<mat-card
    class="daxa-card product-development-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Product Development
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="info bg-gradient border-radius">
            <div class="d-md-flex align-items-center justify-content-between">
                <div class="item d-flex align-items-center">
                    <div class="icon text-center rounded-circle text-white position-relative">
                        <i class="material-symbols-outlined">
                            person
                        </i>
                    </div>
                    <div>
                        <span class="info-title d-block fw-medium text-white">
                            Client
                        </span>
                        <span class="d-block text-white">
                            Bernard Garrison
                        </span>
                    </div>
                </div>
                <div class="item d-flex align-items-center">
                    <div class="icon text-center rounded-circle text-white position-relative">
                        <i class="material-symbols-outlined">
                            attach_money
                        </i>
                    </div>
                    <div>
                        <span class="info-title d-block fw-medium text-white">
                            Budget
                        </span>
                        <span class="d-block text-white">
                            $10,500
                        </span>
                    </div>
                </div>
                <div class="item d-flex align-items-center">
                    <div class="icon text-center rounded-circle text-white position-relative">
                        <i class="material-symbols-outlined">
                            schedule
                        </i>
                    </div>
                    <div>
                        <span class="info-title d-block fw-medium text-white">
                            Duration
                        </span>
                        <span class="d-block text-white">
                            150.5 Hrs
                        </span>
                    </div>
                </div>
            </div>
            <mat-progress-bar mode="determinate" value="60"></mat-progress-bar>
            <div class="d-flex align-items-center justify-content-between">
                <span class="d-block text-white">
                    Progress
                </span>
                <span class="d-block text-white">
                    60%
                </span>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
            <span class="d-block text-body">
                Project Started: Nov 01, 2024
            </span>
            <span class="d-block text-body">
                Project Deadline: Dec 30, 2024
            </span>
        </div>
        <span class="title d-block">
            Project Details:
        </span>
        <p>
            Commodo eu arcu vel tincidunt habitant risus nec. Integer sollicitudin aliquam risus massa eleifend vitae. Ornare maecenas purus at dictumst. Praesent ornare blandit urna mi porta dui id orci. Molestie nunc lorem velit pulvinar. Vulputate at ligula nisl adipiscing aenean sit.
        </p>
        <p>
            Feugiat et eu in euismod suspendisse elementum. Ipsum pellentesque morbi ultricies mattis. Ut at amet faucibus bibendum. Velit posuere ornare orci ultrices porta. Sed vestibulum venenatis sed purus sagittis. Neque adipiscing quis faucibus eget amet.
        </p>
        <ul class="pl-0 mb-0 list-unstyled">
            <li class="text-body position-relative">
                Sapien magna dolor urna ultrices ultrices ultricies enim in.
            </li>
            <li class="text-body position-relative">
                Dui massa ipsum viverra porttitor mi sed vestibulum.
            </li>
            <li class="text-body position-relative">
                Vel duis ligula gravida aenean massa mi ullamcorper.
            </li>
        </ul>
    </mat-card-content>
</mat-card>