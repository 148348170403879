import {HttpParams} from "@angular/common/http";

export function toHttpParams(request: any): HttpParams {
    let params = new HttpParams();

    // Duyệt qua từng key-value trong object và chỉ thêm key có giá trị không phải null hoặc undefined
    Object.entries(request).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
            params = params.set(key, value.toString());
        }
    });

    return params;
}