import {CountryModel} from "../../core/domain/models/country.model";
import {Country, ListCountryResponse} from "../adapters/http/responses/country.response";

// Mapper từ Notification sang NotificationModel
export function mapCountryToModel(country: Country): CountryModel {
  return {
    id: country.id,
    nameVi: country.nameVi,
    nameEn: country.nameEn,
    name: country.nameVi ? country.nameVi : country.name
  };
}

export function mapListCountryResponseToModels(response: ListCountryResponse): CountryModel[] {
  return response.data.items.map(country => mapCountryToModel(country));
}