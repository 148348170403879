<div *ngIf="user$ | async as user" class="row">
    <div class="col">
        <mat-card class="daxa-card profile-card mb-25 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()" [class.rtl-enabled]="themeService.isRTLEnabled()">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Profile Intro
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="user d-flex align-items-center">
                    <div class="image position-relative">
                        <img src="{{ user.avatarThumbnailUrl || 'images/profile.jpg'}}" class="rounded-circle" alt="profile-image">
                        <button type="button" mat-button class="edit-btn" [matMenuTriggerFor]="editProfilePhotoMenu">
                            <i class="material-symbols-outlined">
                                photo_camera
                            </i>
                        </button>
                        <mat-menu #editProfilePhotoMenu="matMenu" class="card-header-menu" xPosition="after">
                            <button mat-menu-item>
                                See Photo
                            </button>
                            <button mat-menu-item (click)="openAvatarDialog()">
                                Upload Photo
                            </button>
                            <button mat-menu-item>
                                Remove
                            </button>
                        </mat-menu>
                    
                    </div>
                    <div class="title">
                        <span class="d-block fw-medium">
                           {{user.fullName}}
                        </span>
                        <span class="d-block text-body">
                            {{user.email}}
                        </span>
                    </div>
                </div>
                <!-- <span class="sub-title d-block fw-medium">
                    About Me
                </span>
                <p>
                    Molestie tincidunt ut consequat a urna tortor. Vitae velit ac nisl velit mauris placerat nisi
                    placerat. Pellentesque viverra lorem malesuada nunc tristique sapien. Imperdiet sit hendrerit
                    tincidunt bibendum donec adipiscing.
                </p> -->
                <h5>
                    Social Profile
                </h5>
                <div class="socials-link">
                    <a href="https://www.facebook.com/" target="_blank"
                        class="facebook d-inline-block text-center position-relative rounded-circle">
                        <i class="ri-facebook-fill"></i>
                    </a>
                    <a href="https://twitter.com/" target="_blank"
                        class="twitter d-inline-block text-center position-relative rounded-circle">
                        <i class="ri-twitter-fill"></i>
                    </a>
                    <a href="https://www.linkedin.com/" target="_blank"
                        class="linkedin d-inline-block text-center position-relative rounded-circle">
                        <i class="ri-linkedin-fill"></i>
                    </a>
                    <a href="mailto:micheal@gmail.com"
                        class="mail d-inline-block text-center position-relative rounded-circle">
                        <i class="ri-mail-fill"></i>
                    </a>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card class="daxa-card profile-card mb-25 border-radius bg-white border-none d-block"
            [class.rtl-enabled]="themeService.isRTLEnabled()">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Profile Information

                        <button mat-icon-button matTooltip="Primary" color="primary" [routerLink]="['/my-profile']" aria-label="Example icon-button with a heart icon">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </h5>

                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul class="info-list pl-0 mb-0 mt-0 list-unstyled">
                    <li>
                        <span class="text-body">User ID:</span> {{user.id}}
                    </li>
                    <li>
                        <span class="text-body">Full Name:</span> {{user.fullName}}
                    </li>
                    <li>
                        <span class="text-body">Email:</span> {{user.email}}
                    </li>
                    <li>
                        <span class="text-body">BirthDay:</span> {{user.birthDay}}
                    </li>
                    <li>
                        <span class="text-body">Gender:</span> {{user.gender}}
                    </li>
                    <li>
                        <span class="text-body">Mobile:</span> {{user.mobile}}
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
        <mat-card class="daxa-card profile-card mb-25 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()" [class.rtl-enabled]="themeService.isRTLEnabled()">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Teams
                    </h5>
                </mat-card-title>
            </mat-card-header>
        </mat-card>
    </div>
</div>
