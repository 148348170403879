import {AddressModel} from "../../core/domain/models/address.model";
import {
  Address,
  AddressResponse,
  CreateAddressResponse, GetAddressInfoResponse,
  ListAddressResponse
} from "../adapters/http/responses/address.response";
import {mapCountryToModel} from "./country.mapper";
import {mapDistrictToModel} from "./district.mapper";
import {mapCityToModel} from "./city.mapper";
import {CreateAddressRequest} from "../adapters/http/requests/create-address.request";

export function mapAddressToModel(address: Address): AddressModel {
  return {
    id: address.id,
    addressLevel1: address.addressLevel1,
    addressLevel2: address.addressLevel2,
    addressLevel3: address.addressLevel3,
    country: mapCountryToModel(address.country),
    province: mapCityToModel(address.province),
    district: mapDistrictToModel(address.district),
    postalCode: address.postalCode
  };
}
export function mapAddressResponseToModels(response: AddressResponse): AddressModel[] {
  return response.data.map(address => mapAddressToModel(address));
}

export function mapListAddressResponseToModels(response: ListAddressResponse): AddressModel[] {
  return response.data.items.map(address => mapAddressToModel(address));
}


export function mapCreateAddressResponseToModel(response: CreateAddressResponse): AddressModel {
    return mapAddressToModel(response.data);
}

export function mapDeleteAddressResponseToModel(response: CreateAddressResponse): {} {
    return {};
}

export function mapGetAddressInfoResponseToModel(response: GetAddressInfoResponse): AddressModel {
    return mapAddressToModel(response.data);
}

export function mapAddressModelToCreateAddressInfo(address: AddressModel): CreateAddressRequest {
  return <CreateAddressRequest>{
    addressLevel1: address.addressLevel1,
    addressLevel2: address.addressLevel2,
    addressLevel3: address.addressLevel3,
    countryId: address.country?.id,
    provinceId: address.province?.id,
    districtId: address.district.id,
    postalCode: address.postalCode
  };
}