<!-- Breadcrumb -->
<!-- <div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Profile
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Profile
        </li>
    </ol>
</div> -->

<!-- Profile -->
<!-- <mat-card
    class="daxa-card profile-card mb-25 p-0 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-content>
        <div class="cover-image position-relative border-top-radius">
            <img src="images/profile-big-cover.jpg" class="border-top-radius" alt="cover-image">
            <button type="button" mat-button class="edit-btn" [matMenuTriggerFor]="editCoverPhotoMenu">
                Edit Cover Photo
            </button>
            <mat-menu #editCoverPhotoMenu="matMenu" class="card-header-menu" xPosition="after">
                <button mat-menu-item>
                    Upload Photo
                </button>
                <button mat-menu-item>
                    Reposition
                </button>
                <button mat-menu-item>
                    Remove
                </button>
            </mat-menu>
        </div>
        <div class="profile-content">
            <div class="d-md-flex align-items-end justify-content-between">
                <div class="d-md-flex align-items-end">
                    <div class="image position-relative">
                        <img src="images/profile.jpg" class="rounded-circle" alt="profile-image">
                        <button type="button" mat-button class="edit-btn" [matMenuTriggerFor]="editProfilePhotoMenu">
                            <i class="material-symbols-outlined">
                                photo_camera
                            </i>
                        </button>
                        <mat-menu #editProfilePhotoMenu="matMenu" class="card-header-menu" xPosition="after">
                            <button mat-menu-item>
                                See Photo
                            </button>
                            <button mat-menu-item>
                                Upload Photo
                            </button>
                            <button mat-menu-item>
                                Remove
                            </button>
                        </mat-menu>
                    </div>
                    <div class="title">
                        <span class="d-block fw-medium">
                            Micheal Collins
                        </span>
                        <span class="d-block text-body">
                            micheal&#64;gmail.com
                        </span>
                    </div>
                </div>
                <div class="btn-box">
                    <button mat-button>
                        Share Profile
                    </button>
                </div>
            </div>
            <ul class="nav-links text-center pl-0 mb-0 list-unstyled">
                <li class="d-inline-block">
                    <a routerLink="/profile" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        Profile
                    </a>
                </li>
                <li class="d-inline-block">
                    <a routerLink="/profile/teams" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        Team
                    </a>
                </li>
                <li class="d-inline-block">
                    <a routerLink="/profile/projects" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        Projects
                    </a>
                </li>
            </ul>
        </div>
    </mat-card-content>
</mat-card> -->

<router-outlet />