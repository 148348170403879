import { Component } from '@angular/core';
import {MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle} from "@angular/material/expansion";
import {RouterLink, RouterLinkActive} from "@angular/router";

@Component({
  selector: 'sidebar-pages',
  standalone: true,
  imports: [
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './pages.component.html',
  styleUrl: './pages.component.scss'
})
export class PagesComponent {
  // Mat Expansion
  panelOpenState = false;
}
