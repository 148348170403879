<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Form Field with Prefix & Suffix
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field class="mb-25">
            <mat-label>Enter your password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field class="mb-25" floatLabel="always">
            <mat-label>Amount</mat-label>
            <input matInput type="number" class="example-right-align" placeholder="0">
            <span matTextPrefix>$&nbsp;</span>
            <span matTextSuffix>.00</span>
        </mat-form-field>
    </mat-card-content>
</mat-card>