<div class="demo-button-container">
    <button mat-flat-button class="daxa" (click)="addColumn()">
        Add
    </button>
    <button mat-flat-button color="warn" (click)="removeColumn()">
        Remove
    </button>
    <button mat-flat-button color="primary" (click)="shuffle()">
        Shuffle
    </button>
</div>

<!-- Table -->
<div class="table-responsive">
    <table mat-table [dataSource]="data">

        @for (column of displayedColumns; track column) {
            <ng-container [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
            </ng-container>
        }
    
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        
    </table>
</div>