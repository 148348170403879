import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MatNativeDateModule
} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {RouterLink} from '@angular/router';
import {FileUploadModule} from '@iplab/ngx-file-upload';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {GetUserInfoUseCase} from "../../../../application/use-cases/get-user-info.use-case";
import {UpdateUserInfoUseCase} from "../../../../application/use-cases/update-user-info.use-case";
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import {MyProfileModel} from "../../../../core/domain/models/my-profile.model";
import {CommonModule} from "@angular/common";
import { mapMyProfileModelToUpdateUserInfoRequest} from "../../../../infrastructure/mappers/user-info.mapper";
import {getCurrentLanguage} from "../../../../application/constants/langs-supported";

const moment = _rollupMoment || _moment;

@Component({
    selector: 'app-update-profile',
    standalone: true,
    imports: [CommonModule,MatCardModule, MatMenuModule, MatButtonModule, RouterLink, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, ReactiveFormsModule, FileUploadModule, MatRadioModule, MatCheckboxModule],
    templateUrl: './update-profile.component.html',
    styleUrl: './update-profile.component.scss',
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: getCurrentLanguage()},
    ]
})
export class UpdateProfileComponent {

    userProfileForm: FormGroup;

    constructor(private getUserInfoUseCase: GetUserInfoUseCase,
                private updateUserInfoUseCase: UpdateUserInfoUseCase) {
    }

    ngOnInit() {
        this.loadUserInfo();
        this.userProfileForm = new FormGroup({
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            username: new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(3)]),
            email: new FormControl({value: '', disabled: true}, [Validators.required, Validators.email]),
            birthDay: new FormControl(''),
            gender: new FormControl('', Validators.required),
            mobile: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')])
        });
    }

    userInfo: MyProfileModel;
    birthDay: string;

    loadUserInfo() {
        this.getUserInfoUseCase.execute().subscribe({
            next: (data) => {
                console.log(data);
                this.userInfo = data
                this.birthDay = this.userInfo.birthDay;
                this.userProfileForm.patchValue(this.userInfo);
            },
            error: (error) => console.error('Error loading user info', error)
        });
    }

    // Hàm xử lý khi click vào nút
    onUpdateProfile() {
        if (this.userProfileForm.valid) {
            this.userInfo = {
                ...this.userInfo,
                ...this.userProfileForm.value
            };
            this.userInfo.birthDay = this.userInfo.birthDay
                ? moment(this.userInfo.birthDay).format('YYYY-MM-DD') : '';
            this.updateUserInfoUseCase.execute(mapMyProfileModelToUpdateUserInfoRequest(this.userInfo))
                .subscribe({
                    next: (data) => {
                        window.alert('Update profile success');
                    },
                    error: (error) => console.error('Error loading user info', error)
                });
        }

    }
}
