<mat-card
    class="daxa-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Basic Expansion
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-accordion class="basic-expansion">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        How to integrate Angular with your website?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    We’re fully committed to making this the most accessible and results-driven AI course on the planet. This requires us to be there when you need our help. That’s why we’ve put together a team of professional Data Scientists to support you in your journey, meaning you’ll get a response from us within 48 hours.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        How do Angular site maintenance?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    We’re fully committed to making this the most accessible and results-driven AI course on the planet. This requires us to be there when you need our help. That’s why we’ve put together a team of professional Data Scientists to support you in your journey, meaning you’ll get a response from us within 48 hours.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        How to use Meta Tags in Angular?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    We’re fully committed to making this the most accessible and results-driven AI course on the planet. This requires us to be there when you need our help. That’s why we’ve put together a team of professional Data Scientists to support you in your journey, meaning you’ll get a response from us within 48 hours.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        How to change Language in Angular?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    We’re fully committed to making this the most accessible and results-driven AI course on the planet. This requires us to be there when you need our help. That’s why we’ve put together a team of professional Data Scientists to support you in your journey, meaning you’ll get a response from us within 48 hours.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        How to submit Angular?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    We’re fully committed to making this the most accessible and results-driven AI course on the planet. This requires us to be there when you need our help. That’s why we’ve put together a team of professional Data Scientists to support you in your journey, meaning you’ll get a response from us within 48 hours.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        How to install blogging Angular site?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    We’re fully committed to making this the most accessible and results-driven AI course on the planet. This requires us to be there when you need our help. That’s why we’ve put together a team of professional Data Scientists to support you in your journey, meaning you’ll get a response from us within 48 hours.
                </p>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
</mat-card>