import {ListDistrictResponse, District} from "../adapters/http/responses/district.response";
import {DistrictModel} from "../../core/domain/models/district.model";


export function mapDistrictToModel(district: District): DistrictModel {
  return {
    id: district.id,
    nameVi: district.nameVi,
    nameEn: district.nameEn,
    name: district.nameVi ?  district.nameVi : district.name
  };
}

export function mapListProvinceResponseToModels(response: ListDistrictResponse): DistrictModel[] {
  return response.data.items.map(district => mapDistrictToModel(district));
}