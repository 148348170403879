<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Divider
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Divider
        </li>
    </ol>
</div>

<!-- Divider -->
<div class="row">
    <div class="col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <h1 class="mt-0 mb-10">Heading One</h1>
                <mat-divider></mat-divider>
                <h2 class="mt-10 mb-10">Heading Two</h2>
                <mat-divider></mat-divider>
                <h3 class="mt-10 mb-10">Heading Three</h3>
                <mat-divider></mat-divider>
                <h4 class="mt-10 mb-10">Heading Four</h4>
                <mat-divider></mat-divider>
                <h5 class="mt-10 mb-10">Heading Five</h5>
                <mat-divider></mat-divider>
                <h6 class="mt-10 mb-0">Heading Six</h6>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <mat-list>
                    <mat-list-item>Item 1</mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>Item 2</mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>Item 3</mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>
</div>