export const series = {
    tickets: [
        12, 14, 2, 47, 32, 44, 14, 55, 41, 69
    ],
    dates: [
        "10 Mar 2024",
        "11 Mar 2024",
        "12 Mar 2024",
        "13 Mar 2024",
        "14 Mar 2024",
        "15 Mar 2024",
        "16 Mar 2024",
        "17 Mar 2024",
        "18 Mar 2024",
        "19 Mar 2024"
    ]
};