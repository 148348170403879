<div [class.component-dark-theme]="themeService.isDark()">
    <div class="example-container">
        <h5>Available numbers</h5>
        <div
            id="all"
            cdkDropList
            [cdkDropListData]="all"
            cdkDropListConnectedTo="even"
            class="example-list"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListEnterPredicate]="noReturnPredicate"
        >
            @for (number of all; track number) {
                <div class="example-box" [cdkDragData]="number" cdkDrag>{{number}}</div>
            }
        </div>
    </div>
    <div class="example-container">
        <h5>Even numbers</h5>
        <div
            id="even"
            cdkDropList
            [cdkDropListData]="even"
            cdkDropListConnectedTo="all"
            class="example-list"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListEnterPredicate]="evenPredicate"
        >
            @for (number of even; track number) {
                <div class="example-box" cdkDrag [cdkDragData]="number">{{number}}</div>
            }
        </div>
    </div>
</div>