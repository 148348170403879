import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    ReactiveFormsModule
  ],
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
  title: string = 'Xác nhận';
  message: string = 'Bạn có chắc chắn muốn thực hiện hành động này?';
  confirmButtonText: string = 'Xác nhận';
  cancelButtonText: string = 'Hủy';

  constructor(
      public dialogRef: MatDialogRef<ConfirmDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Nếu có dữ liệu truyền vào, thay đổi các giá trị mặc định
    if (data) {
      this.title = data.title || this.title;
      this.message = data.message || this.message;
      this.confirmButtonText = data.confirmButtonText || this.confirmButtonText;
      this.cancelButtonText = data.cancelButtonText || this.cancelButtonText;
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true); // Trả về true khi xác nhận
  }

  onCancel(): void {
    this.dialogRef.close(false); // Trả về false khi hủy
  }
}
