<!-- Stats -->
<app-stats />

<div class="row">
    <div class="col-lg-6 col-xxxl-4">

        <!-- Most Leads -->
        <app-most-leads />

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Country Stats -->
        <app-country-stats />

    </div>
    <div class="col-lg-12 col-xxxl-4">
        <div class="row">
            <div class="col-lg-6 col-xxxl-12">

                <!-- Tasks Stats -->
                <app-tasks-stats />

            </div>
            <div class="col-lg-6 col-xxxl-12">

                <!-- Earning Reports -->
                <app-earning-reports />
                
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-xxl-5">

        <!-- Top Customers -->
        <app-top-customers />

    </div>
    <div class="col-lg-6 col-xxl-7">

        <!-- Recent Leads -->
        <app-recent-leads />

    </div>
    <div class="col-lg-12 col-xxxl-8">

        <!-- To Do List -->
        <app-to-do-list />

    </div>
    <div class="col-lg-12 col-xxxl-4">
        <div class="row">
            <div class="col-lg-6 col-xxxl-12">

                <!-- Client Payment Status -->
                <app-client-payment-status />

            </div>
            <div class="col-lg-6 col-xxxl-12">

                <!-- Total Leads -->
                <app-total-leads />
                
            </div>
        </div>
    </div>
</div>