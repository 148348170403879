<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        RemixIcon
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Pages
        </li>
        <li class="breadcrumb-item position-relative">
            Icons
        </li>
        <li class="breadcrumb-item position-relative">
            RemixIcon
        </li>
    </ol>
</div>

<!-- RemixIcon -->
<mat-card
    class="daxa-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
>
    <mat-card-content>
        <code>
            &lt;<span class="red">i</span><span class="green">class</span>=<span class="blue">&quot;ri-home-7-line&quot;</span>&gt;&lt;/<span class="red">i</span>&gt;
        </code>
    </mat-card-content>
</mat-card>

<!-- Icons -->
<div class="row">
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-home-7-line"></i>
                <span class="title d-block text-body">
                    ri-home-7-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-store-2-line"></i>
                <span class="title d-block text-body">
                    ri-store-2-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-mail-open-line"></i>
                <span class="title d-block text-body">
                    ri-mail-open-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-cloud-line"></i>
                <span class="title d-block text-body">
                    ri-cloud-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-bar-chart-box-line"></i>
                <span class="title d-block text-body">
                    ri-bar-chart-box-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-calendar-todo-line"></i>
                <span class="title d-block text-body">
                    ri-calendar-todo-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-global-line"></i>
                <span class="title d-block text-body">
                    ri-global-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-flag-2-fill"></i>
                <span class="title d-block text-body">
                    ri-flag-2-fill
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-service-line"></i>
                <span class="title d-block text-body">
                    ri-service-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-megaphone-line"></i>
                <span class="title d-block text-body">
                    ri-megaphone-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-message-2-line"></i>
                <span class="title d-block text-body">
                    ri-message-2-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-chat-quote-line"></i>
                <span class="title d-block text-body">
                    ri-chat-quote-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-emoji-sticker-line"></i>
                <span class="title d-block text-body">
                    ri-emoji-sticker-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-ball-pen-line"></i>
                <span class="title d-block text-body">
                    ri-ball-pen-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-contrast-2-line"></i>
                <span class="title d-block text-body">
                    ri-contrast-2-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-collage-line"></i>
                <span class="title d-block text-body">
                    ri-collage-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-bug-fill"></i>
                <span class="title d-block text-body">
                    ri-bug-fill
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-scan-line"></i>
                <span class="title d-block text-body">
                    ri-scan-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-wifi-off-fill"></i>
                <span class="title d-block text-body">
                    ri-wifi-off-fill
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-eth-line"></i>
                <span class="title d-block text-body">
                    ri-eth-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-cup-line"></i>
                <span class="title d-block text-body">
                    ri-cup-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-cake-3-line"></i>
                <span class="title d-block text-body">
                    ri-cake-3-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-angularjs-line"></i>
                <span class="title d-block text-body">
                    ri-angularjs-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-edge-new-fill"></i>
                <span class="title d-block text-body">
                    ri-edge-new-fill
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-xbox-line"></i>
                <span class="title d-block text-body">
                    ri-xbox-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-map-pin-line"></i>
                <span class="title d-block text-body">
                    ri-map-pin-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-rocket-line"></i>
                <span class="title d-block text-body">
                    ri-rocket-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-bus-line"></i>
                <span class="title d-block text-body">
                    ri-bus-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-motorbike-fill"></i>
                <span class="title d-block text-body">
                    ri-motorbike-fill
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <i class="ri-image-add-line"></i>
                <span class="title d-block text-body">
                    ri-image-add-line
                </span>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- View All Icons Link -->
<div class="mb-25 text-center">
    <a mat-button href="https://remixicon.com/" class="btn" target="_blank">
        View All Icons
    </a>
</div>