<div
    class="change-password-card"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="row">
        <div class="col-sm-6">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Old Password
                </label>
                <mat-form-field>
                    <mat-label>
                        Enter old password
                    </mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <span class="material-symbols-outlined">
                            {{hide ? 'visibility_off' : 'visibility'}}
                        </span>
                    </button>
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    New Password
                </label>
                <mat-form-field>
                    <mat-label>
                        Enter new password
                    </mat-label>
                    <input matInput [type]="hide2 ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                        <span class="material-symbols-outlined">
                            {{hide2 ? 'visibility_off' : 'visibility'}}
                        </span>
                    </button>
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Confirm
                </label>
                <mat-form-field>
                    <mat-label>
                        Enter confirm password
                    </mat-label>
                    <input matInput [type]="hide3 ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide3">
                        <span class="material-symbols-outlined">
                            {{hide3 ? 'visibility_off' : 'visibility'}}
                        </span>
                    </button>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="btn-box">
        <button mat-button>
            Change Password
        </button>
        <a routerLink="/authentication/forgot-password" mat-button>
            Forgot Password?
        </a>
    </div>
</div>