<div class="row">
    <div class="col-xl-6">
        <mat-form-field class="mb-20">
            <mat-label>Message</mat-label>
            <input matInput value="Disco party!" #message>
        </mat-form-field>
    </div>
    <div class="col-xl-6">
        <mat-form-field class="mb-20">
            <mat-label>Action</mat-label>
            <input matInput value="Dance" #action>
        </mat-form-field>
    </div>
</div>

<button mat-flat-button class="daxa" (click)="openSnackBar(message.value, action.value)">
    Show Snackbar
</button>