<mat-card
    class="daxa-card todays-course-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Today's Course
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <owl-carousel-o [options]="todaysCourseSlides">
            <ng-template carouselSlide>
                <div class="course-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <a routerLink="/lms-page/course-details" class="image d-block">
                                <img src="images/courses/course7.jpg" alt="course-image">
                            </a>
                        </div>
                        <div class="col-lg-6">
                            <div class="content">
                                <a routerLink="/lms-page/course-details" class="d-inline-block">
                                    UI/UX Design Essentials
                                </a>
                                <div class="price lh-1 fw-medium">
                                    $14.99
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <span class="d-block fw-medium">
                            Course content
                        </span>
                        <span class="d-block">
                            15 sections • 70 lectures • 15h 50m
                        </span>
                    </div>
                    <div class="view-details-btn text-right">
                        <a mat-button routerLink="/lms-page/course-details">
                            + View Details
                        </a>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="course-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <a routerLink="/lms-page/course-details" class="image d-block">
                                <img src="images/courses/course8.jpg" alt="course-image">
                            </a>
                        </div>
                        <div class="col-lg-6">
                            <div class="content">
                                <a routerLink="/lms-page/course-details" class="d-inline-block">
                                    Introduction To Python
                                </a>
                                <div class="price lh-1 fw-medium">
                                    $32.22
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <span class="d-block fw-medium">
                            Course content
                        </span>
                        <span class="d-block">
                            12 sections • 43 lectures • 23h 20m
                        </span>
                    </div>
                    <div class="view-details-btn text-right">
                        <a mat-button routerLink="/lms-page/course-details">
                            + View Details
                        </a>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="course-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <a routerLink="/lms-page/course-details" class="image d-block">
                                <img src="images/courses/course9.jpg" alt="course-image">
                            </a>
                        </div>
                        <div class="col-lg-6">
                            <div class="content">
                                <a routerLink="/lms-page/course-details" class="d-inline-block">
                                    Graphic Design Basics
                                </a>
                                <div class="price lh-1 fw-medium">
                                    $23.21
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <span class="d-block fw-medium">
                            Course content
                        </span>
                        <span class="d-block">
                            16 sections • 65 lectures • 11h 20m
                        </span>
                    </div>
                    <div class="view-details-btn text-right">
                        <a mat-button routerLink="/lms-page/course-details">
                            + View Details
                        </a>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </mat-card-content>
</mat-card>