<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Clients
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Project Management
        </li>
        <li class="breadcrumb-item position-relative">
            Clients
        </li>
    </ol>
</div>

<!-- Clients -->
<div
    class="row"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="col-md-6 col-xxl-4 col-xxxl-3" *ngFor="let content of clientCard;">
        <div class="client-card">
            <mat-card class="daxa-card client-card mb-25 border-radius bg-white border-none d-block">
                <mat-card-content>
                    <div class="d-flex align-items-center">
                        <div class="icon position-relative text-center rounded-circle">
                            <img [src]="content.clientImg" alt="client-image">
                        </div>
                        <div>
                            <h5>
                                {{content.clientName}}
                            </h5>
                            <span class="d-block text-body">
                                {{content.clientEmail}}
                            </span>
                        </div>
                    </div>
                    <ul class="pl-0 mb-0 list-unstyled">
                        <li>
                            <span class="text-body">
                                Contact Person:
                            </span> 
                            {{content.contactPerson}}
                        </li>
                        <li>
                            <span class="text-body">
                                Contact Phone:
                            </span> 
                            {{content.contactPhone}}
                        </li>
                        <li>
                            <span class="text-body">
                                Total Projects:
                            </span> 
                            {{content.totalProjects}}
                        </li>
                    </ul>
                    <div class="btn-box">
                        <button mat-button>
                            Message
                        </button>
                        <button mat-button>
                            View Project
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>