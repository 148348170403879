<h1 mat-dialog-title>
    {{ title }}
</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col-sm-12 col-12">
            {{message}}
        </div>
    </div>
</div>

<div mat-dialog-actions class="mt-0">
    <button mat-flat-button color="warn" (click)="onConfirm()">{{ confirmButtonText }}</button>
</div>

