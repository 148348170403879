<mat-card
    class="daxa-card top-instructors-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Top Instructors
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="top-instructors-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- User Column -->
                    <ng-container matColumnDef="user">
                        <td mat-cell *matCellDef="let element">
                            <div class="user-info d-flex align-items-center">
                                <div class="image">
                                    <img [src]="element.user.img" alt="user-image">
                                </div>
                                <div class="info">
                                    <span class="name text-black d-block">
                                        {{element.user.name}}
                                    </span>
                                    <span class="d-block text-body">
                                        {{element.user.number}}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Ratings Column -->
                    <ng-container matColumnDef="ratings">
                        <td mat-cell *matCellDef="let element">
                            <div class="ratings d-flex align-items-center text-body justify-content-end">
                                <i class="{{element.ratings.star1}}"></i>
                                <i class="{{element.ratings.star2}}"></i>
                                <i class="{{element.ratings.star3}}"></i>
                                <i class="{{element.ratings.star4}}"></i>
                                <i class="{{element.ratings.star5}}"></i>
                                ({{element.ratings.overallStar}})
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[6, 12, 18]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>