<div class="row">
    <div class="col-xl-6">
        <mat-form-field class="mb-20">
            <mat-label>Horizontal position</mat-label>
            <mat-select [(value)]="horizontalPosition">
                <mat-option value="start">Start</mat-option>
                <mat-option value="center">Center</mat-option>
                <mat-option value="end">End</mat-option>
                <mat-option value="left">Left</mat-option>
                <mat-option value="right">Right</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-xl-6">
        <mat-form-field class="mb-20">
            <mat-label>Vertical position</mat-label>
            <mat-select [(value)]="verticalPosition">
                <mat-option value="top">Top</mat-option>
                <mat-option value="bottom">Bottom</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<button mat-stroked-button (click)="openSnackBar()" aria-label="Show an example snack-bar">
    Pool Party!
</button>