<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Select
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Select
        </li>
    </ol>
</div>

<!-- Select -->
<div class="row">
    <div class="col-lg-8 col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <app-basic-select />
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Select with Multiple Selection
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-select-multiple-selection />
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Select with Custom Trigger Text
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-select-custom-trigger-text />
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Disabled Select
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-disabled-select />
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Select with a Custom ErrorStateMatcher
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-swac-errorstatematcher />
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Select with Option Groups
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-sw-option-groups />
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Select with Reset Option
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-sw-reset-option />
            </mat-card-content>
        </mat-card>
    </div>
</div>