<div [class.component-dark-theme]="themeService.isDark()">
    <form>
        <p>
            <input type="checkbox" id="can-drink" [formControl]="canDrinkCtrl">&nbsp;
            <label for="can-drink">I am 21 or older</label>
        </p>
        <section>
            <div class="example-listbox-container" [class.example-disabled]="!canDrinkCtrl.value">
            <label class="example-listbox-label fw-medium" id="example-wine-type-label">
                Wine Selection
            </label>
            <ul cdkListbox
                [cdkListboxDisabled]="!canDrinkCtrl.value"
                aria-labelledby="example-wine-type-label"
                class="example-listbox"
            >
                <li cdkOption="cabernet" class="example-option">
                    Cabernet Sauvignon
                </li>
                <li cdkOption="syrah" class="example-option">
                    Syrah
                </li>
                <li cdkOption="zinfandel" cdkOptionDisabled class="example-option">
                    Zinfandel <span class="example-sold-out">(sold out)</span>
                </li>
                <li cdkOption="riesling" class="example-option">
                    Riesling
                </li>
            </ul>
            </div>
        </section>
    </form>
</div>