<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Breadcrumb
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Breadcrumb
        </li>
    </ol>
</div>

<!-- Breadcrumb -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Basic Breadcrumb
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <nav aria-label="d-breadcrumb">
            <ol class="d-breadcrumb">
                <li class="d-breadcrumb-item active" aria-current="page">Home</li>
            </ol>
        </nav>

        <nav aria-label="d-breadcrumb">
            <ol class="d-breadcrumb">
                <li class="d-breadcrumb-item"><a routerLink=".">Home</a></li>
                <li class="d-breadcrumb-item active" aria-current="page">Library</li>
            </ol>
        </nav>

        <nav aria-label="d-breadcrumb">
            <ol class="d-breadcrumb">
                <li class="d-breadcrumb-item"><a routerLink=".">Home</a></li>
                <li class="d-breadcrumb-item"><a routerLink=".">Library</a></li>
                <li class="d-breadcrumb-item active" aria-current="page">Data</li>
            </ol>
        </nav>

        <nav aria-label="d-breadcrumb">
            <ol class="d-breadcrumb mb-0">
                <li class="d-breadcrumb-item"><a routerLink=".">Home</a></li>
                <li class="d-breadcrumb-item"><a routerLink=".">Library</a></li>
                <li class="d-breadcrumb-item"><a routerLink=".">Daxa</a></li>
                <li class="d-breadcrumb-item active" aria-current="page">Data</li>
            </ol>
        </nav>
    </mat-card-content>
</mat-card>