export const series = {
    tickets: [
        15, 75, 47, 65, 14, 32, 19, 54, 44, 61
    ],
    dates: [
        "10 Mar 2024",
        "11 Mar 2024",
        "12 Mar 2024",
        "13 Mar 2024",
        "14 Mar 2024",
        "15 Mar 2024",
        "16 Mar 2024",
        "17 Mar 2024",
        "18 Mar 2024",
        "19 Mar 2024"
    ]
};