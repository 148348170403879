import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NotificationModel } from '../../core/domain/models/notification.model';
import { ListNotificationResponse } from '../adapters/http/responses/list-notification.response';
import { mapListNotificationResponseToModels } from '../mappers/notification.mapper';
import {NotificationAdapter} from "../adapters/http/notification.adapter";
import {NotificationInterface} from "../../core/ports/notification.interface";
import {GetNotificationListRequest} from "../adapters/http/requests/get-notification-list.request";
import {ReadNotificationResponse} from "../adapters/http/responses/read-notification.response";
import {CreateFcmTokenResponse} from "../adapters/http/responses/create-fcm-token.response";
import {ResultModel} from "../../core/domain/models/result.model"; // Cần mapper để map response sang model

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements NotificationInterface {
  constructor(private notificationAdapter: NotificationAdapter) {}

  getNotifications(getNotificationListRequest: GetNotificationListRequest): Observable<NotificationModel[]> {
    return this.notificationAdapter.getNotifications(getNotificationListRequest).pipe(
      tap((response: ListNotificationResponse) => console.log('Notifications response', response)),
      map((response: ListNotificationResponse) => mapListNotificationResponseToModels(response)) // Map response sang model
    );
  }

  markNotificationsAsRead(ids: string[]): Observable<ReadNotificationResponse> {
    return this.notificationAdapter.readNotification({
      ids: ids
    }).pipe(
      map((response: ReadNotificationResponse) => response) // Không cần mapper vì response đã là model
    );
  }

  createFcmToken(token: string): Observable<ResultModel> {
    return this.notificationAdapter.createNotificationToken({token}).pipe(
      tap((response: CreateFcmTokenResponse) => console.log('Create FCM response', response)),
      map((response: CreateFcmTokenResponse) => {
        return {
          success: response.data.result
        }
      })
    );
  }
}
