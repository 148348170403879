<div class="forgot-password-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div
                class="daxa-form ml-auto mr-auto border-radius bg-white"
                [class.card-borderd-theme]="themeService.isCardBorder()"
            >
                <div class="title text-center">
                    <h3>
                        Forgot Your Password?
                    </h3>
                    <p class="ml-auto mr-auto">
                        Enter the email address you used when you joined and we’ll send you instructions to reset your password.
                    </p>
                </div>
                <form>
                    <div class="form-group">
                        <label class="main-label d-block lh-1 text-black">
                            Email Address
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter email address
                            </mat-label>
                            <input matInput type="email">
                        </mat-form-field>
                    </div>
                    <button mat-button class="btn" type="submit">
                        Send
                    </button>
                    <div class="text-center back-to-sign-in">
                        <a mat-button routerLink="/authentication">
                            <i class="ri-arrow-left-s-line"></i>
                            Back to Sign In
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>