<mat-card
    class="daxa-card top-sellers-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Top Sellers
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="top-sellers-table">
            <div class="table-responsive">
                <table>
                    <tbody>
                        <tr>
                            <td class="text-body fw-medium">
                                01.
                            </td>
                            <td>
                                <div class="info d-flex align-items-center">
                                    <div class="image">
                                        <img src="images/users/user6.jpg" alt="user-image">
                                    </div>
                                    <div>
                                        <h6 class="fw-normal">
                                            Mark Stjohn
                                        </h6>
                                        <span class="d-block text-body">
                                            Customer ID #76431
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="rating d-flex align-items-center text-body justify-content-end">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    (5)
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-body fw-medium">
                                02.
                            </td>
                            <td>
                                <div class="info d-flex align-items-center">
                                    <div class="image">
                                        <img src="images/users/user7.jpg" alt="user-image">
                                    </div>
                                    <div>
                                        <h6 class="fw-normal">
                                            Joan Stanley
                                        </h6>
                                        <span class="d-block text-body">
                                            Customer ID #64815
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="rating d-flex align-items-center text-body justify-content-end">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-half-fill"></i>
                                    (4.5)
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-body fw-medium">
                                03.
                            </td>
                            <td>
                                <div class="info d-flex align-items-center">
                                    <div class="image">
                                        <img src="images/users/user8.jpg" alt="user-image">
                                    </div>
                                    <div>
                                        <h6 class="fw-normal">
                                            Jacob Bell
                                        </h6>
                                        <span class="d-block text-body">
                                            Customer ID #34581
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="rating d-flex align-items-center text-body justify-content-end">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-line"></i>
                                    (4)
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-body fw-medium">
                                04.
                            </td>
                            <td>
                                <div class="info d-flex align-items-center">
                                    <div class="image">
                                        <img src="images/users/user9.jpg" alt="user-image">
                                    </div>
                                    <div>
                                        <h6 class="fw-normal">
                                            Donald Bryan
                                        </h6>
                                        <span class="d-block text-body">
                                            Customer ID #67941
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="rating d-flex align-items-center text-body justify-content-end">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    (5)
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-body fw-medium">
                                05.
                            </td>
                            <td>
                                <div class="info d-flex align-items-center">
                                    <div class="image">
                                        <img src="images/users/user10.jpg" alt="user-image">
                                    </div>
                                    <div>
                                        <h6 class="fw-normal">
                                            Kristina Blomquist
                                        </h6>
                                        <span class="d-block text-body">
                                            Customer ID #36985
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="rating d-flex align-items-center text-body justify-content-end">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    (5)
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-body fw-medium">
                                06.
                            </td>
                            <td>
                                <div class="info d-flex align-items-center">
                                    <div class="image">
                                        <img src="images/users/user11.jpg" alt="user-image">
                                    </div>
                                    <div>
                                        <h6 class="fw-normal">
                                            Jeffrey Morrison
                                        </h6>
                                        <span class="d-block text-body">
                                            Customer ID #26985
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="rating d-flex align-items-center text-body justify-content-end">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-half-fill"></i>
                                    <i class="ri-star-line"></i>
                                    (3.5)
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </mat-card-content>
</mat-card>