<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Line Charts
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Apexcharts
        </li>
        <li class="breadcrumb-item position-relative">
            Line Charts
        </li>
    </ol>
</div>

<!-- Line Charts -->
<div class="row">
    <div class="col-lg-6">

        <!-- Basic Line Chart -->
        <app-basic-line-chart />

    </div>
    <div class="col-lg-6">

        <!-- Zoomable Line Chart -->
        <app-zoomable-line-chart />

    </div>
    <div class="col-lg-6">

        <!-- Gradient Line Chart -->
        <app-gradient-line-chart />

    </div>
    <div class="col-lg-6">

        <!-- Dashed Line Chart -->
        <app-dashed-line-chart />

    </div>
    <div class="col-lg-6">

        <!-- Stepline Line Chart -->
        <app-stepline-line-chart />

    </div>
    <div class="col-lg-6">

        <!-- Brush Line Chart -->
        <app-brush-line-chart />

    </div>
    <div class="col-lg-6">

        <!-- Syncing Line Chart -->
        <app-syncing-line-chart />

    </div>
</div>