

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAwjgQNfuq6W7mcD9ka6wYavOFnvWlyfdg",
    authDomain: "corp-309500.firebaseapp.com",
    projectId: "corp-309500",
    storageBucket: "corp-309500.appspot.com",
    messagingSenderId: "520215694014",
    appId: "1:520215694014:web:c712bcb44acebc80c2ecef",
    measurementId: "G-PCRFQG4DNK",
    vapidKey: "BAJ1p0a3c4C8Sheaa1zgOxLr1EVYl4NpK2BdgLxliSeV1d7xifw0bpS-N68ZRnl4dKHCdFCouaOTtmK_zd9bCv0"
  },
  oidc: {
    issuer: "https://dev-idp.202corp.com/realms/tzt-customer",
    clientId: "tzt-console-web",
    dummyClientSecret: "whF8fxwS5H8ACqGKPNa9pGrMaDd3amYd",
    redirectUri: window.location.origin,
    responseType: "code",
    scope: "openid profile email api:client",
    showDebugInformation: true,
  },
  api: {
    gateway: "https://dev-apis.202corp.com",
  },
  adapter:{
    http:{
      gateway: "https://dev-apis.202corp.com/",
      services: {
        projectManager:{
          name: "project-manager-service",
          endpoint: {
            getListProject: "/client-api/v1/projects",
            createProject: "/client-api/v1/project",
          }
        },
        userManager:{
          name: "user-manager-service",
          endpoint: {
            getUserInfo: "/client-api/v1/me",
            updateUserInfo: "/client-api/v1/me",
            updateUserAvatar: "/client-api/v1/me/avatar",
            getListAddress: "/client-api/v1/addresses",
            getAddressInfo: "/client-api/v1/addresses/{id}",
            createAddress: "/client-api/v1/addresses",
            updateAddress: "/client-api/v1/addresses/{id}",
            deleteAddress: "/client-api/v1/addresses/{id}",
            getListCountry: "/client-api/v1/master-data/countries",
            getListCity: "/client-api/v1/master-data/countries/{countryId}/cities",
            getListProvince: "/client-api/v1/master-data/countries/{countryId}/cities/{cityId}"
          }
        },
        communicationService:{
          name: "communication-service",
          endpoint: {
            getListNotification: "/client-api/v1/notifications",
            readNotification: "/client-api/v1/notifications",
            createNotificationToken: "/client-api/v1/fcm/token",
          }
        },
      },
    }
  },
  socket: {
    gateway: "wss://dev-socket.202corp.com",
  },
};
