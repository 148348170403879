import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo',
  standalone: true
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: string | Date): string {
    const date = new Date(value);
    const now = new Date();

    const diffInMilliseconds = now.getTime() - date.getTime();
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));

    // Nếu là trong cùng một ngày
    if (now.toDateString() === date.toDateString()) {
      if (diffInHours === 0) {
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
        return `${diffInMinutes} minute(s) ago`;
      }
      return `${diffInHours} hour(s) ago`;
    }

    // Ngược lại, hiển thị ngày giờ đầy đủ
    return date.toLocaleString(); // Hoặc sử dụng DatePipe nếu cần định dạng cụ thể
  }
}
