import {Inject, Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {AddressService} from "../../infrastructure/services/address.service";
import {AddressModel} from "../../core/domain/models/address.model";

@Injectable({
    providedIn: 'root'
})
export class GetListAddressUseCase {
    constructor(
        private addressService: AddressService
    ) {}
    execute(): Observable<AddressModel[]> {
        return this.addressService.getListAddress()
            .pipe(
                catchError(error => {
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    // Xử lý lỗi
    private handleError(error: any): void {
        console.error('Error fetching user info', error);
    }
}
