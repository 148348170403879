<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Autocomplete Overview
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <mat-form-field>
                <mat-label>State</mat-label>
                <input
                    matInput
                    aria-label="State"
                    [matAutocomplete]="auto"
                    [formControl]="stateCtrl"
                >
                <mat-autocomplete #auto="matAutocomplete">
                    @for (state of filteredStates | async; track state) {
                        <mat-option [value]="state.name">
                            <img alt="flag-image" class="example-option-img" [src]="state.flag" width="25">
                            <span>{{state.name}}</span> |
                            <small>Population: {{state.population}}</small>
                        </mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
            <br>
            <mat-slide-toggle [checked]="stateCtrl.disabled" (change)="stateCtrl.disabled ? stateCtrl.enable() : stateCtrl.disable()" style="margin-top: 10px;">
                Disable Input?
            </mat-slide-toggle>
        </form>
    </mat-card-content>
</mat-card>