<mat-card class="daxa-card error-card mb-25 pt-0 border-radius bg-white border-none d-block text-center">
    <mat-card-content>
        <img src="images/internal-error.png" alt="internal-error-image">
        <h3>
            Looks like we have an internal error, please try again later.
        </h3>
        <p>
            But no worries! Our team is looking ever where while you wait safely.
        </p>
        <a mat-button routerLink="/">
            Back to Home
        </a>
    </mat-card-content>
</mat-card>