<div class="row">
    <div class="col-lg-12 col-xxxl-6">

        <!-- Welcome -->
        <app-welcome />

    </div>
    <div class="col-lg-12 col-xxxl-6">
        <div class="row">
            <div class="col-md-4">

                <!-- Active Courses -->
                <app-active-courses />

            </div>
            <div class="col-md-4">

                <!-- Enrolled Students -->
                <app-enrolled-students />

            </div>
            <div class="col-md-4">

                <!-- Completion Status -->
                <app-completion-status />

            </div>
        </div>
    </div>
    <div class="col-lg-7">

        <!-- Active Students -->
        <app-active-students />

    </div>
    <div class="col-lg-5">

        <!-- Courses Categories -->
        <app-courses-categories />

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Top Instructors -->
        <app-top-instructors />
        
    </div>
    <div class="col-lg-6 col-xxxl-4">
        <div class="row">
            <div class="col-lg-12">

                <!-- Total Course Sales -->
                <app-total-courses-sales />

            </div>
            <div class="col-md-6">

                <!-- Video Courses -->
                <app-video-courses />

            </div>
            <div class="col-md-6">

                <!-- Total Instructors -->
                <app-total-instructors />

            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xxxl-4">

        <!-- Students Progress -->
        <app-students-progress />
        
    </div>
</div>

<!-- Courses -->
<app-courses />

<div class="row">
    <div class="col-lg-6 col-xxxl-3 order-lg-1">

        <!-- Time Spending's -->
        <app-time-spendings />

    </div>
    <div class="col-lg-12 col-xxxl-6 order-lg-3 order-xxxl-2">

        <!-- Average Enrollment Rate -->
        <app-average-enrollment-rate />
        
    </div>
    <div class="col-lg-6 col-xxxl-3 order-lg-2 order-xxxl-3">

        <!-- Today's Course -->
        <app-todays-course />
        
    </div>
</div>