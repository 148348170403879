<div>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="material-symbols-outlined">
                    account_circle
                </i>
                <span class="title">
                                    Account
                                </span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidebar-sub-menu">
            <li class="sidemenu-item">
                <a routerLink="/account" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                   class="sidemenu-link">
                    My Profile
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/account/addresses" routerLinkActive="active"
                   [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Address
                </a>
            </li>
        </ul>
    </mat-expansion-panel>

    <a routerLink="/draw-flow" class="sidebar-menu-link" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            polyline
        </i>
        <span class="title">Draw flow</span>
    </a>
    <a routerLink="/project-list" class="sidebar-menu-link" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            description
        </i>
        <span class="title">Project Management</span>
    </a>
    <a routerLink="/notifications" class="sidebar-menu-link" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            notifications
        </i>
        <span class="title">Notifications</span>
    </a>

    <a routerLink="/authentication/logout" class="sidebar-menu-link" routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            logout
        </i>
        <span class="title">Logout</span>
    </a>
</div>
