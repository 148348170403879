<div
    class="row"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/png.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        HB Template
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        159 Files
                    </span>
                    <span class="d-block text-body">
                        4.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/jpg.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        React Template
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        522 Files
                    </span>
                    <span class="d-block text-body">
                        5.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/txt.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        Material UI
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        995 Files
                    </span>
                    <span class="d-block text-body">
                        7.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/pdf.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        WP Themes
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        39 Files
                    </span>
                    <span class="d-block text-body">
                        3.8 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/xl4.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        Personal Photos
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        75 Files
                    </span>
                    <span class="d-block text-body">
                        2.2 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/doc.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        Mobile Apps
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        357 Files
                    </span>
                    <span class="d-block text-body">
                        8.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/png.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        Important Files
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        159 Files
                    </span>
                    <span class="d-block text-body">
                        4.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/jpg.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        Angular Template
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        522 Files
                    </span>
                    <span class="d-block text-body">
                        5.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/txt.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        Projects
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        995 Files
                    </span>
                    <span class="d-block text-body">
                        7.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/pdf.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        Documents
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        39 Files
                    </span>
                    <span class="d-block text-body">
                        3.8 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/xl4.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        Media
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        75 Files
                    </span>
                    <span class="d-block text-body">
                        2.2 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/doc.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    <a routerLink="/file-manager/personal">
                        Applications
                    </a>
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        357 Files
                    </span>
                    <span class="d-block text-body">
                        8.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>