<div
    class="account-settings-card"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <h4>
        Profile
    </h4>
    <p>
        Update your photo and personal details here.
    </p>
    <div class="row">
        <div class="col-sm-6">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    First Name
                </label>
                <mat-form-field>
                    <mat-label>
                        First name
                    </mat-label>
                    <input matInput value="Mateo">
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Last Name
                </label>
                <mat-form-field>
                    <mat-label>
                        Last name
                    </mat-label>
                    <input matInput value="Luca">
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Email Address
                </label>
                <mat-form-field>
                    <mat-label>
                        Email address
                    </mat-label>
                    <input matInput value="mateo@daxa.com">
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Phone Number
                </label>
                <mat-form-field>
                    <mat-label>
                        Phone number
                    </mat-label>
                    <input matInput value="+(555) 555-1234">
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Location
                </label>
                <mat-form-field>
                    <mat-label>
                        Location
                    </mat-label>
                    <input matInput value="Zuichi, Switzerland">
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Street Address
                </label>
                <mat-form-field>
                    <mat-label>
                        Street address
                    </mat-label>
                    <input matInput value="2445 Crosswind Drive">
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Date Of Birth
                </label>
                <mat-form-field>
                    <mat-label>
                        Date of birth
                    </mat-label>
                    <input matInput value="20 March 1999">
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Gender
                </label>
                <mat-form-field>
                    <mat-label>
                        Select
                    </mat-label>
                    <mat-select [(value)]="genderSelected">
                        <mat-option value="option1">
                            Male
                        </mat-option>
                        <mat-option value="option2">
                            Female
                        </mat-option>
                        <mat-option value="option3">
                            Custom
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Add Your Bio
                </label>
                <mat-form-field class="textarea">
                    <mat-label>
                        Write here....
                    </mat-label>
                    <textarea matInput placeholder="E.g. It makes me feel..."></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <h4>
        Your Photo
    </h4>
    <p>
        This will be displayed on your profile.
    </p>
    <div class="mb-25">
        <file-upload class="file-uploader" [multiple]="multiple"></file-upload>
    </div>
    <h4>
        Socials Profile
    </h4>
    <p></p>
    <div class="row">
        <div class="col-sm-6 col-lg-4">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Facebook
                </label>
                <mat-form-field>
                    <mat-label>
                        Facebook
                    </mat-label>
                    <input matInput value="https://www.facebook.com/">
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-6 col-lg-4">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Twitter
                </label>
                <mat-form-field>
                    <mat-label>
                        Twitter
                    </mat-label>
                    <input matInput value="https://twitter.com/">
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-6 col-lg-4">
            <div class="mb-25">
                <label class="main-label d-block lh-1 text-black">
                    Linkedin
                </label>
                <mat-form-field>
                    <mat-label>
                        Linkedin
                    </mat-label>
                    <input matInput value="https://www.instagram.com/">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="btn-box">
        <button mat-button>
            Update Profile
        </button>
        <button mat-button>
            Cancel
        </button>
    </div>
</div>