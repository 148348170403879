import {NgIf} from '@angular/common';
import {Component, ViewChild} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {Router, RouterLink} from '@angular/router';
import {SelectionModel} from '@angular/cdk/collections';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginator} from "@angular/material/paginator";
import {AddressModel} from "../../../../core/domain/models/address.model";
import {CustomizerSettingsService} from "../../customizer-settings/customizer-settings.service";
import {GetListAddressUseCase} from "../../../../application/use-cases/get-list-address.user-case";
import {FileUploadComponent} from "@iplab/ngx-file-upload";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {
    DialogScrollableContentComponent
} from "../../../components/ui-elements/dialog/dialog-scrollable-content/dialog-scrollable-content.component";
import {AddressDialogComponent} from "../address-dialog/address-dialog.component";
import {MatProgressBar} from "@angular/material/progress-bar";
import {MatDialog} from "@angular/material/dialog";
import {GetAddressInfoUseCase} from "../../../../application/use-cases/get-address-info.use-case";
import {AddressDialogUpdateComponent} from "../address-dialog-update/address-dialog-update.component";
import {ConfirmDialogComponent} from "../../components/confirm-dialog/confirm-dialog.component";
import {DeleteAddressUseCase} from "../../../../application/use-cases/delete-address.use-case";

@Component({
    selector: 'account-address',
    standalone: true,
    imports: [MatCardModule, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, NgIf, MatCheckboxModule, MatTooltipModule, FileUploadComponent, MatFormField, MatInput, MatLabel, MatOption, MatSelect, MatPaginator, DialogScrollableContentComponent, MatProgressBar],
    templateUrl: './addresses.component.html',
    styleUrl: './addresses.component.scss'
})
export class AddressesComponent {

    displayedColumns: string[] = ['id', 'addressLevel1', 'district', 'province', 'country', 'action'];
    dataSource = new MatTableDataSource<AddressModel>([]);
    selection = new SelectionModel<AddressModel>(true, []);

    addresses: AddressModel[] = [];
    isLoading: boolean = true;
    error: string | null = null;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    constructor(
        public themeService: CustomizerSettingsService,
        private getListAddressUseCase: GetListAddressUseCase,
        private deleteAddressUseCase: DeleteAddressUseCase,
        private router: Router,
        public dialog: MatDialog
    ) {
        this.getListAddress();
    }

    getListAddress(): void {
        this.getListAddressUseCase.execute()
            .subscribe({
                next: (data) => {
                    console.log(data);
                    this.addresses = data;
                    this.dataSource = new MatTableDataSource<AddressModel>(this.addresses);
                    this.isLoading = false;
                },
                error: (error) => {
                    this.error = 'Failed to load addresses';
                    this.isLoading = false;
                    console.error('Error:', error);
                }
            });
    }

    openAddressDialog(): void {
        const dialogRef = this.dialog.open(AddressDialogComponent, {
            panelClass: 'address-dialog-container'
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.getListAddress();  // Refresh the address list after closing the dialog
        });
    }

    openAddressDialogUpdate(id: number): void {
        const dialogRef = this.dialog.open(AddressDialogUpdateComponent, {
            panelClass: 'address-dialog-container',
            data: {id: id}
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.getListAddress();  // Refresh the address list after closing the dialog
        });
    }

    deleteAddress(id: number): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '750px',
            data: {
                title: 'Xác nhận xóa',
                message: 'Bạn có chắc chắn muốn xóa mục này?',
                confirmButtonText: 'Xóa',
                cancelButtonText: 'Hủy'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.deleteAddressUseCase.execute(id)
                    .subscribe({
                        next: (data) => {
                            console.log(data);
                            if (data.code == '0') {
                                this.getListAddress();  // Refresh the address list after closing the dialog
                            }
                        },
                        error: (error) => {
                            this.error = 'Failed to delete address';
                            console.error('Error:', error);
                        }
                    });
            }
        });
    }

    editAddress(id: any) {

    }
}