<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Chips Drag & Drop
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-chip-set
            cdkDropList
            cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="drop($event)"
        >
            @for (vegetable of vegetables; track vegetable) {
                <mat-chip cdkDrag>{{vegetable.name}}</mat-chip>
            }
        </mat-chip-set>
    </mat-card-content>
</mat-card>