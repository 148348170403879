<mat-card
    class="daxa-card advance-timeline-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Advance Timeline
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul class="timeline pl-0 mb-0 list-unstyled mt-0 position-relative">
            <li class="timeline-item position-relative">
                <div class="inner bg-gray border-radius">
                    <h5>
                        Project Kickoff
                    </h5>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Smith Johnson
                    </span>
                    <span class="description d-block">
                        Description:
                    </span>
                    <p>
                        Brief meeting to initiate the project
                    </p>
                </div>
                <div class="date bg-gray">
                    19 Nov, 2024 08:30AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner bg-gray border-radius">
                    <h5>
                        Design Mockups
                    </h5>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Mildred Garrett
                    </span>
                    <span class="description d-block">
                        Description:
                    </span>
                    <p>
                        Create visual mockups for client review
                    </p>
                </div>
                <div class="date bg-gray">
                    18 Nov, 2024 05:10AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner bg-gray border-radius">
                    <h5>
                        Requirement Gathering
                    </h5>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Jeffrey Peterson
                    </span>
                    <span class="description d-block">
                        Description:
                    </span>
                    <p>
                        Conduct interviews and gather project specs
                    </p>
                </div>
                <div class="date bg-gray">
                    17 Nov, 2024 10:93AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner bg-gray border-radius">
                    <h5>
                        Development Phase
                    </h5>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Gabriel Ward
                    </span>
                    <span class="description d-block">
                        Description:
                    </span>
                    <p>
                        Write code and build project functionalities
                    </p>
                </div>
                <div class="date bg-gray">
                    15 Nov, 2024  02:30PM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner bg-gray border-radius">
                    <h5>
                        Testing and QA
                    </h5>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Charles Thomas
                    </span>
                    <span class="description d-block">
                        Description:
                    </span>
                    <p>
                        Perform quality assurance and testing
                    </p>
                </div>
                <div class="date bg-gray">
                    14 Nov, 2024  06:48PM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner bg-gray border-radius">
                    <h5>
                        Client Review
                    </h5>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Craig Bannister
                    </span>
                    <span class="description d-block">
                        Description:
                    </span>
                    <p>
                        Present project to the client for feedback
                    </p>
                </div>
                <div class="date bg-gray">
                    12 Nov, 2024 04:20AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner bg-gray border-radius">
                    <h5>
                        Deployment
                    </h5>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Michael Winans
                    </span>
                    <span class="description d-block">
                        Description:
                    </span>
                    <p>
                        Deploy the project to the production server
                    </p>
                </div>
                <div class="date bg-gray">
                    11 Nov, 2024 08:30AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner bg-gray border-radius">
                    <h5>
                        Project Closure
                    </h5>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Robert Tipton
                    </span>
                    <span class="description d-block">
                        Description:
                    </span>
                    <p>
                        Finalize documentation and close the project
                    </p>
                </div>
                <div class="date bg-gray">
                    10 Nov, 2024 07:30AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
        </ul>
    </mat-card-content>
</mat-card>