import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterLink } from '@angular/router';
import { CustomizerSettingsService } from '../../../../components/customizer-settings/customizer-settings.service';

@Component({
    selector: 'app-course-content',
    standalone: true,
    imports: [MatCardModule, MatButtonModule, RouterLink, MatExpansionModule],
    templateUrl: './course-content.component.html',
    styleUrl: './course-content.component.scss'
})
export class CourseContentComponent {

    // Expansion Panel
    panelOpenState = false;

    // isToggled
    isToggled = false;

    constructor(
        public themeService: CustomizerSettingsService
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

}
