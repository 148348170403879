<div class="reset-password-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="daxa-form ml-auto mr-auto border-radius bg-white">
                <div class="title text-center">
                    <h3>
                        <label i18n>Create your first project now!</label>
                    </h3>
                    <p class="ml-auto mr-auto">
                        <label i18n>You don't have any projects yet? Create your first project!</label>
                    </p>
                </div>
                <form  (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="main-label d-block lh-1 text-black">
                            <label i18n>Project Name</label>
                        </label>
                        <mat-form-field>
                            <mat-label>
                                <label i18n>Enter Project Name</label>
                            </mat-label>
                            <input matInput [(ngModel)]="name" name="projectName">
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <label class="main-label d-block lh-1 text-black">
                            <label i18n>Project Description</label>
                        </label>
                        <mat-form-field>
                            <mat-label>
                                <label i18n>Enter Project Description</label>
                            </mat-label>
                            <input matInput [(ngModel)]="desc" name="projectDescription">
                        </mat-form-field>
                    </div>
                    <button mat-button class="btn" type="submit">
                        <label i18n>Create Project</label>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
