import { createAction, props } from '@ngrx/store';
import {NotificationModel} from "../../../core/domain/models/notification.model";

export const loadNotifications = createAction('[Notification] Load Notifications');
export const loadNotificationsSuccess = createAction(
  '[Notification] Load Notifications Success',
  props<{ notifications: NotificationModel[] }>()
);
export const loadNotificationsFailure = createAction(
  '[Notification] Load Notifications Failure',
  props<{ error: any }>()
);
// Action mới để cập nhật trạng thái icon khi có thông báo mới
export const newNotificationReceived = createAction(
  '[Notification] New Notification Received',
  props<{ notification: NotificationModel }>()  // Dữ liệu thông báo mới
);

export const setNotificationCount = createAction(
  '[Notification] Set Notification Count',
  props<{ count: number | string }>()
);
export const setNewNotification = createAction(
  '[Notification] Set New Notification',
  props<{ isNew: boolean }>()
);
