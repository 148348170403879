<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Event Details
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Pages
        </li>
        <li class="breadcrumb-item position-relative">
            Events
        </li>
        <li class="breadcrumb-item position-relative">
            Event Details
        </li>
    </ol>
</div>

<!-- Event Details Image -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block p-0">
    <mat-card-content>
        <img src="images/event-details.jpg" class="border-radius" style="margin-bottom: -4px;" alt="event-details-image">
    </mat-card-content>
</mat-card>

<!-- Event Details -->
<div class="row">
    <div class="col-xxxl-7">
        <mat-card
            class="daxa-card event-details-card mb-25 border-radius bg-white border-none d-block"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
            [class.component-dark-theme]="themeService.isDark()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Event Details
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span class="title d-block fw-medium">
                    Annual Conference 2024
                </span>
                <p>
                    The Annual Conference 2024 is a flagship event organized by ABC Corporation. This conference aims to bring together industry leaders, experts, and enthusiasts to discuss the latest trends, innovations, and challenges in our field. Participants can expect insightful keynote sessions, engaging panel discussions, and valuable networking opportunities.
                </p>
                <div class="info d-md-flex aling-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <div class="icon rounded-circle position-relative text-info text-center">
                            <i class="material-symbols-outlined">
                                schedule
                            </i>
                        </div>
                        <div>
                            <span class="d-block fw-medium">
                                Time
                            </span>
                            <span class="d-block text-body">
                                01 Dec, 2024, 09:00 AM
                            </span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="icon rounded-circle position-relative text-daxa text-center">
                            <i class="material-symbols-outlined">
                                place
                            </i>
                        </div>
                        <div>
                            <span class="d-block fw-medium">
                                Location
                            </span>
                            <span class="d-block text-body">
                                Convention Center
                            </span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="icon rounded-circle position-relative text-primary text-center">
                            <i class="material-symbols-outlined">
                                event_seat
                            </i>
                        </div>
                        <div>
                            <span class="d-block fw-medium">
                                Event Seat
                            </span>
                            <span class="d-block text-body">
                                50
                            </span>
                        </div>
                    </div>
                </div>
                <div class="btn-box">
                    <button mat-button>
                        <i class="material-symbols-outlined">
                            local_activity
                        </i>
                        Get Ticket
                    </button>
                    <button mat-button>
                        <i class="material-symbols-outlined">
                            event
                        </i>
                        Add To Calendar
                    </button>
                    <button mat-button>
                        <i class="material-symbols-outlined">
                            favorite_border
                        </i>
                        5937
                    </button>
                    <button mat-button>
                        <i class="material-symbols-outlined">
                            share
                        </i>
                        2582
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-5">
        <mat-card
            class="daxa-card event-details-card mb-25 border-radius bg-white border-none d-block"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Event Info
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul class="list pl-0 mb-0 mt-0 list-unstyled">
                    <li class="text-body">
                        <span class="text-black">08:00 AM</span> - Registration
                    </li>
                    <li class="text-body">
                        <span class="text-black">09:00 AM</span> - Opening Keynote
                    </li>
                    <li class="text-body">
                        <span class="text-black">10:30 AM</span> - Panel Discussion: Future Trends
                    </li>
                    <li class="text-body">
                        <span class="text-black">12:00 PM</span> - Lunch Break
                    </li>
                    <li class="text-body">
                        <span class="text-black">02:00 PM</span> - Interactive Workshops
                    </li>
                    <li class="text-body">
                        <span class="text-black">04:30 PM</span> - Networking Reception
                    </li>
                </ul>
                <h5>
                    Registration Information
                </h5>
                <ul class="list pl-0 mb-0 mt-0 list-unstyled">
                    <li class="text-body">
                        Early Bird Registration Deadline: November 15, 2024
                    </li>
                    <li class="text-body">
                        Standard Registration Deadline: November 30, 2024
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-7 col-xxxl-6">
        <mat-card
            class="daxa-card event-details-card mb-25 border-radius bg-white border-none d-block"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        About This Event
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>
                    Pellentesque viverra lorem malesuada nunc tristique sapien. Imperdiet sit hendrerit tincidunt bibendum donec adipiscing. Tellus non morbi nascetur cursus etiam facilisis mi. Dolor aliquam sed amet aliquam venenatis. Ac viverra interdum tortor enim. Molestie tincidunt ut consequat a urna tortor. Vitae velit ac nisl velit mauris placerat nisi placerat.
                </p>
                <p>
                    Imperdiet sit hendrerit tincidunt bibendum donec adipiscing. Tellus non morbi nascetur cursus etiam facilisis mi. Dolor aliquam sed amet aliquam venenatis. Ac viverra interdum tortor enim. Molestie tincidunt ut consequat a urna tortor. Vitae velit ac nisl velit mauris placerat nisi placerat.
                </p>
                <ul class="features-list pl-0 mb-0 list-unstyled mt-0">
                    <li class="position-relative text-body">
                        Pellentesque viverra lorem malesuada nunc tristique sapien.
                    </li>
                    <li class="position-relative text-body">
                        Imperdiet sit hendrerit tincidunt bibendum donec adipiscing.
                    </li>
                    <li class="position-relative text-body">
                        Tellus non morbi nascetur cursus etiam facilisis mi.
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-6">
        <mat-card
            class="daxa-card speakers-card mb-25 border-radius bg-white border-none d-block"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Speakers
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="speakers-table">
                    <div class="table-responsive">
                        <table mat-table [dataSource]="dataSource">
        
                            <!-- Speaker Column -->
                            <ng-container matColumnDef="speaker">
                                <th mat-header-cell *matHeaderCellDef>
                                    Speaker
                                </th>
                                <td mat-cell *matCellDef="let element" class="text-black">
                                    <div class="info d-flex align-items-center">
                                        <div class="image">
                                            <img [src]="element.speaker.img" alt="speaker-image">
                                        </div>
                                        <div>
                                            <span class="d-block fw-medium">
                                                {{element.speaker.name}}
                                            </span>
                                            <span class="d-block text-body">
                                                {{element.speaker.designation}}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
        
                            <!-- Topic Column -->
                            <ng-container matColumnDef="topic">
                                <th mat-header-cell *matHeaderCellDef>
                                    Topic
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.topic}}
                                </td>
                            </ng-container>
        
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                        </table>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>