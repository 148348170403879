<mat-card
    class="daxa-card sidebar-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-content>
        <form class="search-box position-relative">
            <i class="material-symbols-outlined">
                search
            </i>
            <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search...">
        </form>
        <a routerLink="/email/compose" class="btn" mat-button>
            Compose Email
        </a>
        <ul class="sidebar-list pl-0 list-unstyled">
            <li>
                <a routerLink="/email" class="d-flex align-items-center justify-content-between position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <i class="material-symbols-outlined">
                        inbox
                    </i>
                    Inbox
                    <span class="d-block text-body">
                        10
                    </span>
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        grade
                    </i>
                    Starred
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        snooze
                    </i>
                    Snoozed
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        send
                    </i>
                    Sent
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        drafts
                    </i>
                    Drafts
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        report_gmailerrorred
                    </i>
                    Spam
                    <span class="d-block text-body">
                        15
                    </span>
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        delete
                    </i>
                    Trash
                    <span class="d-block text-body">
                        7
                    </span>
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        bookmark_border
                    </i>
                    Important
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        email
                    </i>
                    All Mail
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        person
                    </i>
                    Personal
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        business_center
                    </i>
                    Company
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        attach_money
                    </i>
                    Wallet Balance
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        people
                    </i>
                    Friends
                </a>
            </li>
        </ul>
        <button
            mat-button
            class="text-daxa p-0 h-auto"
        >
            + Create New Label
        </button>
    </mat-card-content>
</mat-card>