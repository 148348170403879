<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Datepicker Emulating a Year & Month Picker
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field>
            <mat-label>Month and Year</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="date">
            <mat-hint>MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (monthSelected)="setMonthAndYear($event, dp)">
        </mat-datepicker>
        </mat-form-field>
    </mat-card-content>
</mat-card>