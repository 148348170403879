import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import {UserAuthInterface} from "../../core/ports/user-auth.interface";

@Injectable({
  providedIn: 'root'
})
export class UserAuthService implements UserAuthInterface{

  constructor(private oauthService: OAuthService) {}

  login() {
    this.oauthService.initImplicitFlow();
  }

  logout() {
    this.oauthService.logOut();
  }

}
