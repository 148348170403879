<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Simple Form Field
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        
        <mat-form-field class="mb-25">
            <mat-label>Input</mat-label>
            <input matInput>
        </mat-form-field>

        <mat-form-field class="mb-25">
            <mat-label>Select</mat-label>
            <mat-select>
                <mat-option value="one">First option</mat-option>
                <mat-option value="two">Second option</mat-option>
            </mat-select>
        </mat-form-field>
        
        <mat-form-field>
            <mat-label>Textarea</mat-label>
            <textarea matInput></textarea>
        </mat-form-field>

    </mat-card-content>
</mat-card>