<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Cards
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Cards
        </li>
    </ol>
</div>

<!-- Cards -->
<div class="row">
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Basic Card
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span class="badge text-outline-daxa mr-15">Daxa</span>
                <span class="badge text-outline-warning">Warning</span>
                <h5 class="mb-10 mt-20">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
                <button mat-stroked-button class="daxa">Daxa</button>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="daxa-card mb-25 border-radius bg-white border-none d-block"
            style="background-image: url(images/cards/card2.jpg); background-position: center center; background-repeat: no-repeat; background-size: cover;"
        >
            <mat-card-content>
                <span class="badge text-bg-daxa mr-15">Daxa</span>
                    <span class="badge text-bg-primary">Primary</span>
                    <div style="height: 250px;"></div>
                    <h4 class="mb-10 mt-20 text-white">Nesta Technologies</h4>
                    <p class="text-white">When you enter into any new area of science, you almost always any new area of science find.</p>
            </mat-card-content>
        </mat-card>
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="badge text-bg-danger mr-15">Danger</span>
                <span class="badge text-bg-warning">Warning</span>
                <h5 class="mb-10 mt-20">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
                <button mat-flat-button class="daxa">Daxa</button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="badge text-bg-daxa mr-15">Daxa</span>
                <span class="badge text-bg-info">Info</span>
                <img src="images/cards/card1.jpg" class="border-radius mt-15" alt="card">
                <h5 class="mb-10 mt-15">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
                <button mat-flat-button class="daxa">Daxa</button>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="daxa-card mb-25 border-radius bg-white border-none d-block"
            style="background-image: url(images/cards/card4.jpg); background-position: center center; background-repeat: no-repeat; background-size: cover;"
        >
            <mat-card-content>
                <span class="badge text-bg-danger mr-15">Danger</span>
                <span class="badge text-bg-warning">Warning</span>
                <div style="height: 200px;"></div>
                <h4 class="mb-10 mt-20 text-white">Nesta Technologies</h4>
                <p class="text-white">When you enter into any new area of science, you almost always any new area of science find.</p>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="badge text-soft-danger mr-15">Danger</span>
                <span class="badge text-soft-warning">Warning</span>
                <h5 class="mb-10 mt-20">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
                <button mat-raised-button color="accent">Accent</button>
            </mat-card-content>
        </mat-card>
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Daxa Card
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <span class="badge text-soft-daxa mr-15">Daxa</span>
                <span class="badge text-soft-success">Success</span>
                <img src="images/cards/card3.jpg" class="border-radius mt-15" alt="card">
                <h5 class="mb-10 mt-15">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
                <button mat-flat-button color="warn">Warn</button>
            </mat-card-content>
        </mat-card>
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <h5 class="mb-10">Nesta Technologies</h5>
                <p>When you enter into any new area of science, you almost always any new area of science find.</p>
                <button mat-mini-fab matTooltip="Daxa" class="daxa">
                    <mat-icon>favorite</mat-icon>
                </button>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Material Cards
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Material Cards
        </li>
    </ol>
</div>

<!-- Material Cards -->
<div class="row">
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25">
            <mat-card-content class="fw-medium">Simple card</mat-card-content>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header>
                <mat-card-subtitle>Card with footer</mat-card-subtitle>
                <mat-card-title class="fw-medium mb-10 mt-10">Mateo Luca</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>This card has divider and indeterminate progress as footer</p>
                <p>{{ longText }}</p>
                <mat-divider></mat-divider>
            </mat-card-content>
            <mat-card-actions class="ps-15 mt-10 mb-10">
                <button mat-flat-button class="gray mr-15">LIKE</button>
                <button mat-flat-button class="gray">SHARE</button>
            </mat-card-actions>
            <mat-card-footer>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header class="mb-15">
                <mat-card-title-group>
                    <mat-card-title class="fw-medium mb-10">Card with media size</mat-card-title>
                    <mat-card-subtitle>Small</mat-card-subtitle>
                    <img mat-card-sm-image src="images/products/product2.jpg">
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                <p>{{longText}}</p>
            </mat-card-content>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header class="mb-15">
                <mat-card-title-group>
                    <mat-card-title class="fw-medium mb-10">Card with media size</mat-card-title>
                    <mat-card-subtitle>Medium</mat-card-subtitle>
                    <img mat-card-md-image src="images/products/product3.jpg"  alt="Image of a Mateo Luca">
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                <p>{{longText}}</p>
            </mat-card-content>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header class="mb-15">
                <mat-card-title-group>
                    <mat-card-title class="fw-medium mb-10">Card with media size</mat-card-title>
                    <mat-card-subtitle>Large</mat-card-subtitle>
                    <img mat-card-lg-image src="images/products/product4.jpg">
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                <p>{{longText}}</p>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25">
            <mat-card-header>
                <mat-card-title class="fw-medium mb-10">Card with actions alignment option</mat-card-title>
                <mat-card-subtitle>Start</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions class="ps-15 mt-10 mb-10">
                <button mat-flat-button class="daxa mr-15">LIKE</button>
                <button mat-flat-button color="warn">SHARE</button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header>
                <mat-card-title class="fw-medium mb-10">Card with actions alignment option</mat-card-title>
                <mat-card-subtitle>End</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions align="end" class="pe-15 mb-10">
                <button mat-flat-button class="daxa mr-15">LIKE</button>
                <button mat-flat-button color="warn">SHARE</button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header>
                <mat-card-title class="fw-medium mb-10">Card with sub-title</mat-card-title>
                <mat-card-subtitle class="mb-10">Dog Breed</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <p>This card indeterminates progress bar.</p>
                <p>{{longText}}</p>
            </mat-card-content>
            <mat-card-actions class="ps-15 mt-10 mb-10">
                <button mat-flat-button class="daxa mr-15">LIKE</button>
                <button mat-flat-button color="warn">SHARE</button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="mb-25">
            <mat-card-header class="mb-15">
                <mat-card-title-group>
                    <mat-card-title class="fw-medium mb-10">Card with media size</mat-card-title>
                    <mat-card-subtitle>Extra large</mat-card-subtitle>
                    <img mat-card-xl-image src="images/products/product5.jpg">
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                <p>{{longText}}</p>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="mb-25">
            <mat-card-header>
                <div mat-card-avatar style="background-image: url(images/admin.png); background-size: cover;"></div>
                <mat-card-title class="fw-semibold mb-5">Card with multiple sections</mat-card-title>
                <mat-card-subtitle>Dog Breed</mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image src="images/products/product1.jpg" alt="Photo of a Product Image">
            <mat-card-content>
                <div class="pt-15"></div>
                <p>The Mateo Luca is the smallest of the six original and distinct spitz breeds of dog from Japan. A small, agile dog that copes very well with mountainous terrain, the Mateo Luca was originally bred for.</p>
            </mat-card-content>
            <mat-card-actions class="ps-15 mt-10 mb-10">
                <button mat-flat-button class="daxa mr-15">LIKE</button>
                <button mat-flat-button color="warn">SHARE</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>