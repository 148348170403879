import {Inject, Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {PROJECT_TOKEN} from "../../core/ports/project.token";
import {ProjectInterface} from "../../core/ports/project.interface";
import {ProjectModel} from "../../core/domain/models/project.model";
import {NOTIFICATION_TOKEN, NotificationInterface} from "../../core/ports/notification.interface";
import {ResultModel} from "../../core/domain/models/result.model";

@Injectable({
    providedIn: 'root'
})
export class CreateFcmTokenUseCase {
    constructor(@Inject(NOTIFICATION_TOKEN)  private notificationInterface: NotificationInterface) {}
    execute(token: string ): Observable<ResultModel> {
        return this.notificationInterface.createFcmToken(token).pipe(
            catchError(error => {
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    // Xử lý lỗi
    private handleError(error: any): void {
        console.error('Error create fcm token', error);
    }
}
