<div>
    <a routerLink="/to-do-list" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            ballot
        </i>
        <span class="title">To Do List</span>
    </a>
    <a routerLink="/calendar" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            calendar_today
        </i>
        <span class="title">Calendar</span>
    </a>
    <a routerLink="/contacts" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            perm_contact_calendar
        </i>
        <span class="title">Contacts</span>
    </a>
    <a routerLink="/chat" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            chat
        </i>
        <span class="title">Chat</span>
    </a>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="material-symbols-outlined">
                    email
                </i>
                <span class="title">Email</span>
                <span class="daxa-badge two">3</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidebar-sub-menu">
            <li class="sidemenu-item">
                <a routerLink="/email" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Inbox
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/email/compose" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Compose
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/email/read" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Read
                </a>
            </li>
        </ul>
    </mat-expansion-panel>
    <a routerLink="/kanban-board" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            keyboard_command_key
        </i>
        <span class="title">Kanban Board</span>
    </a>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="material-symbols-outlined">
                    insert_drive_file
                </i>
                <span class="title">File Manager</span>
                <span class="daxa-badge three">7</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidebar-sub-menu">
            <li class="sidemenu-item">
                <a routerLink="/file-manager" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    My Drive
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/file-manager/assets" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Assets
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/file-manager/projects" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Projects
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/file-manager/personal" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Personal
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/file-manager/applications" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Applications
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/file-manager/documents" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Documents
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/file-manager/media" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Media
                </a>
            </li>
        </ul>
    </mat-expansion-panel>
</div>
