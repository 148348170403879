<mat-card class="daxa-card ave-resolution-time-card mb-25 bg-white border-radius border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Ave Resolution Time
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                Last 30 days
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    Last 30 days
                </button>
                <button mat-menu-item>
                    Last 15 days
                </button>
                <button mat-menu-item>
                    Last 7 days
                </button>
                <button mat-menu-item>
                    Last Day
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <h3 class="lh-1 mb-0 text-center">
            10 <span class="fw-normal">hrs</span> : 30 <span class="fw-normal">mins</span>
        </h3>
        <div class="chart">
            <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [xaxis]="chartOptions.xaxis!"
                [stroke]="chartOptions.stroke!"
                [dataLabels]="chartOptions.dataLabels!"
                [yaxis]="chartOptions.yaxis!"
                [tooltip]="chartOptions.tooltip!"
                [labels]="chartOptions.labels!"
                [legend]="chartOptions.legend!"
                [colors]="chartOptions.colors!"
                [grid]="chartOptions.grid!"
                [title]="chartOptions.title!"
                [subtitle]="chartOptions.subtitle!"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>