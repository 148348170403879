<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Date Range Picker Forms Integration
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            @if (range.controls.start.hasError('matStartDateInvalid')) {
                <mat-error>Invalid start date</mat-error>
            }
            @if (range.controls.end.hasError('matEndDateInvalid')) {
                <mat-error>Invalid end date</mat-error>
            }
        </mat-form-field>
        <p style="margin-top: 8px;">Selected range: {{range.value | json}}</p>
    </mat-card-content>
</mat-card>