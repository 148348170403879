<mat-card class="daxa-card projects-overview-card mb-25 pb-0 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Projects Overview
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-lg-6">

                <!-- Total Projects -->
                <app-total-projects />

            </div>
            <div class="col-lg-6">

                <!-- Active Projects -->
                <app-active-projects />

            </div>
            <div class="col-lg-6">

                <!-- Completed Projects -->
                <app-completed-projects />

            </div>
            <div class="col-lg-6">

                <!-- Total Members -->
                <app-total-members />

            </div>
        </div>
    </mat-card-content>
</mat-card>