<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Area Charts
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Apexcharts
        </li>
        <li class="breadcrumb-item position-relative">
            Area Charts
        </li>
    </ol>
</div>

<!-- Area Charts -->
<div class="row">
    <div class="col-lg-6">

        <!-- Basic Area Chart -->
        <app-basic-area-chart />

    </div>
    <div class="col-lg-6">

        <!-- Spline Area Chart -->
        <app-spline-area-chart />

    </div>
    <div class="col-lg-6">

        <!-- Datetime Area Chart -->
        <app-datetime-area-chart />

    </div>
    <div class="col-lg-6">

        <!-- Negative Values Area Chart -->
        <app-negative-values-area-chart />

    </div>
    <div class="col-lg-6">

        <!-- Stacked Area Chart -->
        <app-stacked-area-chart />

    </div>
    <div class="col-lg-6">

        <!-- Missing Null Values Area Chart -->
        <app-missing-null-values-area-chart />

    </div>
    <div class="col-lg-6">

        <!-- Github Style Area Chart -->
        <app-github-style-area-chart />

    </div>
</div>