<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        <label i18n >Project List</label>
    </h5>
</div>

<!-- Team Members -->
<mat-card
    class="daxa-card team-member-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <a mat-button class="add-new-btn" routerLink="/create-project">
                <label i18n >+ New Project</label>
            </a>
        </mat-card-title>
        <mat-card-subtitle>
            <form class="search-box position-relative">
                <i class="material-symbols-outlined">
                    search
                </i>
                <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search project...">
            </form>
        </mat-card-subtitle>
    </mat-card-header>
</mat-card>

<div class="row">
    <div class="col-lg-4 col-xxl-3 col-sm-6" *ngFor="let content of teamMemberCard;">
        <mat-card
            class="daxa-card team-member-card mb-25 border-radius bg-white border-none d-block"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item (click)="viewProject(content.id)">
                            <label i18n >View</label>
                        </button>
                        <button mat-menu-item>
                            <label i18n >Delete</label>
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <h5 class="mb-0">
                        {{content.name}}
                    </h5>
                </div>
                <ul class="pl-0 mb-0 list-unstyled">
                    <li>
                        <span class="text-body">
                            ID:
                        </span>
                        {{content.id}}
                    </li>
                    <li>
                        <span class="text-body">
                            <label i18n >DESC:</label>
                        </span>
                        {{content.name}}
                    </li>

                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>
