<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Instructors
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            LMS
        </li>
        <li class="breadcrumb-item position-relative">
            Instructors
        </li>
    </ol>
</div>

<!-- Instructors -->
<mat-card
    class="daxa-card instructors-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <form class="search-box position-relative">
                <i class="material-symbols-outlined">
                    search
                </i>
                <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search instructor..." (keyup)="applyFilter($event)" #input>
            </form>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                Last 30 days
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    Last 30 days
                </button>
                <button mat-menu-item>
                    Last 15 days
                </button>
                <button mat-menu-item>
                    Last 7 days
                </button>
                <button mat-menu-item>
                    Last Day
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="instructors-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox
                                (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()"
                            >
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>
                            ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.id}}
                        </td>
                    </ng-container>

                    <!-- Instructor Column -->
                    <ng-container matColumnDef="instructor">
                        <th mat-header-cell *matHeaderCellDef>
                            Instructor
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black">
                            <div class="info d-flex align-items-center">
                                <div class="image">
                                    <img [src]="element.instructor.img" alt="instructor-image">
                                </div>
                                <span class="d-block fw-medium">
                                    {{element.instructor.name}}
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Total Course Column -->
                    <ng-container matColumnDef="totalCourse">
                        <th mat-header-cell *matHeaderCellDef>
                            Total Course
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.totalCourse}}
                        </td>
                    </ng-container>

                    <!-- Total Earnings Column -->
                    <ng-container matColumnDef="totalEarnings">
                        <th mat-header-cell *matHeaderCellDef>
                            Total Earnings
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.totalEarnings}}
                        </td>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>
                            Email
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.email}}
                        </td>
                    </ng-container>

                    <!-- Ratings Column -->
                    <ng-container matColumnDef="ratings">
                        <th mat-header-cell *matHeaderCellDef>
                            Ratings
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.ratings}}
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="daxa-badge active d-inline-block" *ngIf="element.status.active">
                                {{element.status.active}}
                            </span>
                            <span class="daxa-badge deactive d-inline-block" *ngIf="element.status.deactive">
                                {{element.status.deactive}}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="action-info">
                                <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action}}
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>