<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Material Symbols
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Pages
        </li>
        <li class="breadcrumb-item position-relative">
            Icons
        </li>
        <li class="breadcrumb-item position-relative">
            Material Symbols
        </li>
    </ol>
</div>

<!-- Material Symbols -->
<mat-card
    class="daxa-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
>
    <mat-card-content>
        <code>
            &lt;<span class="red">span</span><span class="green">class</span>=<span class="blue">&quot;material-symbols-outlined&quot;</span>&gt;search&lt;/<span class="red">span</span>&gt;
        </code>
    </mat-card-content>
</mat-card>

<!-- Icons -->
<div class="row">
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    search
                </span>
                <span class="title d-block text-body">
                    search
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    home
                </span>
                <span class="title d-block text-body">
                    home
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    menu
                </span>
                <span class="title d-block text-body">
                    menu
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    close
                </span>
                <span class="title d-block text-body">
                    close
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    settings
                </span>
                <span class="title d-block text-body">
                    settings
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    done
                </span>
                <span class="title d-block text-body">
                    done
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    expand_more
                </span>
                <span class="title d-block text-body">
                    expand_more
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    search
                </span>
                <span class="title d-block text-body">
                    search
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    check_circle
                </span>
                <span class="title d-block text-body">
                    check_circle
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    favorite
                </span>
                <span class="title d-block text-body">
                    favorite
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    add
                </span>
                <span class="title d-block text-body">
                    add
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    arrow_back
                </span>
                <span class="title d-block text-body">
                    arrow_back
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    star
                </span>
                <span class="title d-block text-body">
                    star
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    chevron_right
                </span>
                <span class="title d-block text-body">
                    chevron_right
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    logout
                </span>
                <span class="title d-block text-body">
                    logout
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    arrow_forward_ios
                </span>
                <span class="title d-block text-body">
                    arrow_forward_ios
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    add_circle
                </span>
                <span class="title d-block text-body">
                    add_circle
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    cancel
                </span>
                <span class="title d-block text-body">
                    cancel
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    arrow_back_ios
                </span>
                <span class="title d-block text-body">
                    arrow_back_ios
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    arrow_forward
                </span>
                <span class="title d-block text-body">
                    arrow_forward
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    arrow_drop_down
                </span>
                <span class="title d-block text-body">
                    arrow_drop_down
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    check
                </span>
                <span class="title d-block text-body">
                    check
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    check_box
                </span>
                <span class="title d-block text-body">
                    check_box
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    toggle_on
                </span>
                <span class="title d-block text-body">
                    toggle_on
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    open_in_new
                </span>
                <span class="title d-block text-body">
                    open_in_new
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    grade
                </span>
                <span class="title d-block text-body">
                    grade
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    refresh
                </span>
                <span class="title d-block text-body">
                    refresh
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    check_box_outline_blank
                </span>
                <span class="title d-block text-body">
                    check_box_outline_blank
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    login
                </span>
                <span class="title d-block text-body">
                    login
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="daxa-card icon-card text-center mb-25 border-radius bg-white border-none d-block">
            <mat-card-content>
                <span class="material-symbols-outlined">
                    chevron_left
                </span>
                <span class="title d-block text-body">
                    chevron_left
                </span>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- View All Icons Link -->
<div class="mb-25 text-center">
    <a mat-button href="https://fonts.google.com/icons" class="btn" target="_blank">
        View All Icons
    </a>
</div>