<mat-card
    class="daxa-card students-progress-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Student's Progress
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="students-progress-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>
                            ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.id}}
                        </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black fw-medium">
                            {{element.name}}
                        </td>
                    </ng-container>

                    <!-- Course Name Column -->
                    <ng-container matColumnDef="courseName">
                        <th mat-header-cell *matHeaderCellDef>
                            Course Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a routerLink="/lms-page/course-details" class="text-body">
                                {{element.courseName}}
                            </a>
                        </td>
                    </ng-container>

                    <!-- Progress Column -->
                    <ng-container matColumnDef="progress">
                        <th mat-header-cell *matHeaderCellDef>
                            Progress
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="percentage d-block">
                                {{element.progress}}%
                            </span>
                            <mat-progress-bar mode="determinate" [value]="element.progress"></mat-progress-bar>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>