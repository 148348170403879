<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Create Project
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Project Management
        </li>
        <li class="breadcrumb-item position-relative">
            Projects
        </li>
        <li class="breadcrumb-item position-relative">
            Create Project
        </li>
    </ol>
</div>

<!-- Create Project -->
<mat-card
    class="daxa-card create-project-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Create New Project
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <div class="row">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Project Name
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Write project name
                                    </mat-label>
                                    <input matInput placeholder="E.g. Hotel management system">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Project ID
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Write project ID
                                    </mat-label>
                                    <input matInput placeholder="E.g. #951">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Start Date
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Choose a date
                                    </mat-label>
                                    <input matInput [matDatepicker]="startDate">
                                    <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                    <mat-datepicker #startDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    End Date
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Choose a date
                                    </mat-label>
                                    <input matInput [matDatepicker]="endDate">
                                    <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                    <mat-datepicker #endDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Project Description
                                </label>
                                <div class="NgxEditor__Wrapper">
                                    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                                    <ngx-editor [editor]="editor" [placeholder]="'Type here...'"></ngx-editor>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Budget
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Enter budget
                                    </mat-label>
                                    <input matInput placeholder="E.g. $5,250">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Priority Status
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Select
                                    </mat-label>
                                    <mat-select>
                                        <mat-option value="option1">
                                            In Progress
                                        </mat-option>
                                        <mat-option value="option2">
                                            Pending
                                        </mat-option>
                                        <mat-option value="option3">
                                            Completed
                                        </mat-option>
                                        <mat-option value="option4">
                                            Not Started
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Categories
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Select
                                    </mat-label>
                                    <mat-select>
                                        <mat-option value="option1">
                                            Watch
                                        </mat-option>
                                        <mat-option value="option2">
                                            Electronics
                                        </mat-option>
                                        <mat-option value="option3">
                                            Sports
                                        </mat-option>
                                        <mat-option value="option4">
                                            Fashion
                                        </mat-option>
                                        <mat-option value="option5">
                                            Jewellery
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Project Manager
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Select
                                    </mat-label>
                                    <mat-select>
                                        <mat-option value="option1">
                                            Walter Frazier
                                        </mat-option>
                                        <mat-option value="option2">
                                            Kimberly Anderson
                                        </mat-option>
                                        <mat-option value="option3">
                                            Roscoe Guerrero
                                        </mat-option>
                                        <mat-option value="option4">
                                            Robert Stewart
                                        </mat-option>
                                        <mat-option value="option4">
                                            Dustin Fritch
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Add Team Members
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Select
                                    </mat-label>
                                    <mat-select multiple>
                                        <mat-option value="option1">
                                            Walter Frazier
                                        </mat-option>
                                        <mat-option value="option2">
                                            Kimberly Anderson
                                        </mat-option>
                                        <mat-option value="option3">
                                            Roscoe Guerrero
                                        </mat-option>
                                        <mat-option value="option4">
                                            Robert Stewart
                                        </mat-option>
                                        <mat-option value="option5">
                                            Dustin Fritch
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Project Tags
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Select
                                    </mat-label>
                                    <mat-select multiple>
                                        <mat-option value="option1">
                                            Watch
                                        </mat-option>
                                        <mat-option value="option2">
                                            Electronics
                                        </mat-option>
                                        <mat-option value="option3">
                                            Sports
                                        </mat-option>
                                        <mat-option value="option4">
                                            Fashion
                                        </mat-option>
                                        <mat-option value="option5">
                                            Jewellery
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Project Preview Image
                        </label>
                        <file-upload class="file-uploader" [multiple]="multiple"></file-upload>
                    </div>
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Attached Files
                        </label>
                        <file-upload class="file-uploader" [multiple]="multiple"></file-upload>
                    </div>
                </div>
            </div>
            <div class="btn-box">
                <button mat-button>
                    + Create Project
                </button>
                <button mat-button>
                    Cancel
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>