<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Chips Autocomplete
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <mat-form-field>
                <mat-label>Favorite Fruits</mat-label>
                <mat-chip-grid #chipGrid aria-label="Fruit selection">
                    @for (fruit of fruits; track fruit) {
                        <mat-chip-row (removed)="remove(fruit)">
                            {{fruit}}
                            <button matChipRemove [attr.aria-label]="'remove ' + fruit">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    }
                </mat-chip-grid>
                <input placeholder="New Fruit..." #fruitInput [formControl]="fruitCtrl"
                [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"/>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    @for (fruit of filteredFruits | async; track fruit) {
                        <mat-option [value]="fruit">{{fruit}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </mat-card-content>
</mat-card>