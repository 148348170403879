<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Bottom Sheet
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Bottom Sheet
        </li>
    </ol>
</div>

<!-- Bottom Sheet -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Bottom Sheet Overview
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>You have received a file called "cat-picture.jpeg".</p>
        <button mat-raised-button (click)="openBottomSheet()">Open file</button>
    </mat-card-content>
</mat-card>