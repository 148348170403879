<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Notifications
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Pages
        </li>
        <li class="breadcrumb-item position-relative">
            Notifications
        </li>
    </ol>
</div>

<!-- Notifications -->
<mat-card class="daxa-card notifications-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Notifications
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="notifications-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Notification ID Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>
                            Notification ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.id}}
                        </td>
                    </ng-container>

                    <!-- Timestamp Column -->
                    <ng-container matColumnDef="channel">
                        <th mat-header-cell *matHeaderCellDef>
                            channel
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.channel}}
                        </td>
                    </ng-container>

                    <!-- Type Column -->
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef>
                            Type
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black fw-medium">
                            {{element.type}}
                        </td>
                    </ng-container>

                    <!-- Content Column -->
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>
                            Title
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black fw-medium">
                            {{element.title}}
                        </td>
                    </ng-container>

                    <!-- Content Column -->
                    <ng-container matColumnDef="body">
                        <th mat-header-cell *matHeaderCellDef>
                            Content
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black fw-medium">
                            {{element.body}}
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="daxa-badge read d-inline-block" *ngIf="element.isRead">
                                Read
                            </span>
                            <span class="daxa-badge unread d-inline-block" *ngIf="!element.isRead">
                                UnRead
                            </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
        </div>
    </mat-card-content>
</mat-card>