import {Inject, Injectable} from '@angular/core';
import { UserInfoInterface } from '../../core/ports/user-info.interface';
import {USER_INFO_TOKEN} from "../../core/ports/user-info.token";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {UpdateUserInfoRequest} from "../../infrastructure/adapters/http/requests/user-info.request";

@Injectable({
    providedIn: 'root'
})
export class UpdateUserInfoUseCase {
    constructor(
        @Inject(USER_INFO_TOKEN) private userInfoPort: UserInfoInterface
    ) {}

    execute( userInfo: UpdateUserInfoRequest) {
        return this.userInfoPort.updateUserInfo(userInfo).pipe(
            catchError(error => {
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    // Xử lý lỗi
    private handleError(error: any): void {
        console.error('Error fetching user info', error);
    }
}
