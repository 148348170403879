export const series = {
    leads: [
        198,
        212,
        200,
        230
    ],
    dates: [
        "10 Mar 2024",
        "11 Mar 2024",
        "12 Mar 2024",
        "13 Mar 2024"
    ]
};