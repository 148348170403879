<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Sidenav
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Sidenav
        </li>
    </ol>
</div>

<!-- Autosize Sidenav -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Autosize Sidenav
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-autosize-sidenav />
    </mat-card-content>
</mat-card>

<!-- Basic Drawer -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Basic Drawer
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-basic-drawer />
    </mat-card-content>
</mat-card>

<!-- Drawer with Explicit Backdrop Setting -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Drawer with Explicit Backdrop Setting
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-dwe-backdrop-setting />
    </mat-card-content>
</mat-card>