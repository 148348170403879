// mappers/my-profile.mapper.ts
import { UserInfoModel } from '../models/user-info.model';
import { MyProfileModel } from '../models/my-profile.model';

// Hàm mapper để ánh xạ từ UserInfoModel sang MyProfileModel
export function mapUserInfoToMyProfile(userInfo: UserInfoModel): MyProfileModel {
  return {
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    username: userInfo.username, // Ánh xạ từ UserInfoModel
    email: userInfo.email,
    id: userInfo.id,
    fullName: userInfo.fullName, // Tạo fullName từ firstName và lastName
    avatarThumbnailUrl: userInfo.avatarThumbnailUrl,
    avatarUrl: userInfo.avatarUrl,
    birthDay: userInfo.birthDay,
    gender: userInfo.gender,
    mobile: userInfo.mobile,
  };
}
