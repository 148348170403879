<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Tickets
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Help Desk
        </li>
        <li class="breadcrumb-item position-relative">
            Tickets
        </li>
    </ol>
</div>

<!-- Tickets Overview -->
<div class="row">
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets Open -->
        <app-tickets-open />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets In Progress -->
        <app-tickets-in-progress />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets Resolved -->
        <app-tickets-resolved />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets Closed -->
        <app-tickets-closed />

    </div>
</div>

<!-- All Tickets -->
<mat-card
    class="daxa-card all-tickets-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                All Tickets
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="all-tickets-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Ticket ID Column -->
                    <ng-container matColumnDef="ticketID">
                        <th mat-header-cell *matHeaderCellDef>
                            Ticket ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.ticketID}}
                        </td>
                    </ng-container>

                    <!-- Subject Column -->
                    <ng-container matColumnDef="subject">
                        <th mat-header-cell *matHeaderCellDef>
                            Subject
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a routerLink="/help-desk-page/ticket-details" class="d-inline-block fw-medium">
                                {{element.subject}}
                            </a>
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="daxa-badge in-progress d-inline-block" *ngIf="element.status.inProgress">
                                {{element.status.inProgress}}
                            </span>
                            <span class="daxa-badge pending d-inline-block" *ngIf="element.status.pending">
                                {{element.status.pending}}
                            </span>
                            <span class="daxa-badge open d-inline-block" *ngIf="element.status.open">
                                {{element.status.open}}
                            </span>
                            <span class="daxa-badge closed d-inline-block" *ngIf="element.status.closed">
                                {{element.status.closed}}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Created Date Column -->
                    <ng-container matColumnDef="createdDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Created Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.createdDate}}
                        </td>
                    </ng-container>

                    <!-- Due Date Column -->
                    <ng-container matColumnDef="dueDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Due Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.dueDate}}
                        </td>
                    </ng-container>

                    <!-- Requester Column -->
                    <ng-container matColumnDef="requester">
                        <th mat-header-cell *matHeaderCellDef>
                            Requester
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black">
                            {{element.requester}}
                        </td>
                    </ng-container>

                    <!-- Priority Column -->
                    <ng-container matColumnDef="priority">
                        <th mat-header-cell *matHeaderCellDef>
                            Priority
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.priority}}
                        </td>
                    </ng-container>

                    <!-- Assigned Agents Column -->
                    <ng-container matColumnDef="assignedAgents">
                        <th mat-header-cell *matHeaderCellDef>
                            Assigned Agents
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="team-members-list">
                                <img [src]="element.assignedAgents.img1" alt="team-member-image" *ngIf="element.assignedAgents.img1">
                                <img [src]="element.assignedAgents.img2" alt="team-member-image" *ngIf="element.assignedAgents.img2">
                                <img [src]="element.assignedAgents.img3" alt="team-member-image" *ngIf="element.assignedAgents.img3">
                                <img [src]="element.assignedAgents.img4" alt="team-member-image" *ngIf="element.assignedAgents.img4">
                                <img [src]="element.assignedAgents.img5" alt="team-member-image" *ngIf="element.assignedAgents.img5">
                            </div>
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="action-info">
                                <a mat-button routerLink="/help-desk-page/ticket-details" matTooltip="View Ticket" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.view}}
                                    </i>
                                </a>
                                <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.delete}}
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>