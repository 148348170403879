<mat-card class="daxa-card total-instructors-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-content>
        <div class="title">
            <h5>
                Total Instructors
            </h5>
            <div class="number fw-medium lh-1">
                1K+
            </div>
        </div>
        <div class="icon text-center bg-primary rounded-circle text-white position-relative">
            <img src="images/icons/instructor.svg" alt="instructor-icon">
        </div>
        <div class="info d-flex align-items-center justify-content-between">
            <span class="sub-title d-block text-body">
                Last Month
            </span>
            <span class="daxa-up-down-badge position-relative">
                <i class="material-symbols-outlined">
                    trending_up
                </i>
                50.05%
            </span>
        </div>
    </mat-card-content>
</mat-card>