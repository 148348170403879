import { HttpInterceptorFn } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpHandlerFn, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {inject} from "@angular/core";

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const oauthService = inject(OAuthService);

    const token = oauthService.getAccessToken();

    if (token) {
        const cloned = req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
        return next(cloned).pipe(
            catchError((error) => {
                if (error.status === 401) {
                    oauthService.initLoginFlow();
                }
                return throwError(error);
            })
        );
    } else {
        return next(req).pipe(
            catchError((error) => {
                if (error.status === 401) {
                    oauthService.initLoginFlow();
                }
                return throwError(error);
            })
        );
    }
};
