<mat-stepper #stepper>
    <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Fill out your name</ng-template>
            <mat-form-field class="mb-20">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
            </mat-form-field>
            <div>
                <button mat-flat-button class="daxa" matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" label="Fill out your address">
        <form [formGroup]="secondFormGroup">
            <mat-form-field class="mb-20">
                <mat-label>Address</mat-label>
                <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" required>
            </mat-form-field>
            <div>
                <button mat-flat-button color="warn" class="mr-15" matStepperPrevious>Back</button>
                <button mat-flat-button class="daxa" matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <span class="d-block text-body mb-20">
            You are now done.
        </span>
        <div>
            <button mat-flat-button class="daxa mr-15" matStepperPrevious>Back</button>
            <button mat-flat-button color="warn" (click)="stepper.reset()">Reset</button>
        </div>
    </mat-step>
</mat-stepper>