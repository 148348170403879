import {ProjectModel} from "../../core/domain/models/project.model";
import {ListProjectResponse} from "../adapters/http/responses/list-project.response";
import {CreateProjectResponse, Project} from "../adapters/http/responses/create-project.response";

export function mapListProjectResponseToModels(response: ListProjectResponse): ProjectModel[] {
  return response.data.items.map((project: Project) => {
    return {
      id: project.id,
      name: project.name,
      desc: project.desc
    };
  });
}

export function mapProjectResponseToModels(response: CreateProjectResponse): ProjectModel {
    return {
      id: response.data.id,
      name: response.data.name,
      desc: response.data.desc
    };
}
