<mat-card class="daxa-card complaints-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Complaints
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <apx-chart
            [series]="chartOptions.series!"
            [chart]="chartOptions.chart!"
            [dataLabels]="chartOptions.dataLabels!"
            [plotOptions]="chartOptions.plotOptions!"
            [yaxis]="chartOptions.yaxis!"
            [grid]="chartOptions.grid!"
            [legend]="chartOptions.legend!"
            [fill]="chartOptions.fill!"
            [title]="chartOptions.title!"
            [colors]="chartOptions.colors!"
            [stroke]="chartOptions.stroke!"
            [tooltip]="chartOptions.tooltip!"
            [xaxis]="chartOptions.xaxis!"
        ></apx-chart>
    </mat-card-content>
</mat-card>