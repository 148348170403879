import {Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {AddressService} from "../../infrastructure/services/address.service";
import {GetListCityRequest} from "../../infrastructure/adapters/http/requests/get-list-city.request";
import {CityModel} from "../../core/domain/models/city.model";

@Injectable({
    providedIn: 'root'
})
export class GetListCityUseCase {
    constructor(
        private addressService: AddressService
    ) {}
    execute(request : GetListCityRequest): Observable<CityModel[]> {
        return this.addressService.getListCity(request)
            .pipe(
                catchError(error => {
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    // Xử lý lỗi
    private handleError(error: any): void {
        console.error('Error fetching user info', error);
    }
}
