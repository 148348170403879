import { createReducer, on } from '@ngrx/store';
import { loadUser, loadUserSuccess, loadUserFailure } from '../actions/user.actions';
import { UserInfoModel } from "../../../core/domain/models/user-info.model";

export interface UserState {
  user: UserInfoModel | null;
  loading: boolean;
  error: any;
}

export const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(loadUser, (state) => ({ ...state, loading: true })),
  on(loadUserSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    user
  })),
  on(loadUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  }))
);
