<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        File Uploader
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Forms
        </li>
        <li class="breadcrumb-item position-relative">
            File Uploader
        </li>
    </ol>
</div>

<!-- File Uploader -->
<div class="row">
    <div class="col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Simple File Uploader
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-simple-fu />
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Multiple File Uploader
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-multiple-fu />
            </mat-card-content>
        </mat-card>
    </div>
</div>