<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Datepicker Open Method
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field>
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker">
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button mat-raised-button (click)="picker.open()">Open</button>
    </mat-card-content>
</mat-card>