<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <i class="material-symbols-outlined">
                dashboard
            </i>
            <span class="title">
                Dashboard
            </span>
            <span class="daxa-badge">
                5
            </span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ul class="sidebar-sub-menu">
        <li class="sidemenu-item">
            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                E-Commerce
            </a>
        </li>
        <li class="sidemenu-item">
            <a routerLink="/crm" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                CRM
            </a>
        </li>
        <li class="sidemenu-item">
            <a routerLink="/project-management" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                Project Management
            </a>
        </li>
        <li class="sidemenu-item">
            <a routerLink="/lms" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                LMS
            </a>
        </li>
        <li class="sidemenu-item">
            <a routerLink="/help-desk" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                Help Desk <span class="daxa-badge">Hot</span>
            </a>
        </li>
    </ul>
</mat-expansion-panel>