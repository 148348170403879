<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        List
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            List
        </li>
    </ol>
</div>

<!-- List -->
<div class="row">
    <div class="col-md-6 col-xxl-4">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Basic List
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-list role="list">
                    <mat-list-item role="listitem">Item 1</mat-list-item>
                    <mat-list-item role="listitem">Item 2</mat-list-item>
                    <mat-list-item role="listitem">Item 3</mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        List with Single Selection
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-list-with-single-selection />
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        List with Sections
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-list-with-sections />
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        List with Selection
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-list-with-selection />
            </mat-card-content>
        </mat-card>
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        List Variants
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <h5 class="mb-0">Single line lists</h5>
                <mat-list>
                    <mat-list-item>
                        <span matListItemTitle>This is the title</span>
                    </mat-list-item>
                    <mat-list-item>Also the title</mat-list-item>
                </mat-list>
                <h5 class="mb-0">Two line lists</h5>
                <mat-list>
                    <mat-list-item>
                        <span matListItemTitle>Title</span>
                        <span matListItemLine>Second line</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span matListItemTitle>Title</span>
                        <span>Second line</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span matListItemTitle>Title</span>
                        Second line
                    </mat-list-item>
                </mat-list>
                <h5 class="mb-0">Three line lists</h5>
                <mat-list>
                    <mat-list-item>
                        <span matListItemTitle>Title</span>
                        <span matListItemLine>Second line</span>
                        <span matListItemLine>Third line</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span matListItemTitle>Title</span>
                        <span matListItemLine>Second line. This line will truncate.</span>
                        <span>Third line</span>
                    </mat-list-item>
                    <mat-list-item>
                        <span matListItemTitle>Title</span>
                        <span matListItemLine>Second line. This line will truncate.</span>
                        Third line
                    </mat-list-item>
                </mat-list>
                <h5 class="mb-0">Three line list with secondary text wrapping</h5>
                <mat-list class="example-list-wrapping">
                    <mat-list-item lines="3">
                        <span matListItemTitle>Title</span>
                        <span>
                            Secondary line that will wrap because the list lines is explicitly set to 3 lines. Text inside of a `matListItemTitle` or `matListItemLine` will never wrap.
                        </span>
                    </mat-list-item>
                    <mat-list-item lines="3">
                        <span matListItemTitle>Title</span>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>
</div>