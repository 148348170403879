<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Kanban Board
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Project Management
        </li>
        <li class="breadcrumb-item position-relative">
            Kanban Board
        </li>
    </ol>
</div>

<!-- Kanban Board -->
<div
    class="row"
    cdkDropListGroup
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="col-sm-6 col-lg-4 col-xxxl-3">
        <mat-card class="daxa-card kanban-board-card mb-25 border-radius bg-gradient border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 text-white">
                        To Do (04)
                    </h5>
                    <span class="d-block text-white">
                        This month add 04 new projects
                    </span>
                </mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Select All
                        </button>
                        <button mat-menu-item>
                            Edit All
                        </button>
                        <button mat-menu-item>
                            Delete All
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
        </mat-card>
        <div
            cdkDropList
            [cdkDropListData]="toDo"
            (cdkDropListDropped)="drop($event)"
        >
            @for (item of toDo; track item) {
                <mat-card class="daxa-card kanban-board-card mb-25 border-radius bg-white border-none d-block" cdkDrag>
                    <mat-card-content>
                        <div class="head d-flex align-items-center justify-content-between">
                            <span class="d-block fw-medium">
                                {{item.title}}
                            </span>
                            <span class="d-block text-daxa">
                                {{item.daysLeft}}
                            </span>
                        </div>
                        <p>
                            {{item.description}}
                        </p>
                        <span class="daxa-badge text-success d-inline-block">
                            {{item.category}}
                        </span>
                        <div class="footer d-flex align-items-center justify-content-between">
                            <span class="date text-body d-block position-relative">
                                <i class="material-symbols-outlined">
                                    schedule
                                </i>
                                {{item.createdDate}}
                            </span>
                            <div class="users-list">
                                <img [src]="item.img1" alt="user-image" *ngIf="item.img1">
                                <img [src]="item.img2" alt="user-image" *ngIf="item.img2">
                                <img [src]="item.img3" alt="user-image" *ngIf="item.img3">
                                <img [src]="item.img4" alt="user-image" *ngIf="item.img4">
                                <img [src]="item.img5" alt="user-image" *ngIf="item.img5">
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            }
        </div>
        <div class="mb-25">
            <button
                mat-button
                class="add-another-task-btn"
                (click)="toggleClass()"
            >
                + Add Another Task
            </button>
        </div>
    </div>
    <div class="col-sm-6 col-lg-4 col-xxxl-3">
        <mat-card class="daxa-card kanban-board-card mb-25 border-radius bg-gradient border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 text-white">
                        In Progress (07)
                    </h5>
                    <span class="d-block text-white">
                        This month progress 07 new projects
                    </span>
                </mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Select All
                        </button>
                        <button mat-menu-item>
                            Edit All
                        </button>
                        <button mat-menu-item>
                            Delete All
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
        </mat-card>
        <div
            cdkDropList
            [cdkDropListData]="inProgress"
            (cdkDropListDropped)="drop($event)"
        >
            @for (item of inProgress; track item) {
                <mat-card class="daxa-card kanban-board-card mb-25 border-radius bg-white border-none d-block" cdkDrag>
                    <mat-card-content>
                        <div class="head d-flex align-items-center justify-content-between">
                            <span class="d-block fw-medium">
                                {{item.title}}
                            </span>
                            <span class="d-block text-warning">
                                {{item.daysLeft}}
                            </span>
                        </div>
                        <p>
                            {{item.description}}
                        </p>
                        <span class="daxa-badge text-success d-inline-block">
                            {{item.category}}
                        </span>
                        <div class="footer d-flex align-items-center justify-content-between">
                            <span class="date text-body d-block position-relative">
                                <i class="material-symbols-outlined">
                                    schedule
                                </i>
                                {{item.createdDate}}
                            </span>
                            <div class="users-list">
                                <img [src]="item.img1" alt="user-image" *ngIf="item.img1">
                                <img [src]="item.img2" alt="user-image" *ngIf="item.img2">
                                <img [src]="item.img3" alt="user-image" *ngIf="item.img3">
                                <img [src]="item.img4" alt="user-image" *ngIf="item.img4">
                                <img [src]="item.img5" alt="user-image" *ngIf="item.img5">
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            }
        </div>
        <div class="mb-25">
            <button
                mat-button
                class="add-another-task-btn"
                (click)="toggleClass()"
            >
                + Add Another Task
            </button>
        </div>
    </div>
    <div class="col-sm-6 col-lg-4 col-xxxl-3">
        <mat-card class="daxa-card kanban-board-card mb-25 border-radius bg-gradient border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 text-white">
                        To Review (06)
                    </h5>
                    <span class="d-block text-white">
                        This month review 06 projects
                    </span>
                </mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Select All
                        </button>
                        <button mat-menu-item>
                            Edit All
                        </button>
                        <button mat-menu-item>
                            Delete All
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
        </mat-card>
        <div
            cdkDropList
            [cdkDropListData]="toReview"
            (cdkDropListDropped)="drop($event)"
        >
            @for (item of toReview; track item) {
                <mat-card class="daxa-card kanban-board-card mb-25 border-radius bg-white border-none d-block" cdkDrag>
                    <mat-card-content>
                        <div class="head d-flex align-items-center justify-content-between">
                            <span class="d-block fw-medium">
                                {{item.title}}
                            </span>
                            <span class="d-block text-danger">
                                {{item.daysLeft}}
                            </span>
                        </div>
                        <p>
                            {{item.description}}
                        </p>
                        <span class="daxa-badge text-success d-inline-block">
                            {{item.category}}
                        </span>
                        <div class="footer d-flex align-items-center justify-content-between">
                            <span class="date text-body d-block position-relative">
                                <i class="material-symbols-outlined">
                                    schedule
                                </i>
                                {{item.createdDate}}
                            </span>
                            <div class="users-list">
                                <img [src]="item.img1" alt="user-image" *ngIf="item.img1">
                                <img [src]="item.img2" alt="user-image" *ngIf="item.img2">
                                <img [src]="item.img3" alt="user-image" *ngIf="item.img3">
                                <img [src]="item.img4" alt="user-image" *ngIf="item.img4">
                                <img [src]="item.img5" alt="user-image" *ngIf="item.img5">
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            }
        </div>
        <div class="mb-25">
            <button
                mat-button
                class="add-another-task-btn"
                (click)="toggleClass()"
            >
                + Add Another Task
            </button>
        </div>
    </div>
    <div class="col-sm-6 col-lg-4 col-xxxl-3">
        <mat-card class="daxa-card kanban-board-card mb-25 border-radius bg-gradient border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 text-white">
                        To Completed (05)
                    </h5>
                    <span class="d-block text-white">
                        This month completed 05 projects
                    </span>
                </mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Select All
                        </button>
                        <button mat-menu-item>
                            Edit All
                        </button>
                        <button mat-menu-item>
                            Delete All
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
        </mat-card>
        <div
            cdkDropList
            [cdkDropListData]="toCompleted"
            (cdkDropListDropped)="drop($event)"
        >
            @for (item of toCompleted; track item) {
                <mat-card class="daxa-card kanban-board-card mb-25 border-radius bg-white border-none d-block" cdkDrag>
                    <mat-card-content>
                        <div class="head d-flex align-items-center justify-content-between">
                            <span class="d-block fw-medium">
                                {{item.title}}
                            </span>
                            <span class="d-block text-success">
                                {{item.daysLeft}}
                            </span>
                        </div>
                        <p>
                            {{item.description}}
                        </p>
                        <span class="daxa-badge text-success d-inline-block">
                            {{item.category}}
                        </span>
                        <div class="footer d-flex align-items-center justify-content-between">
                            <span class="date text-body d-block position-relative">
                                <i class="material-symbols-outlined">
                                    schedule
                                </i>
                                {{item.createdDate}}
                            </span>
                            <div class="users-list">
                                <img [src]="item.img1" alt="user-image" *ngIf="item.img1">
                                <img [src]="item.img2" alt="user-image" *ngIf="item.img2">
                                <img [src]="item.img3" alt="user-image" *ngIf="item.img3">
                                <img [src]="item.img4" alt="user-image" *ngIf="item.img4">
                                <img [src]="item.img5" alt="user-image" *ngIf="item.img5">
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            }
        </div>
        <div class="mb-25">
            <button
                mat-button
                class="add-another-task-btn"
                (click)="toggleClass()"
            >
                + Add Another Task
            </button>
        </div>
    </div>
</div>

<!-- Popup -->
<div
    class="add-another-task-popup"
    [class.active]="classApplied"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="popup-dialog">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Add New Task
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <button
                        mat-button
                        (click)="toggleClass()"
                    >
                        <i class="ri-close-fill"></i>
                    </button>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="row">
                    <div class="col-md-12">
                        <label class="main-label d-block lh-1 text-black">
                            Task Name
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Task name
                            </mat-label>
                            <input matInput placeholder="E.g. Hand Watch">
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <label class="main-label d-block lh-1 text-black">
                            Task Description
                        </label>
                        <mat-form-field class="textarea">
                            <mat-label>
                                Task description
                            </mat-label>
                            <textarea matInput placeholder="E.g. It makes me feel..."></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <label class="main-label d-block lh-1 text-black">
                            Team Members
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Select
                            </mat-label>
                            <mat-select multiple>
                                <mat-option value="option1">
                                    Shawn Kennedy
                                </mat-option>
                                <mat-option value="option2">
                                    Roberto Cruz
                                </mat-option>
                                <mat-option value="option3">
                                    Juli Johnson
                                </mat-option>
                                <mat-option value="option4">
                                    Catalina Engles	
                                </mat-option>
                                <mat-option value="option5">
                                    Louis Nagle
                                </mat-option>
                                <mat-option value="option6">
                                    Michael Marquez
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <label class="main-label d-block lh-1 text-black">
                            Deadline Date
                        </label>
                        <mat-form-field class="date">
                            <mat-label>
                                Choose a date
                            </mat-label>
                            <input matInput [matDatepicker]="dueDate">
                            <mat-datepicker-toggle matIconSuffix [for]="dueDate"></mat-datepicker-toggle>
                            <mat-datepicker #dueDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="btn-box">
                    <button
                        mat-button
                        (click)="toggleClass()"
                    >
                        Cancel
                    </button>
                    <button mat-button>
                        Create
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>