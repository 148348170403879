import { Component } from '@angular/core';
import {RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {SidebarComponent} from "../../components/common/sidebar/sidebar.component";
import {MatAnchor} from "@angular/material/button";
import {MatCard, MatCardContent} from "@angular/material/card";
import {CustomizerSettingsService} from "../../components/customizer-settings/customizer-settings.service";

@Component({
    selector: 'app-account',
    standalone: true,
    imports: [RouterLink, RouterOutlet, SidebarComponent, MatAnchor, MatCard, MatCardContent, RouterLinkActive],
    templateUrl: './account.component.html',
    styleUrl: './account.component.scss'
})
export class AccountComponent {
    isToggled = false;

    constructor(
        public themeService: CustomizerSettingsService
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }
}