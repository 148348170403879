export const series = {
    leads: [
        23,
        20,
        21,
        19
    ],
    dates: [
        "10 Mar 2024",
        "11 Mar 2024",
        "12 Mar 2024",
        "13 Mar 2024"
    ]
};