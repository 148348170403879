<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Ratio
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Ratio
        </li>
    </ol>
</div>

<!-- Ratio -->
<div class="row">
    <div class="col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Ratio (1x1)
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-ratios">
                <div class="ratio ratio-1x1">
                    <div>1x1</div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Ratio (4x3)
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-ratios">
                <div class="ratio ratio-4x3">
                    <div>4x3</div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Ratio (16x9)
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-ratios">
                <div class="ratio ratio-16x9">
                    <div>16x9</div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Ratio (21x9)
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-ratios">
                <div class="ratio ratio-21x9">
                    <div>21x9</div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>