import { NotificationModel } from "../../core/domain/models/notification.model";
import {ListNotificationResponse, Notification} from "../adapters/http/responses/list-notification.response";

// Mapper từ Notification sang NotificationModel
export function mapNotificationToModel(notification: Notification): NotificationModel {
  return {
    id: notification.id,
    title: notification.title,
    body: notification.body,
    channel: notification.channel,
    type: notification.type,
    isRead: notification.isRead,
    createdTime: notification.createdTime
  };
}

// Mapper từ ListNotificationResponse sang NotificationModel[]
export function mapListNotificationResponseToModels(response: ListNotificationResponse): NotificationModel[] {
  return response.data.items.map(notification => mapNotificationToModel(notification));
}
