<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Expansion as Accordion
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Personal data
                    </mat-panel-title>
                    <mat-panel-description>
                        Type your name and age
                        <mat-icon>account_circle</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field class="mb-20">
                    <mat-label>First name</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Age</mat-label>
                    <input matInput type="number" min="1">
                </mat-form-field>
                <mat-action-row>
                    <button mat-button class="daxa" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Destination
                    </mat-panel-title>
                    <mat-panel-description>
                        Type the country name
                        <mat-icon>map</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field>
                    <mat-label>Country</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button class="daxa" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Day of the trip
                    </mat-panel-title>
                    <mat-panel-description>
                        Inform the date you wish to travel
                        <mat-icon>date_range</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field>
                    <mat-label>Date</mat-label>
                    <input matInput [matDatepicker]="picker" (focus)="picker.open()" readonly>
                </mat-form-field>
                <mat-datepicker #picker></mat-datepicker>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button class="daxa" (click)="nextStep()">End</button>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
</mat-card>