import {Component, Inject} from '@angular/core';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import {MatOption, MatSelect} from "@angular/material/select";
import {CountryModel} from "../../../../core/domain/models/country.model";
import {GetListCountryUseCase} from "../../../../application/use-cases/get-list-country.user-case";
import {CommonModule} from "@angular/common";
import {GetListCityUseCase} from "../../../../application/use-cases/get-list-city.user-case";
import {GetListCityRequest} from "../../../../infrastructure/adapters/http/requests/get-list-city.request";
import {CityModel} from "../../../../core/domain/models/city.model";
import {GetListDistrictUseCase} from "../../../../application/use-cases/get-list-district.user-case";
import {GetListDistrictRequest} from "../../../../infrastructure/adapters/http/requests/get-list-district.request";
import {CreateAddressRequest} from "../../../../infrastructure/adapters/http/requests/create-address.request";
import {CreateAddressUseCase} from "../../../../application/use-cases/create-address.use-case";
import {DistrictModel} from "../../../../core/domain/models/district.model";

export interface DialogData {
    id: number;
}

// Dialog Overview Example Dialog
@Component({
    selector: 'address-dialog-component',
    templateUrl: 'address-dialog.component.html',
    styleUrl: './address-dialog.component.scss',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatSelect,
        MatOption,
        ReactiveFormsModule,
    ]
})
export class AddressDialogComponent {
    createAddressRequest: CreateAddressRequest;
    addressForm: FormGroup;
    isLoading: boolean = true;
    error: string | null = null;
    countries: CountryModel[] = [];
    cities: CityModel[] = [];
    districts: DistrictModel[] = [];
    requestCity : GetListCityRequest = {
        countryId: '',
        page: 0,
        size: 100
    }
    requestDistrict : GetListDistrictRequest = {
        countryId: '',
        cityId: '',
        page: 0,
        size: 100
    }
    constructor(
        public dialogRef: MatDialogRef<AddressDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public getListCountryUseCase: GetListCountryUseCase,
        public getListCityUseCase: GetListCityUseCase,
        public getListDistrictUseCase: GetListDistrictUseCase,
        public createAddressUserCase : CreateAddressUseCase,
    ) {

        this.getListCountry();
        this.addressForm = new FormGroup({
            countryId: new FormControl('', Validators.required),
            provinceId: new FormControl('', Validators.required),
            districtId: new FormControl('', Validators.required),
            addressLevel1: new FormControl('', Validators.required),
            addressLevel2: new FormControl(''),
            addressLevel3: new FormControl(''),
            postalCode: new FormControl(''),
        });
        console.log('DialogData', data);
    }


    getListCountry(): void {
        this.getListCountryUseCase.execute()
            .subscribe({
                next: (data) => {
                    console.log(data);
                    this.countries = data;
                    this.cities = [];
                    this.districts = [];
                    if (data.length === 1) {
                        this.addressForm.get('countryId')?.setValue(data[0].id);
                        this.onSelectionChangeCountry();
                    }
                },
                error: (error) => {
                    this.error = 'Failed to load addresses';
                    this.isLoading = false;
                    console.error('Error:', error);
                }
            });
    }

    getListCity() {
        this.getListCityUseCase.execute(this.requestCity)
            .subscribe({
                next: (data) => {
                    console.log(data);
                    this.cities = data;
                    this.districts = [];
                },
                error: (error) => {
                    this.error = 'Failed to load addresses';
                    this.isLoading = false;
                    console.error('Error:', error);
                }
            });
    }

    getListProvince() {
        this.getListDistrictUseCase.execute(this.requestDistrict).subscribe({
            next: (data) => {
                console.log(data);
                this.districts = data;
            },
            error: (error) => {
                this.error = 'Failed to load addresses';
                this.isLoading = false;
                console.error('Error:', error);
            }
        });
    }
    onSelectionChangeCountry(): void {
        this.requestCity.countryId = this.addressForm.get('countryId')?.value
        this.getListCity();
    }
    onSelectionChangeCity(): void {
        this.requestDistrict.countryId = this.addressForm.get('countryId')?.value;
        this.requestDistrict.cityId = this.addressForm.get('provinceId')?.value;
        this.getListProvince();
    }

    onSubmitAddress() {
        if (this.addressForm.valid) {
            this.createAddressRequest = {
                ...this.createAddressRequest,
                ...this.addressForm.value
            };

            this.createAddressUserCase.execute(this.createAddressRequest).subscribe({
                next: (data) => {
                    console.log(data);
                    this.dialogRef.close();
                },
                error: (error) => {
                    this.error = 'Failed to load addresses';
                    this.isLoading = false;
                    console.error('Error:', error);
                    this.dialogRef.close();
                }
            });
            console.log(this.createAddressRequest);
        }
    }
    /*onNoClick(): void {
        this.dialogRef.close();
    }*/

}