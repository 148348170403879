<mat-card
    class="daxa-card conversion-rate-card border-radius border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
>
    <mat-card-content>
        <div class="d-flex justify-content-between">
            <div class="title">
                <h5>
                    Conversion Rate
                </h5>
                <div class="number fw-medium lh-1">
                    12.21%
                </div>
            </div>
            <div class="icon text-center bg-danger rounded-circle text-white position-relative">
                <img src="images/icons/join-white.svg" alt="join-icon">
            </div>
        </div>
        <div class="info d-flex align-items-center justify-content-between">
            <span class="sub-title d-block text-body">
                Conversion rate this month
            </span>
            <span class="daxa-up-down-badge position-relative">
                <i class="material-symbols-outlined">
                    trending_up
                </i>
                1.11%
            </span>
        </div>
    </mat-card-content>
</mat-card>