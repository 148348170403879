<mat-card class="daxa-card stats-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-content>
        <div class="row">
            <div class="col-md-6 col-xxxl-3">

                <!-- New Users -->
                <app-new-users />

            </div>
            <div class="col-md-6 col-xxxl-3">

                <!-- Active Users -->
                <app-active-users />

            </div>
            <div class="col-md-6 col-xxxl-3">

                <!-- Lead Conversation -->
                <app-lead-conversation />

            </div>
            <div class="col-md-6 col-xxxl-3">

                <!-- Revenue Growth -->
                <app-revenue-growth />

            </div>
        </div>
    </mat-card-content>
</mat-card>