import { createAction, props } from '@ngrx/store';
import {UserInfoModel} from "../../../core/domain/models/user-info.model";

export const loadUser = createAction('[User] Load User');
export const loadUserSuccess = createAction(
  '[User] Load User Success',
  props<{ user: UserInfoModel }>()
);
export const loadUserFailure = createAction(
  '[User] Load User Failure',
  props<{ error: any }>()
);
