<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Testimonials
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Pages
        </li>
        <li class="breadcrumb-item position-relative">
            Testimonials
        </li>
    </ol>
</div>

<!-- Testimonials Style - 1 -->
<mat-card
    class="daxa-card testimonial-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Testimonials Style - 1
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item bg-gray mb-25 border-radius">
                    <img src="images/users/user1.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Marcia Baker
                    </h5>
                    <span class="designation d-block text-body">
                        Web Developer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item bg-gray mb-25 border-radius">
                    <img src="images/users/user2.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Carolyn Barnes
                    </h5>
                    <span class="designation d-block text-body">
                        Web Designer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-half-fill"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item bg-gray mb-25 border-radius">
                    <img src="images/users/user4.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Donna Miller
                    </h5>
                    <span class="designation d-block text-body">
                        Web Developer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-line"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- Testimonials Style - 2 -->
<mat-card
    class="daxa-card testimonial-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Testimonials Style - 2
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item text-center bg-gray mb-25 border-radius">
                    <img src="images/users/user5.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Barbara Cross
                    </h5>
                    <span class="designation d-block text-body">
                        Web Developer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item text-center bg-gray mb-25 border-radius">
                    <img src="images/users/user6.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Rebecca Block
                    </h5>
                    <span class="designation d-block text-body">
                        Web Designer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item text-center bg-gray mb-25 border-radius">
                    <img src="images/users/user7.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Ramiro McCarty
                    </h5>
                    <span class="designation d-block text-body">
                        Web Developer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- Testimonials Style - 3 -->
<mat-card
    class="daxa-card testimonial-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Testimonials Style - 3
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item bg-gray mb-25 border-radius">
                    <div class="user d-flex align-items-center">
                        <div class="img">
                            <img src="images/users/user8.jpg" width="100" alt="user-image" class="rounded-circle">
                        </div>
                        <div class="title">
                            <h5>
                                Marcia Baker
                            </h5>
                            <span class="designation d-block text-body">
                                Web Developer
                            </span>
                            <div class="ratings lh-1">
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                            </div>
                        </div>
                    </div>
                    <p class="text-black fst-italic">
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item bg-gray mb-25 border-radius">
                    <div class="user d-flex align-items-center">
                        <div class="img">
                            <img src="images/users/user9.jpg" width="100" alt="user-image" class="rounded-circle">
                        </div>
                        <div class="title">
                            <h5>
                                Carolyn Barnes
                            </h5>
                            <span class="designation d-block text-body">
                                Web Designer
                            </span>
                            <div class="ratings lh-1">
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-half-fill"></i>
                            </div>
                        </div>
                    </div>
                    <p class="text-black fst-italic">
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item bg-gray mb-25 border-radius">
                    <div class="user d-flex align-items-center">
                        <div class="img">
                            <img src="images/users/user10.jpg" width="100" alt="user-image" class="rounded-circle">
                        </div>
                        <div class="title">
                            <h5>
                                Donna Miller
                            </h5>
                            <span class="designation d-block text-body">
                                Web Developer
                            </span>
                            <div class="ratings lh-1">
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-line"></i>
                            </div>
                        </div>
                    </div>
                    <p class="text-black fst-italic">
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- Testimonials Style - 4 -->
<mat-card
    class="daxa-card testimonial-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Testimonials Style - 4
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item bg-gray mb-25 border-radius">
                    <div class="user d-flex align-items-center">
                        <div class="img">
                            <img src="images/users/user11.jpg" width="100" alt="user-image" class="rounded-circle">
                        </div>
                        <div class="title">
                            <h5>
                                Barbara Cross
                            </h5>
                            <span class="designation d-block text-body">
                                Web Developer
                            </span>
                        </div>
                    </div>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                    </div>
                    <p class="fst-italic">
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item bg-gray mb-25 border-radius">
                    <div class="user d-flex align-items-center">
                        <div class="img">
                            <img src="images/users/user12.jpg" width="100" alt="user-image" class="rounded-circle">
                        </div>
                        <div class="title">
                            <h5>
                                Rebecca Block
                            </h5>
                            <span class="designation d-block text-body">
                                Web Designer
                            </span>
                        </div>
                    </div>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-half-fill"></i>
                    </div>
                    <p class="fst-italic">
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item bg-gray mb-25 border-radius">
                    <div class="user d-flex align-items-center">
                        <div class="img">
                            <img src="images/users/user13.jpg" width="100" alt="user-image" class="rounded-circle">
                        </div>
                        <div class="title">
                            <h5>
                                Ramiro McCarty
                            </h5>
                            <span class="designation d-block text-body">
                                Web Developer
                            </span>
                        </div>
                    </div>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-line"></i>
                    </div>
                    <p class="fst-italic">
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- Testimonials Style - 5 -->
<mat-card
    class="daxa-card testimonial-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Testimonials Style - 5
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item p-0 mb-25">
                    <img src="images/users/user14.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Marcia Baker
                    </h5>
                    <span class="designation d-block text-body">
                        Web Developer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item p-0 mb-25">
                    <img src="images/users/user15.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Carolyn Barnes
                    </h5>
                    <span class="designation d-block text-body">
                        Web Designer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-half-fill"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item p-0 mb-25">
                    <img src="images/users/user16.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Donna Miller
                    </h5>
                    <span class="designation d-block text-body">
                        Web Developer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-line"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- Testimonials Style - 6 -->
<mat-card
    class="daxa-card testimonial-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Testimonials Style - 6
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row justify-content-center testimonials-list">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item text-center p-0 mb-25">
                    <img src="images/users/user5.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Barbara Cross
                    </h5>
                    <span class="designation d-block text-body">
                        Web Developer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item text-center p-0 mb-25">
                    <img src="images/users/user6.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Rebecca Block
                    </h5>
                    <span class="designation d-block text-body">
                        Web Designer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="testimonial-item text-center p-0 mb-25">
                    <img src="images/users/user7.jpg" width="100" alt="user-image" class="rounded-circle">
                    <h5>
                        Ramiro McCarty
                    </h5>
                    <span class="designation d-block text-body">
                        Web Developer
                    </span>
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>
                        "We look and sound so good! I am still in shock at how smooth this process was. The professionalism, collaboration and the design they come up is great."
                    </p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>