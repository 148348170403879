<mat-card
    class="daxa-card sidebar-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                My Drive
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form class="search-box position-relative">
            <i class="material-symbols-outlined">
                search
            </i>
            <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search...">
        </form>
        <ul class="sidebar-list pl-0 list-unstyled">
            <li>
                <a routerLink="/file-manager" class="d-flex align-items-center justify-content-between position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <i class="material-symbols-outlined">
                        drive_eta
                    </i>
                    My Drive
                    <span class="d-block text-body">
                        6
                    </span>
                </a>
                <ul class="list-unstyled">
                    <li>
                        <a routerLink="/file-manager/assets" class="d-inline-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            Assets
                        </a>
                    </li>
                    <li>
                        <a routerLink="/file-manager/projects" class="d-inline-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            Projects
                        </a>
                    </li>
                    <li>
                        <a routerLink="/file-manager/personal" class="d-inline-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            Personal
                        </a>
                    </li>
                    <li>
                        <a routerLink="/file-manager/applications" class="d-inline-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            Applications
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <a routerLink="/file-manager/documents" class="d-flex align-items-center justify-content-between position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <i class="material-symbols-outlined">
                        description
                    </i>
                    Documents
                </a>
            </li>
            <li>
                <a routerLink="/file-manager/media" class="d-flex align-items-center justify-content-between position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <i class="material-symbols-outlined">
                        perm_media
                    </i>
                    Media
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        schedule
                    </i>
                    Recents
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        grade
                    </i>
                    Important
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        report_gmailerrorred
                    </i>
                    Spam
                    <span class="d-block text-body">
                        10
                    </span>
                </a>
            </li>
            <li>
                <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative">
                    <i class="material-symbols-outlined">
                        delete
                    </i>
                    Trash
                    <span class="d-block text-body">
                        15
                    </span>
                </a>
            </li>
        </ul>
        <div class="storage-status">
            <h5>
                Storage Status
            </h5>
            <mat-progress-bar mode="determinate" value="32"></mat-progress-bar>
            <span class="d-block text-body">
                % 32 GB used of 128 GB
            </span>
        </div>
    </mat-card-content>
</mat-card>