import {Inject, Injectable} from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import { of } from 'rxjs';
import { loadUser, loadUserSuccess, loadUserFailure } from '../actions/user.actions';
import {UserInfoInterface} from "../../../core/ports/user-info.interface";
import {USER_INFO_TOKEN} from "../../../core/ports/user-info.token";

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(loadUser),
    mergeMap(() => this.userInfoPort.getUserInfo()
      .pipe(
        tap(user => console.log('User Info:', user)),
        map(user => loadUserSuccess({ user })),
        catchError(error => of(loadUserFailure({ error })))
      ))
  ));

  constructor(
    private actions$: Actions,
  @Inject(USER_INFO_TOKEN) private userInfoPort: UserInfoInterface
  ) {}
}
