<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Settings
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Profile
        </li>
        <li class="breadcrumb-item position-relative">
            Settings
        </li>
    </ol>
</div>

<!-- Settings -->
<div class="row">
    <div class="col-xxl-7 col-xxxl-8">
        <mat-card
            class="daxa-card settings-card mb-25 border-radius bg-white border-none d-block"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Profile Settings
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                First Name
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    First name
                                </mat-label>
                                <input matInput value="Micheal">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Last Name
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Last name
                                </mat-label>
                                <input matInput value="Collins">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Email Address
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Email address
                                </mat-label>
                                <input matInput value="micheal@gmail.com">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Phone No
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Phone no
                                </mat-label>
                                <input matInput value="+1 444 555 6699">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Password
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Password
                                </mat-label>
                                <input matInput placeholder="************">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Confirm Password
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Confirm password
                                </mat-label>
                                <input matInput placeholder="************">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Address
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Add address here
                                </mat-label>
                                <input matInput placeholder="E.g. 84 S. Arrowhead Court Branford">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Country
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Select
                                </mat-label>
                                <mat-select>
                                    <mat-option value="option1">
                                        Switzerland
                                    </mat-option>
                                    <mat-option value="option2">
                                        New Zealand
                                    </mat-option>
                                    <mat-option value="option3">
                                        Germany
                                    </mat-option>
                                    <mat-option value="option4">
                                        United States
                                    </mat-option>
                                    <mat-option value="option5">
                                        Japan
                                    </mat-option>
                                    <mat-option value="option6">
                                        Netherlands
                                    </mat-option>
                                    <mat-option value="option7">
                                        Sweden
                                    </mat-option>
                                    <mat-option value="option8">
                                        Canada
                                    </mat-option>
                                    <mat-option value="option9">
                                        United Kingdom
                                    </mat-option>
                                    <mat-option value="option10">
                                        Australia
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Add Your Bio
                            </label>
                            <mat-form-field class="textarea">
                                <mat-label>
                                    What's on your mind, Micheal?
                                </mat-label>
                                <textarea matInput placeholder="E.g. It makes me feel..."></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Who will be able to see your profile?
                            </label>
                            <mat-radio-group>
                                <mat-radio-button value="1">
                                    Only me
                                </mat-radio-button>
                                <mat-radio-button value="2">
                                    My followers
                                </mat-radio-button>
                                <mat-radio-button value="3">
                                    Everyone
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Select Your Skills
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Select
                                </mat-label>
                                <mat-select>
                                    <mat-option value="option1">
                                        Leadership
                                    </mat-option>
                                    <mat-option value="option2">
                                        Project Management
                                    </mat-option>
                                    <mat-option value="option3">
                                        Data Analysis
                                    </mat-option>
                                    <mat-option value="option4">
                                        Teamwork
                                    </mat-option>
                                    <mat-option value="option5">
                                        Web Development
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Your Profession
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Your profession
                                </mat-label>
                                <input matInput value="Designer">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Company Name
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Company name
                                </mat-label>
                                <input matInput value="Daxa">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Company Website
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Company website
                                </mat-label>
                                <input matInput value="http://website.com">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Facebook
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Facebook
                                </mat-label>
                                <input matInput value="facebook@example.com">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Twitter
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Twitter
                                </mat-label>
                                <input matInput value="twitter@example.com">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Linkedin
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    Linkedin
                                </mat-label>
                                <input matInput value="linkedin@example.com">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                YouTube
                            </label>
                            <mat-form-field>
                                <mat-label>
                                    YouTube
                                </mat-label>
                                <input matInput value="youtube@example.com">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Upload Your Profile Picture
                            </label>
                            <file-upload class="file-uploader" [multiple]="multiple"></file-upload>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="mb-25">
                            <label class="main-label d-block lh-1 text-black">
                                Upload Cover Photo
                            </label>
                            <file-upload class="file-uploader" [multiple]="multiple"></file-upload>
                        </div>
                    </div>
                </div>
                <div class="btn-box mt-0">
                    <button mat-button>
                        Save Information
                    </button>
                    <button mat-button>
                        Cancel
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-5 col-xxxl-4">
        <mat-card
            class="daxa-card settings-card mb-25 border-radius bg-white border-none d-block"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Privacy Policy
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="mb-25">
                    <mat-checkbox>
                        Allow users to show your email
                    </mat-checkbox>
                    <mat-checkbox>
                        Allow users to show your experiences
                    </mat-checkbox>
                    <mat-checkbox>
                        Allow users to show your followers
                    </mat-checkbox>
                </div>
                <div class="mb-25">
                    <label class="main-label d-block lh-1 text-black">
                        Who can tag you?
                    </label>
                    <mat-radio-group>
                        <mat-radio-button value="1">
                            Everyone
                        </mat-radio-button>
                        <mat-radio-button value="2">
                            Group Member
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div>
                    <label class="main-label d-block lh-1 text-black">
                        Account Deletion Or Transfer
                    </label>
                    <p class="minus">
                        Transfer this account to another person or to a company repositories.
                    </p>
                </div>
                <div class="btn-box">
                    <button mat-button>
                        Delete Account
                    </button>
                    <button mat-button>
                        Transfer Account
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>