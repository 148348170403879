<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Sellers List
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            E-Commerce
        </li>
        <li class="breadcrumb-item position-relative">
            Sellers List
        </li>
    </ol>
</div>

<!-- Sellers List -->
<mat-card class="daxa-card sellers-list-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <a mat-button class="add-new-btn" routerLink="/ecommerce-page/create-seller">
                + Add New Seller
            </a>
        </mat-card-title>
        <mat-card-subtitle>
            <form class="search-box position-relative">
                <i class="material-symbols-outlined">
                    search
                </i>
                <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search seller...">
            </form>
        </mat-card-subtitle>
    </mat-card-header>
</mat-card>
<div
    class="row"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="col-lg-4 col-xxxl-3 col-sm-6" *ngFor="let content of sellerCard;">
        <mat-card class="daxa-card sellers-list-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            View
                        </button>
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="icon position-relative text-center rounded-circle">
                        <img [src]="content.sellerImg" alt="seller-image">
                    </div>
                    <h5 class="mb-0">
                        <a routerLink="/ecommerce-page/seller-details">
                            {{content.sellerName}}
                        </a>
                    </h5>
                </div>
                <ul class="pl-0 mb-0 list-unstyled">
                    <li>
                        <span class="text-body">
                            Last Sale Date:
                        </span> 
                        {{content.lastSaleDate}}
                    </li>
                    <li>
                        <span class="text-body">
                            Item Stock:
                        </span> 
                        {{content.itemStock}}
                    </li>
                    <li>
                        <span class="text-body">
                            Wallet Balance:
                        </span> 
                        {{content.walletBalance}}
                    </li>
                    <li>
                        <span class="text-body">
                            Revenue:
                        </span> 
                        {{content.revenue}}
                    </li>
                    <li>
                        <span class="text-body">
                            Store:
                        </span> 
                        {{content.store}}
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- Pagination -->
<div class="pagination-card mb-25 d-lg-flex align-items-center justify-content-between">
    <p class="mb-0">
        Showing 1 to 12 of 80 entries
    </p>
    <ol class="pagination list-unstyled mt-0 mb-0 pl-0">
        <li class="pagination-item d-inline-block">
            <a mat-button routerLink="/ecommerce-page/sellers">
                <i class="material-symbols-outlined">
                    west
                </i>
            </a>
        </li>
        <li class="pagination-item d-inline-block">
            <a mat-button routerLink="/ecommerce-page/sellers" class="active">
                1
            </a>
        </li>
        <li class="pagination-item d-inline-block">
            <a mat-button routerLink="/ecommerce-page/sellers">
                2
            </a>
        </li>
        <li class="pagination-item d-inline-block">
            <a mat-button routerLink="/ecommerce-page/sellers">
                3
            </a>
        </li>
        <li class="pagination-item d-inline-block">
            <a mat-button routerLink="/ecommerce-page/sellers">
                <i class="material-symbols-outlined">
                    east
                </i>
            </a>
        </li>
    </ol>
</div>