import {Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {AddressService} from "../../infrastructure/services/address.service";
import {GetListDistrictRequest} from "../../infrastructure/adapters/http/requests/get-list-district.request";
import {DistrictModel} from "../../core/domain/models/district.model";

@Injectable({
    providedIn: 'root'
})
export class GetListDistrictUseCase {
    constructor(
        private addressService: AddressService
    ) {}
    execute(request: GetListDistrictRequest): Observable<DistrictModel[]> {
        return this.addressService.getListDistrict(request)
            .pipe(
                catchError(error => {
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    // Xử lý lỗi
    private handleError(error: any): void {
        console.error('Error fetching user info', error);
    }
}
