<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Basic Table
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Tables
        </li>
        <li class="breadcrumb-item position-relative">
            Basic Table
        </li>
    </ol>
</div>

<!-- Basic Table -->
<div class="row">
    <div class="col-lg-12">

        <!-- Users -->
        <app-users />

    </div>
    <div class="col-lg-6">

        <!-- Top Sellers -->
        <app-top-sellers />

    </div>
    <div class="col-lg-6">

        <!-- Country Stats -->
        <app-country-stats />

    </div>
    <div class="col-lg-6">

        <!-- Team Members -->
        <app-team-members />

    </div>
</div>