import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private oauthService: OAuthService, private router: Router) {}

    canActivate(): boolean {
        if (!this.oauthService.hasValidAccessToken()) {
            this.oauthService.initLoginFlow(); // Chuyển sang trang login
            return false;
        }
        return true;
    }
}
