<div class="confirm-email-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div
                class="daxa-form ml-auto mr-auto border-radius bg-white"
                [class.card-borderd-theme]="themeService.isCardBorder()"
                [class.component-dark-theme]="themeService.isDark()"
            >
                <div class="title text-center">
                    <h3>
                        Welcome To Daxa
                    </h3>
                    <p class="ml-auto mr-auto">
                        Your mail is verified! Your account is now safe from unwanted activities.
                    </p>
                </div>
                <div class="admin text-center">
                    <div class="logo position-relative text-success rounded-circle">
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </div>
                    <span class="d-block fw-medium">
                        Your Email Verified <span class="text-success">Successfully!</span>
                    </span>
                </div>
                <a routerLink="/" mat-button class="btn">
                    Back To Home
                </a>
            </div>
        </div>
    </div>
</div>