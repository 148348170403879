<mat-card class="daxa-card order-summary-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Order Summary
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="info d-flex align-items-center justify-content-between">
            <span class="d-block">
                Completed 60%
            </span>
            <span class="d-block">
                New Order 30%
            </span>
            <span class="d-block">
                Pending 10%
            </span>
        </div>
        <apx-chart
            [series]="chartOptions.series!"
            [chart]="chartOptions.chart!"
            [legend]="chartOptions.legend!"
            [colors]="chartOptions.colors!"
            [tooltip]="chartOptions.tooltip!"
            [labels]="chartOptions.labels!"
            [dataLabels]="chartOptions.dataLabels!"
            [responsive]="chartOptions.responsive!"
        ></apx-chart>
    </mat-card-content>
</mat-card>