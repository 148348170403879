<div
    class="stat-item position-relative"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <h5>
        Lead Conversation
    </h5>
    <div class="number lh-1 fw-medium">
        25.99%
    </div>
    <span class="daxa-up-down-badge down position-relative">
        <i class="material-symbols-outlined">
            trending_down
        </i>
        1.25%
    </span>
    <div class="chart">
        <apx-chart
            [series]="chartOptions.series!"
            [chart]="chartOptions.chart!"
            [xaxis]="chartOptions.xaxis!"
            [stroke]="chartOptions.stroke!"
            [tooltip]="chartOptions.tooltip!"
            [dataLabels]="chartOptions.dataLabels!"
            [colors]="chartOptions.colors!"
            [yaxis]="chartOptions.yaxis!"
            [grid]="chartOptions.grid!"
            [labels]="chartOptions.labels!"
            [legend]="chartOptions.legend!"
        ></apx-chart>
        <div class="title d-block text-body">
            Last Week
        </div>
    </div>
</div>