<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Order Tracking
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            E-Commerce
        </li>
        <li class="breadcrumb-item position-relative">
            Orders
        </li>
        <li class="breadcrumb-item position-relative">
            Order Tracking
        </li>
    </ol>
</div>

<!-- Order Tracking -->
<div class="row">
    <div class="col-lg-8">
        <mat-card
            class="daxa-card order-tracking-card mb-25 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Order ID #2435679
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <h5 class="mb-0">
                        Tracking ID #1004216609
                    </h5>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <span class="text d-block text-body">
                    IBAN: EST6049283742809
                </span>
                <span class="text d-block text-body">
                    Date: 26th Mar, 2024
                </span>
                <div class="numbers position-relative d-md-flex justify-content-between">
                    <div class="box">
                        <div class="rounded-circle text-center fw-medium">
                            01
                        </div>
                        <span class="fw-medium d-block">
                            Order Placed
                        </span>
                        <span class="d-block text-body">
                            22 Mar, 2024 - 11:35AM
                        </span>
                    </div>
                    <div class="box">
                        <div class="rounded-circle text-center fw-medium">
                            02
                        </div>
                        <span class="fw-medium d-block">
                            Packed
                        </span>
                        <span class="d-block text-body">
                            23 Mar, 2024 - 10:35AM
                        </span>
                    </div>
                    <div class="box">
                        <div class="rounded-circle text-center fw-medium">
                            03
                        </div>
                        <span class="fw-medium d-block">
                            Shipped
                        </span>
                        <span class="d-block text-body">
                            24 Mar, 2024 - 3:45PM
                        </span>
                    </div>
                    <div class="box active">
                        <div class="rounded-circle text-center fw-medium">
                            04
                        </div>
                        <span class="fw-medium d-block">
                            Delivered
                        </span>
                        <span class="d-block text-body">
                            25 Mar, 2024 - 2:21PM
                        </span>
                    </div>
                </div>
                <span class="title d-block fw-medium">
                    View Details:
                </span>
                <span class="text d-block">
                    <span class="text-body">22 Mar, 2024 - 11:35AM:</span> Your order is now on warehouse. It’s being ready for shipped.
                </span>
                <span class="text d-block">
                    <span class="text-body">23 Mar, 2024 - 10:35AM:</span> Your order is on the way for deliver.
                </span>
                <span class="text d-block">
                    <span class="text-body">24 Mar, 2024 - 3:45PM:</span> Your order shipped.
                </span>
                <span class="text d-block">
                    <span class="text-body">25 Mar, 2024 - 2:21PM:</span> Your order delivered.
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4">
        <mat-card
            class="daxa-card order-summary-card mb-25 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Order Summary
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="order-summary-table">
                    <div class="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col" class="fw-medium text-black">
                                        Description
                                    </th>
                                    <th scope="col" class="fw-medium text-black">
                                        Price
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-body">
                                        Grand Total :
                                    </td>
                                    <td class="text-body">
                                        $1,105.00
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-body">
                                        Discount :
                                    </td>
                                    <td class="text-body">
                                        -$105.00
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-body">
                                        Tax :
                                    </td>
                                    <td class="text-body">
                                        $75.00
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-black">
                                        Subtotal :
                                    </td>
                                    <td class="text-body">
                                        $1,000.00
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-body">
                                        Shipping Charge :
                                    </td>
                                    <td class="text-body">
                                        $80.00
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-body">
                                        Coupon Charge :
                                    </td>
                                    <td class="text-body">
                                        $20.00
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-black">
                                        Total :
                                    </td>
                                    <td class="text-body">
                                        $900.00
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>