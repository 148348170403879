import {bootstrapApplication} from '@angular/platform-browser';
import {appConfig} from './app/app.config';
import {AppComponent} from './app/app.component';
import {isSupported} from "@angular/fire/messaging";

if ('serviceWorker' in navigator || isSupported()) {
  navigator.serviceWorker.register('firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
      // Chỉ gọi hàm requestPermission và subscribe vào PushManager sau khi Service Worker đã đăng ký thành công.
    }).catch((error) => {
    console.error('Service Worker registration failed:', error);
  });
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
