<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Dialog Animations
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <button mat-flat-button class="daxa" (click)="openDialog('0ms', '0ms')">
            Open dialog without animation
        </button>
        <button mat-flat-button color="primary" (click)="openDialog('3000ms', '1500ms')">
            Open dialog slowly
        </button>
    </mat-card-content>
</mat-card>