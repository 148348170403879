<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Display Value Autocomplete
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <mat-form-field>
                <mat-label>Assignee</mat-label>
                <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    @for (option of filteredOptions | async; track option) {
                        <mat-option [value]="option">{{option.name}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </mat-card-content>
</mat-card>