<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Gradient RadialBar Chart
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <apx-chart
            [series]="chartOptions.series!"
            [chart]="chartOptions.chart!"
            [plotOptions]="chartOptions.plotOptions!"
            [labels]="chartOptions.labels!"
            [stroke]="chartOptions.stroke!"
            [fill]="chartOptions.fill!"
        ></apx-chart>
    </mat-card-content>
</mat-card>