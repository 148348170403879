<div class="courses-table">
    <div class="table-responsive">
        <table mat-table [dataSource]="dataSource">

            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>
                    ID
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.id}}
                </td>
            </ng-container>

            <!-- Course Name Column -->
            <ng-container matColumnDef="courseName">
                <th mat-header-cell *matHeaderCellDef>
                    Course Name
                </th>
                <td mat-cell *matCellDef="let element" class="fw-medium">
                    <a routerLink="/lms-page/course-details">
                        {{element.courseName}}
                    </a>
                </td>
            </ng-container>

            <!-- Category Column -->
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef>
                    Category
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.category}}
                </td>
            </ng-container>

            <!-- Instructor Column -->
            <ng-container matColumnDef="instructor">
                <th mat-header-cell *matHeaderCellDef>
                    Instructor
                </th>
                <td mat-cell *matCellDef="let element" class="text-black">
                    {{element.instructor}}
                </td>
            </ng-container>

            <!-- Enrolled Students Column -->
            <ng-container matColumnDef="enrolledStudents">
                <th mat-header-cell *matHeaderCellDef>
                    Enrolled Students
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.enrolledStudents}}
                </td>
            </ng-container>

            <!-- Start Date Column -->
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>
                    Start Date
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.startDate}}
                </td>
            </ng-container>

            <!-- End Date Column -->
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>
                    End Date
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.endDate}}
                </td>
            </ng-container>

            <!-- Price Column -->
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>
                    Price
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.price}}
                </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                    Status
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="daxa-badge active d-inline-block" *ngIf="element.status.active">
                        {{element.status.active}}
                    </span>
                    <span class="daxa-badge deactive d-inline-block" *ngIf="element.status.deactive">
                        {{element.status.deactive}}
                    </span>
                </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                    Action
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="action-info">
                        <a mat-button routerLink="/lms-page/course-details" matTooltip="View Course" matTooltipPosition="above">
                            <i class="material-symbols-outlined">
                                {{element.action.view}}
                            </i>
                        </a>
                        <a mat-button routerLink="/lms-page/edit-course" matTooltip="Edit Course" matTooltipPosition="above">
                            <i class="material-symbols-outlined">
                                {{element.action.edit}}
                            </i>
                        </a>
                        <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above">
                            <i class="material-symbols-outlined">
                                {{element.action.delete}}
                            </i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>