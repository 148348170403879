<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Tabs
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Tabs
        </li>
    </ol>
</div>

<!-- Basic Tabs -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Basic Tabs
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="First"> Content 1 </mat-tab>
            <mat-tab label="Second"> Content 2 </mat-tab>
            <mat-tab label="Third"> Content 3 </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<!-- Tab Group with Aligned Labels -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Tab Group with Aligned Labels
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="First">Content 1</mat-tab>
            <mat-tab label="Second">Content 2</mat-tab>
            <mat-tab label="Third">Content 3</mat-tab>
        </mat-tab-group>

        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
            <mat-tab label="First">Content 1</mat-tab>
            <mat-tab label="Second">Content 2</mat-tab>
            <mat-tab label="Third">Content 3</mat-tab>
        </mat-tab-group>

        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="end">
            <mat-tab label="First">Content 1</mat-tab>
            <mat-tab label="Second">Content 2</mat-tab>
            <mat-tab label="Third">Content 3</mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<!-- Tab Group Animations -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Tab Group Animations
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <h5>No animation</h5>
        <mat-tab-group animationDuration="0ms">
            <mat-tab label="First">Content 1</mat-tab>
            <mat-tab label="Second">Content 2</mat-tab>
            <mat-tab label="Third">Content 3</mat-tab>
        </mat-tab-group>
        <br>

        <h5>Very slow animation</h5>
        <mat-tab-group animationDuration="2000ms">
            <mat-tab label="First">Content 1</mat-tab>
            <mat-tab label="Second">Content 2</mat-tab>
            <mat-tab label="Third">Content 3</mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<!-- Tab Group with Asynchronously Loading Tab Contents -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Tab Group with Asynchronously Loading Tab Contents
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-tgw-asynchronously-loading-tc />
    </mat-card-content>
</mat-card>

<!-- Using Tabs with a Custom Label Template -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Using Tabs with a Custom Label Template
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-utwa-custom-label-template />
    </mat-card-content>
</mat-card>

<!-- Tab Group with Dynamic Height based on Tab Contents -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Tab Group with Dynamic Height based on Tab Contents
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group dynamicHeight>
            <mat-tab label="Short tab">
                <div class="example-small-box mat-elevation-z4">
                    Small content
                </div>
            </mat-tab>
            <mat-tab label="Long tab">
                <div class="example-large-box mat-elevation-z4">
                    Large content
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<!-- Tab Group with the Headers on the Bottom -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Tab Group with the Headers on the Bottom
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group headerPosition="below">
            <mat-tab label="First"> Content 1 </mat-tab>
            <mat-tab label="Second"> Content 2 </mat-tab>
            <mat-tab label="Third"> Content 3 </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<!-- Tab Group with Ink Bar Fit to Content -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Tab Group with Ink Bar Fit to Content
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group fitInkBarToContent>
            <mat-tab label="First">Content 1</mat-tab>
            <mat-tab label="Second">Content 2</mat-tab>
            <mat-tab label="Third">Content 3</mat-tab>
            <mat-tab label="Fourth" disabled>Content 4</mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<!-- Tab Group with Inverted Tabs -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Tab Group with Inverted Tabs
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group headerPosition="below">
            <mat-tab label="First">Content 1</mat-tab>
            <mat-tab label="Second">Content 2</mat-tab>
            <mat-tab label="Third">Content 3</mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<!-- Tab Group where the Tab Content is Loaded Lazily (when activated) -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Tab Group where the Tab Content is Loaded Lazily (when activated)
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="First">
                <ng-template matTabContent>
                    Content 1 - Loaded: {{getTimeLoaded(1) | date:'medium'}}
                </ng-template>
            </mat-tab>
            <mat-tab label="Second">
                <ng-template matTabContent>
                    Content 2 - Loaded: {{getTimeLoaded(2) | date:'medium'}}
                </ng-template>
            </mat-tab>
            <mat-tab label="Third">
                <ng-template matTabContent>
                    Content 3 - Loaded: {{getTimeLoaded(3) | date:'medium'}}
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<!-- Tab Group with Paginated Tabs -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Tab Group with Paginated Tabs
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group>
            @for (tab of lotsOfTabs; track tab) {
                <mat-tab [label]="tab">Content</mat-tab>
            }
        </mat-tab-group>
    </mat-card-content>
</mat-card>