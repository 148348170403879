<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Create Seller
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            E-Commerce
        </li>
        <li class="breadcrumb-item position-relative">
            Sellers
        </li>
        <li class="breadcrumb-item position-relative">
            Create Seller
        </li>
    </ol>
</div>

<!-- Create Seller -->
<mat-card
    class="daxa-card create-seller-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-content>
        <form>
            <div class="row">
                <div class="col-md-4 col-sm-6">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Seller Name
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter seller name
                            </mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Store Name
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter store name
                            </mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Phone Number
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter phone number
                            </mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Email Address
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter email address
                            </mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Date of Birth
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter date of birth
                            </mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            City
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Select city
                            </mat-label>
                            <mat-select>
                                <mat-option value="option1">
                                    New York
                                </mat-option>
                                <mat-option value="option2">
                                    Tokyo
                                </mat-option>
                                <mat-option value="option3">
                                    London
                                </mat-option>
                                <mat-option value="option4">
                                    Amsterdam
                                </mat-option>
                                <mat-option value="option5">
                                    Paris
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Country
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Select country
                            </mat-label>
                            <mat-select>
                                <mat-option value="option1">
                                    Switzerland
                                </mat-option>
                                <mat-option value="option2">
                                    New Zealand
                                </mat-option>
                                <mat-option value="option3">
                                    Germany
                                </mat-option>
                                <mat-option value="option4">
                                    United States
                                </mat-option>
                                <mat-option value="option5">
                                    Japan
                                </mat-option>
                                <mat-option value="option6">
                                    Netherlands
                                </mat-option>
                                <mat-option value="option7">
                                    Sweden
                                </mat-option>
                                <mat-option value="option8">
                                    Canada
                                </mat-option>
                                <mat-option value="option9">
                                    United Kingdom
                                </mat-option>
                                <mat-option value="option10">
                                    Australia
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            ZIP / Postcode
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter zip / postcode
                            </mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Wallet Balance
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter wallet balance
                            </mat-label>
                            <input matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Description
                        </label>
                        <div class="NgxEditor__Wrapper">
                            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                            <ngx-editor [editor]="editor" [placeholder]="'Type here...'"></ngx-editor>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12">
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Upload Image
                        </label>
                        <file-upload class="file-uploader" [multiple]="multiple"></file-upload>
                    </div>
                </div>
            </div>
            <div class="btn-box">
                <button mat-button>
                    Create Seller
                </button>
                <button mat-button>
                    Cancel
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>