<mat-card
    class="daxa-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Basic Accordion
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <cdk-accordion class="basic-accordion">
            <cdk-accordion-item
                *ngFor="let item of items; let index = index;"
                #accordionItem="cdkAccordionItem"
                role="button"
                tabindex="0"
                class="accordion-item"
                [attr.id]="'accordion-header-' + index"
                [attr.aria-expanded]="accordionItem.expanded"
                [attr.aria-controls]="'accordion-body-' + index"
            >
                <div
                    class="accordion-item-header {{accordionItem.expanded ? 'close' : 'open'}}"
                    (click)="accordionItem.toggle()"
                >
                    {{ item.title }}
                </div>
                <div
                    role="region"
                    class="accordion-item-body"
                    [style.display]="accordionItem.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-' + index"
                    [attr.aria-labelledby]="'accordion-header-' + index"
                >
                    <p>{{ item.desc }}</p>
                </div>
            </cdk-accordion-item>
        </cdk-accordion>
    </mat-card-content>
</mat-card>