<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Profile
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Social
        </li>
        <li class="breadcrumb-item position-relative">
            Profile
        </li>
    </ol>
</div>

<!-- Profile -->
<div class="row">
    <div class="col-xxxl-9 col-md-12">
        <mat-card
            class="daxa-card profile-card mb-25 p-0 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-content>
                <div class="cover-image position-relative border-top-radius">
                    <img src="images/profile-cover.jpg" class="border-top-radius" alt="cover-image">
                    <button type="button" mat-button class="edit-btn" [matMenuTriggerFor]="editCoverPhotoMenu">
                        Edit Cover Photo
                    </button>
                    <mat-menu #editCoverPhotoMenu="matMenu" class="card-header-menu" xPosition="after">
                        <button mat-menu-item>
                            Upload Photo
                        </button>
                        <button mat-menu-item>
                            Reposition
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </div>
                <div class="profile-content">
                    <div class="d-md-flex align-items-end justify-content-between">
                        <div class="d-md-flex align-items-end">
                            <div class="image position-relative">
                                <img src="images/profile.jpg" class="rounded-circle" alt="profile-image">
                                <button type="button" mat-button class="edit-btn" [matMenuTriggerFor]="editProfilePhotoMenu">
                                    <i class="material-symbols-outlined">
                                        photo_camera
                                    </i>
                                </button>
                                <mat-menu #editProfilePhotoMenu="matMenu" class="card-header-menu" xPosition="after">
                                    <button mat-menu-item>
                                        See Photo
                                    </button>
                                    <button mat-menu-item>
                                        Upload Photo
                                    </button>
                                    <button mat-menu-item>
                                        Remove
                                    </button>
                                </mat-menu>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Micheal Collins
                                </span>
                                <span class="d-block text-body">
                                    micheal&#64;gmail.com
                                </span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <button mat-button>
                                Message
                            </button>
                            <button mat-button>
                                Follow
                            </button>
                        </div>
                    </div>
                    <ul class="nav-links text-center pl-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <a routerLink="/social" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                Timeline
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a routerLink="/social/about" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                About
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a routerLink="/social/activity" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                Activity
                            </a>
                        </li>
                    </ul>
                </div>
            </mat-card-content>
        </mat-card>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <mat-card
                    class="daxa-card friends-card mb-25 border-radius bg-white border-none d-block"
                    [class.component-dark-theme]="themeService.isDark()"
                    [class.rtl-enabled]="themeService.isRTLEnabled()"
                >
                    <mat-card-header>
                        <mat-card-title>
                            <h5 class="mb-0">
                                Friends
                            </h5>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <form class="search-box position-relative">
                            <i class="material-symbols-outlined">
                                search
                            </i>
                            <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search...">
                        </form>
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user15.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Marcia Baker
                                </span>
                                <span class="d-block text-body">
                                    baker&#64;gmail.com
                                </span>
                            </div>
                        </div>
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user16.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status off"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Carolyn Barnes
                                </span>
                                <span class="d-block text-body">
                                    barnes&#64;gmail.com
                                </span>
                            </div>
                        </div>
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user17.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Donna Miller
                                </span>
                                <span class="d-block text-body">
                                    miller&#64;gmail.com
                                </span>
                            </div>
                        </div>
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user14.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Barbara Cross
                                </span>
                                <span class="d-block text-body">
                                    cross&#64;gmail.com
                                </span>
                            </div>
                        </div>
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user13.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status off"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Rebecca Block
                                </span>
                                <span class="d-block text-body">
                                    block&#64;gmail.com
                                </span>
                            </div>
                        </div>
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user12.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status off"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Ramiro McCarty
                                </span>
                                <span class="d-block text-body">
                                    mccarty&#64;gmail.com
                                </span>
                            </div>
                        </div>
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user11.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Robert Fairweather
                                </span>
                                <span class="d-block text-body">
                                    fairweather&#64;gmail.com
                                </span>
                            </div>
                        </div>
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user10.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status off"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Marcelino Haddock
                                </span>
                                <span class="d-block text-body">
                                    haddock&#64;gmail.com
                                </span>
                            </div>
                        </div>
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user9.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status off"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Thomas Wilson
                                </span>
                                <span class="d-block text-body">
                                    wilson&#64;gmail.com
                                </span>
                            </div>
                        </div>
                        <div class="user d-flex align-items-center">
                            <div class="img position-relative">
                                <img src="images/users/user8.jpg" class="rounded-circle" alt="user-image">
                                <span class="active-status off"></span>
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Nathaniel Hulsey
                                </span>
                                <span class="d-block text-body">
                                    hulsey&#64;gmail.com
                                </span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-lg-8 col-md-12">
                <router-outlet />
            </div>
        </div>
    </div>
    <div class="col-xxxl-3 col-md-12">
        <div class="row">
            <div class="col-lg-6 col-xxxl-12">
                <mat-card
                    class="daxa-card profile-intro-card mb-25 border-radius bg-white border-none d-block"
                    [class.rtl-enabled]="themeService.isRTLEnabled()"
                >
                    <mat-card-header>
                        <mat-card-title>
                            <h5 class="mb-0">
                                Profile Intro
                            </h5>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="user d-flex align-items-center">
                            <div class="image">
                                <img src="images/profile.jpg" class="rounded-circle" alt="profile-image">
                            </div>
                            <div class="title">
                                <span class="d-block fw-medium">
                                    Micheal Collins
                                </span>
                                <span class="d-block text-body">
                                    micheal&#64;gmail.com
                                </span>
                            </div>
                        </div>
                        <span class="sub-title d-block fw-medium">
                            About Me
                        </span>
                        <p>
                            Molestie tincidunt ut consequat a urna tortor. Vitae velit ac nisl velit mauris placerat nisi placerat. Pellentesque viverra lorem malesuada nunc tristique sapien. Imperdiet sit hendrerit tincidunt bibendum donec adipiscing.
                        </p>
                        <h5>
                            Social Profile
                        </h5>
                        <div class="socials-link">
                            <a href="https://www.facebook.com/" target="_blank" class="facebook d-inline-block text-center position-relative rounded-circle">
                                <i class="ri-facebook-fill"></i>
                            </a>
                            <a href="https://twitter.com/" target="_blank" class="twitter d-inline-block text-center position-relative rounded-circle">
                                <i class="ri-twitter-fill"></i>
                            </a>
                            <a href="https://www.linkedin.com/" target="_blank" class="linkedin d-inline-block text-center position-relative rounded-circle">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                            <a href="mailto:micheal@gmail.com" class="mail d-inline-block text-center position-relative rounded-circle">
                                <i class="ri-mail-fill"></i>
                            </a>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-lg-6 col-xxxl-12">
                <mat-card
                    class="daxa-card followers-card mb-25 border-radius bg-white border-none d-block"
                    [class.rtl-enabled]="themeService.isRTLEnabled()"
                >
                    <mat-card-header>
                        <mat-card-title>
                            <h5 class="mb-0">
                                Followers
                            </h5>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="follower d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="image">
                                    <img src="images/users/user1.jpg" class="rounded-circle" alt="user-image">
                                </div>
                                <div class="title">
                                    <span class="d-block fw-medium">
                                        Salvatore Dewall
                                    </span>
                                    <span class="d-block text-body">
                                        dewall&#64;gmail.com
                                    </span>
                                </div>
                            </div>
                            <button
                                mat-button
                                class="text-body p-0 h-auto"
                            >
                                Add Friend
                            </button>
                        </div>
                        <div class="follower d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="image">
                                    <img src="images/users/user3.jpg" class="rounded-circle" alt="user-image">
                                </div>
                                <div class="title">
                                    <span class="d-block fw-medium">
                                        Alina Smith
                                    </span>
                                    <span class="d-block text-body">
                                        smith&#64;gmail.com
                                    </span>
                                </div>
                            </div>
                            <button
                                mat-button
                                class="text-body p-0 h-auto"
                            >
                                Add Friend
                            </button>
                        </div>
                        <div class="follower d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="image">
                                    <img src="images/users/user5.jpg" class="rounded-circle" alt="user-image">
                                </div>
                                <div class="title">
                                    <span class="d-block fw-medium">
                                        Russell Colon
                                    </span>
                                    <span class="d-block text-body">
                                        colon&#64;gmail.com
                                    </span>
                                </div>
                            </div>
                            <button
                                mat-button
                                class="text-body p-0 h-auto"
                            >
                                Add Friend
                            </button>
                        </div>
                        <div class="follower d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="image">
                                    <img src="images/users/user6.jpg" class="rounded-circle" alt="user-image">
                                </div>
                                <div class="title">
                                    <span class="d-block fw-medium">
                                        Leslie Shadle
                                    </span>
                                    <span class="d-block text-body">
                                        shadle&#64;gmail.com
                                    </span>
                                </div>
                            </div>
                            <button
                                mat-button
                                class="text-body p-0 h-auto"
                            >
                                Add Friend
                            </button>
                        </div>
                        <div class="follower d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="image">
                                    <img src="images/users/user7.jpg" class="rounded-circle" alt="user-image">
                                </div>
                                <div class="title">
                                    <span class="d-block fw-medium">
                                        Raymond Nguyen
                                    </span>
                                    <span class="d-block text-body">
                                        nguyen&#64;gmail.com
                                    </span>
                                </div>
                            </div>
                            <button
                                mat-button
                                class="text-body p-0 h-auto"
                            >
                                Add Friend
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-lg-6 col-xxxl-12">
                <mat-card
                    class="daxa-card followers-card mb-25 border-radius bg-white border-none d-block"
                    [class.rtl-enabled]="themeService.isRTLEnabled()"
                >
                    <mat-card-header>
                        <mat-card-title>
                            <h5 class="mb-0">
                                Following
                            </h5>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="follower d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="image">
                                    <img src="images/users/user8.jpg" class="rounded-circle" alt="user-image">
                                </div>
                                <div class="title">
                                    <span class="d-block fw-medium">
                                        Lenore Crum
                                    </span>
                                    <span class="d-block text-body">
                                        crum&#64;gmail.com
                                    </span>
                                </div>
                            </div>
                            <button
                                mat-button
                                class="text-body p-0 h-auto"
                            >
                                Add Friend
                            </button>
                        </div>
                        <div class="follower d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="image">
                                    <img src="images/users/user9.jpg" class="rounded-circle" alt="user-image">
                                </div>
                                <div class="title">
                                    <span class="d-block fw-medium">
                                        Paul Bartlett
                                    </span>
                                    <span class="d-block text-body">
                                        bartlett&#64;gmail.com
                                    </span>
                                </div>
                            </div>
                            <button
                                mat-button
                                class="text-body p-0 h-auto"
                            >
                                Add Friend
                            </button>
                        </div>
                        <div class="follower d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="image">
                                    <img src="images/users/user10.jpg" class="rounded-circle" alt="user-image">
                                </div>
                                <div class="title">
                                    <span class="d-block fw-medium">
                                        Nikki Lowe
                                    </span>
                                    <span class="d-block text-body">
                                        lowe&#64;gmail.com
                                    </span>
                                </div>
                            </div>
                            <button
                                mat-button
                                class="text-body p-0 h-auto"
                            >
                                Add Friend
                            </button>
                        </div>
                        <div class="follower d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="image">
                                    <img src="images/users/user11.jpg" class="rounded-circle" alt="user-image">
                                </div>
                                <div class="title">
                                    <span class="d-block fw-medium">
                                        Milton Laskowski
                                    </span>
                                    <span class="d-block text-body">
                                        laskowski&#64;gmail.com
                                    </span>
                                </div>
                            </div>
                            <button
                                mat-button
                                class="text-body p-0 h-auto"
                            >
                                Add Friend
                            </button>
                        </div>
                        <div class="follower d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="image">
                                    <img src="images/users/user12.jpg" class="rounded-circle" alt="user-image">
                                </div>
                                <div class="title">
                                    <span class="d-block fw-medium">
                                        Ethel Hillen
                                    </span>
                                    <span class="d-block text-body">
                                        hillen&#64;gmail.com
                                    </span>
                                </div>
                            </div>
                            <button
                                mat-button
                                class="text-body p-0 h-auto"
                            >
                                Add Friend
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-lg-6 col-xxxl-12">
                <mat-card
                    class="daxa-card photos-card mb-25 border-radius bg-white border-none d-block"
                    [class.rtl-enabled]="themeService.isRTLEnabled()"
                >
                    <mat-card-header>
                        <mat-card-title>
                            <h5 class="mb-0">
                                Photos
                            </h5>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-4">
                                <a routerLink="/social" class="d-block">
                                    <img src="images/photos/photo1.jpg" alt="photo-image">
                                </a>
                            </div>
                            <div class="col-4">
                                <a routerLink="/social" class="d-block">
                                    <img src="images/photos/photo2.jpg" alt="photo-image">
                                </a>
                            </div>
                            <div class="col-4">
                                <a routerLink="/social" class="d-block">
                                    <img src="images/photos/photo3.jpg" alt="photo-image">
                                </a>
                            </div>
                            <div class="col-4">
                                <a routerLink="/social" class="d-block">
                                    <img src="images/photos/photo4.jpg" alt="photo-image">
                                </a>
                            </div>
                            <div class="col-4">
                                <a routerLink="/social" class="d-block">
                                    <img src="images/photos/photo5.jpg" alt="photo-image">
                                </a>
                            </div>
                            <div class="col-4">
                                <a routerLink="/social" class="d-block">
                                    <img src="images/photos/photo6.jpg" alt="photo-image">
                                </a>
                            </div>
                            <div class="col-4">
                                <a routerLink="/social" class="d-block">
                                    <img src="images/photos/photo7.jpg" alt="photo-image">
                                </a>
                            </div>
                            <div class="col-4">
                                <a routerLink="/social" class="d-block">
                                    <img src="images/photos/photo8.jpg" alt="photo-image">
                                </a>
                            </div>
                            <div class="col-4">
                                <a routerLink="/social" class="d-block">
                                    <img src="images/photos/photo9.jpg" alt="photo-image">
                                </a>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>