import {Inject, Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {NotificationService} from "../../infrastructure/services/notification.service";
import {NotificationModel} from "../../core/domain/models/notification.model";
import {GetNotificationListRequest} from "../../infrastructure/adapters/http/requests/get-notification-list.request";
import {ReadNotificationResponse} from "../../infrastructure/adapters/http/responses/read-notification.response";

@Injectable({
    providedIn: 'root'
})
export class UpdateNotificationAsReadUseCase {
    constructor(
      private notificationService: NotificationService
    ) {}
    execute(ids: string[]): Observable<ReadNotificationResponse> {
        return this.notificationService.markNotificationsAsRead(ids).pipe(
            catchError(error => {
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    // Xử lý lỗi
    private handleError(error: any): void {
        console.error('Error fetching user info', error);
    }
}
