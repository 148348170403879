import {SOCKET_TOKEN, SocketInterface} from "../../core/ports/socket.interface";
import {Inject, Injectable} from "@angular/core";
import {PROJECT_TOKEN} from "../../core/ports/project.token";

@Injectable({
    providedIn: 'root'
})
export class SocketConnectUseCase {
    constructor(
        @Inject(SOCKET_TOKEN) private socketInterface: SocketInterface
    ) {}
    execute(): void {
        this.socketInterface.connect();
    }
}