
<div class="drawing-board">
    <div class="wrapper">
        <div class="col-right">
            <div
                id="drawflow"
                (dragenter)="onDrawFlowEvent($event)"
                (dragleave)="onDrawFlowEvent($event)"
                (dragover)="onDrawFlowEvent($event)"
                (dragend)="onDrawFlowEvent($event)"
                (drop)="onDrawFlowEvent($event)"
            ></div>
        </div>
    </div>
</div>
