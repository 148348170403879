<mat-card class="daxa-card total-orders-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-content>
        <div class="d-flex justify-content-between">
            <div class="title">
                <h5>
                    Total Orders
                </h5>
                <div class="number fw-medium lh-1">
                    20,705
                </div>
            </div>
            <div class="icon text-center bg-daxa rounded-circle text-white position-relative">
                <i class="material-symbols-outlined">
                    shopping_basket
                </i>
            </div>
        </div>
        <div class="info d-flex align-items-center justify-content-between">
            <span class="sub-title d-block text-body">
                4.75% increase in orders last week
            </span>
            <span class="daxa-up-down-badge position-relative">
                <i class="material-symbols-outlined">
                    trending_up
                </i>
                4.75%
            </span>
        </div>
    </mat-card-content>
</mat-card>