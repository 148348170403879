<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Distributed Column Chart
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <apx-chart
            [series]="chartOptions.series!"
            [chart]="chartOptions.chart!"
            [dataLabels]="chartOptions.dataLabels!"
            [plotOptions]="chartOptions.plotOptions!"
            [yaxis]="chartOptions.yaxis!"
            [xaxis]="chartOptions.xaxis!"
            [legend]="chartOptions.legend!"
            [colors]="chartOptions.colors!"
            [grid]="chartOptions.grid!"
        ></apx-chart>
    </mat-card-content>
</mat-card>