<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Widgets
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Widgets
        </li>
    </ol>
</div>

<!-- Widgets -->
<div class="row">
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets Open -->
        <app-tickets-open />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets In Progress -->
        <app-tickets-in-progress />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets Resolved -->
        <app-tickets-resolved />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets Closed -->
        <app-tickets-closed />

    </div>
    <div class="col-md-12 col-xxxl-6">

        <!-- New Tickets Created -->
        <app-new-tickets-created />

    </div>
    <div class="col-lg-6 col-xxxl-3">
        <div class="row">
            <div class="col-md-6 col-lg-12">

                <!-- First Response Time -->
                <app-first-response-time />

            </div>
            <div class="col-md-6 col-lg-12">

                <!-- Ave Resolution Time -->
                <app-ave-resolution-time />

            </div>
        </div>
    </div>
    <div class="col-lg-6 col-xxxl-3">

        <!-- Customer Satisfaction -->
        <app-customer-satisfaction />
        
    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Most Leads -->
        <app-most-leads />

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Country Stats -->
        <app-country-stats />

    </div>
    <div class="col-lg-12 col-xxxl-4">
        <div class="row">
            <div class="col-lg-6 col-xxxl-12">

                <!-- Tasks Stats -->
                <app-tasks-stats />

            </div>
            <div class="col-lg-6 col-xxxl-12">

                <!-- Earning Reports -->
                <app-earning-reports />
                
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xxxl-12">

        <!-- Stats -->
        <app-stats />

    </div>
    <div class="col-lg-4">

        <!-- Working Schedule -->
        <app-working-schedule />

    </div>
    <div class="col-lg-8">
        <div class="row">
            <div class="col-lg-6">

                <!-- Projects Analysis -->
                <app-projects-analysis />

            </div>
            <div class="col-lg-6">

                <!-- Chat Project User -->
                <app-chat-projects-user />

            </div>
            <div class="col-lg-12">

                <!-- To Do List -->
                <app-to-do-list />

            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xxxl-6">

        <!-- Welcome -->
        <app-welcome />

    </div>
    <div class="col-lg-12 col-xxxl-6">
        <div class="row">
            <div class="col-md-4">

                <!-- Active Courses -->
                <app-active-courses />

            </div>
            <div class="col-md-4">

                <!-- Enrolled Students -->
                <app-enrolled-students />

            </div>
            <div class="col-md-4">

                <!-- Completion Status -->
                <app-completion-status />

            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-6 col-xxxl-3 order-lg-1">

        <!-- Time Spending's -->
        <app-time-spendings />

    </div>
    <div class="col-lg-12 col-xxxl-6 order-lg-3 order-xxxl-2">

        <!-- Average Enrollment Rate -->
        <app-average-enrollment-rate />
        
    </div>
    <div class="col-lg-6 col-xxxl-3 order-lg-2 order-xxxl-3">

        <!-- Today's Course -->
        <app-todays-course />
        
    </div>
</div>
<div class="row">
    <div class="col-lg-7">

        <!-- SLA Monitoring -->
        <app-sla-monitoring />

    </div>
    <div class="col-lg-5">

        <!-- Tickets Solved and Created -->
        <app-tickets-solved-and-cleated />

    </div>
    <div class="col-lg-12">

        <!-- Performance of Agents -->
        <app-performance-of-agents />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets by Channel -->
        <app-tickets-by-channel />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets by Type -->
        <app-tickets-by-type />

    </div>
    <div class="col-lg-12 col-xxxl-6">

        <!-- Recent Customer Rating -->
        <app-recent-customer-rating />

    </div>
    <div class="col-lg-12 col-xxxl-6">

        <!-- Projects Overview -->
        <app-projects-overview />

    </div>
    <div class="col-lg-12 col-xxxl-6">

        <!-- Projects Roadmap -->
        <app-projects-roadmap />

    </div>
    <div class="col-lg-6 col-xxxl-6">

        <!-- Total Sales -->
        <app-total-sales />

    </div>
    <div class="col-lg-6 col-xxxl-3">
        <div class="row">
            <div class="col-md-6 col-lg-12">

                <!-- Total Orders -->
                <app-total-orders />

            </div>
            <div class="col-md-6 col-lg-12">

                <!-- Total Customers -->
                <app-total-customers />
                
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xxxl-3">
        <div class="row">
            <div class="col-md-6 col-xxxl-12">

                <!-- Total Revenue -->
                <app-total-revenue />

            </div>
            <div class="col-md-6 col-xxxl-12">

                <!-- Sales Overview -->
                <app-sales-overview />
                
            </div>
        </div>
    </div>
</div>