<mat-card class="daxa-card total-customers-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-content>
        <div class="d-flex justify-content-between">
            <div class="title">
                <h5>
                    Total Customers
                </h5>
                <div class="number fw-medium lh-1">
                    84,127
                </div>
            </div>
            <div class="icon text-center bg-info rounded-circle text-white position-relative">
                <i class="material-symbols-outlined">
                    diversity_2
                </i>
            </div>
        </div>
        <div class="info d-flex align-items-center justify-content-between">
            <span class="sub-title d-block text-body">
                Total visitors decreased by 1.25%
            </span>
            <span class="daxa-up-down-badge down position-relative">
                <i class="material-symbols-outlined">
                    trending_down
                </i>
                1.25%
            </span>
        </div>
    </mat-card-content>
</mat-card>