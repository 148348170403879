export enum SupportedLanguages {
    English = 'en',
    Vietnamese = 'vi',
}

export const getLanguage = (localCode: string): SupportedLanguages => {
    switch (localCode) {
        case SupportedLanguages.English: return  SupportedLanguages.English;
        default: return SupportedLanguages.Vietnamese;
    }
}
export const getCurrentLanguage = (): string => {
    const langPath = window.location.pathname.split('/')[1];
    const lang = getLanguage(langPath)
    console.log("LangSupport - getCurrentLanguage", lang);
    return lang;
}