<div
    class="row"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/png.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    sketch-design.zip
                </h5>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/jpg.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    compile.png
                </h5>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/txt.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    integrations.pdf
                </h5>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/pdf.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    contact&#64;32.txt
                </h5>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/xl4.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    app-Design.doc
                </h5>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/doc.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    image02.png
                </h5>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/png.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    ubold-sketch.doc
                </h5>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/jpg.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    annualreport.txt
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        522 Files
                    </span>
                    <span class="d-block text-body">
                        5.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/txt.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    wireframes.xl4
                </h5>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/pdf.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    sketch-design.zip
                </h5>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/xl4.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    daxa.png
                </h5>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="icon">
                    <img src="images/icons/doc.png" alt="icon">
                </div>
                <h5 class="mb-0">
                    hello32.txt
                </h5>
            </mat-card-content>
        </mat-card>
    </div>
</div>