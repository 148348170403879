import {Inject, Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {PROJECT_TOKEN} from "../../core/ports/project.token";
import {ProjectInterface} from "../../core/ports/project.interface";
import {ProjectModel} from "../../core/domain/models/project.model";

@Injectable({
    providedIn: 'root'
})
export class CreateProjectUseCase {
    constructor(@Inject(PROJECT_TOKEN)  private projectInterface: ProjectInterface) {}
    execute(requestModel: ProjectModel ): Observable<ProjectModel> {
        return this.projectInterface.createProject(requestModel).pipe(
            catchError(error => {
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    // Xử lý lỗi
    private handleError(error: any): void {
        console.error('Error create project', error);
    }
}
