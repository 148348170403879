<div class="reset-password-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div
                class="daxa-form ml-auto mr-auto border-radius bg-white"
                [class.card-borderd-theme]="themeService.isCardBorder()"
            >
                <div class="title text-center">
                    <h3>
                        Reset Password?
                    </h3>
                    <p class="ml-auto mr-auto">
                        Enter your new password and confirm it another time in the field below.
                    </p>
                </div>
                <form>
                    <div class="form-group">
                        <label class="main-label d-block lh-1 text-black">
                            Password
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter password
                            </mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <span class="material-symbols-outlined">
                                    {{hide ? 'visibility_off' : 'visibility'}}
                                </span>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <label class="main-label d-block lh-1 text-black">
                            Confirm Password
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Confirm password
                            </mat-label>
                            <input matInput [type]="hide2 ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                <span class="material-symbols-outlined">
                                    {{hide2 ? 'visibility_off' : 'visibility'}}
                                </span>
                            </button>
                        </mat-form-field>
                    </div>
                    <button mat-button class="btn" type="submit">
                        Send
                    </button>
                    <div class="text-center back-to-sign-in">
                        <a mat-button routerLink="/authentication">
                            <i class="ri-arrow-left-s-line"></i>
                            Back to Sign In
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>