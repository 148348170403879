<div class="row">
    <div class="col-lg-6 col-xxxl-6">

        <!-- Total Sales -->
        <app-total-sales />

    </div>
    <div class="col-lg-6 col-xxxl-3">
        <div class="row">
            <div class="col-md-6 col-lg-12">

                <!-- Total Orders -->
                <app-total-orders />

            </div>
            <div class="col-md-6 col-lg-12">

                <!-- Total Customers -->
                <app-total-customers />
                
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xxxl-3">
        <div class="row">
            <div class="col-md-6 col-xxxl-12">

                <!-- Total Revenue -->
                <app-total-revenue />

            </div>
            <div class="col-md-6 col-xxxl-12">

                <!-- Sales Overview -->
                <app-sales-overview />
                
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-xxxl-3">
        <div class="row">
            <div class="col-lg-12 col-xxxl-12">

                <!-- Profit -->
                <app-profit />

            </div>
            <div class="col-lg-12 col-xxxl-12">

                <!-- Best Seller Of The Month -->
                <app-best-seller-of-the-month />

            </div>
        </div>
    </div>
    <div class="col-lg-6 col-xxxl-3">
        <div class="row">
            <div class="col-md-6 col-lg-12 col-xxxl-12">

                <!-- Average Daily Sales -->
                <app-average-daily-sales />

            </div>
            <div class="col-md-6 col-lg-12 col-xxxl-12">

                <!-- New Customers This Month -->
                <app-new-customers-this-month />

            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xxxl-6">

        <!-- Top Selling Products -->
        <app-top-selling-products />
        
    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Orders Summary -->
        <app-order-summary />

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Top Sellers -->
        <app-top-sellers />
        
    </div>
    <div class="col-lg-12 col-xxxl-4">
        <div class="row">
            <div class="col-lg-6 col-xxxl-12">

                <!-- Top Sales Locations -->
                <app-top-sales-locations />

            </div>
            <div class="col-lg-6 col-xxxl-12">

                <!-- Revenue -->
                <app-revenue />
                
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xxxl-8">

        <!-- Recent Orders -->
        <app-recent-orders />

    </div>
    <div class="col-lg-12 col-xxxl-4">

        <!-- Transactions History -->
        <app-transactions-history />
        
    </div>
</div>