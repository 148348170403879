<mat-card
    class="daxa-card all-projects-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                All Projects
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Month
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="all-projects-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>
                            ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.id}}
                        </td>
                    </ng-container>

                    <!-- Project Name Column -->
                    <ng-container matColumnDef="projectName">
                        <th mat-header-cell *matHeaderCellDef>
                            Project Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.projectName}}
                        </td>
                    </ng-container>

                    <!-- Client Column -->
                    <ng-container matColumnDef="client">
                        <th mat-header-cell *matHeaderCellDef>
                            Client
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black">
                            {{element.client}}
                        </td>
                    </ng-container>

                    <!-- Start Date Column -->
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Start Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.startDate}}
                        </td>
                    </ng-container>

                    <!-- End Date Column -->
                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef>
                            End Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.endDate}}
                        </td>
                    </ng-container>

                    <!-- Budget Column -->
                    <ng-container matColumnDef="budget">
                        <th mat-header-cell *matHeaderCellDef>
                            Budget
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.budget}}
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="daxa-badge in-progress d-inline-block" *ngIf="element.status.inProgress">
                                {{element.status.inProgress}}
                            </span>
                            <span class="daxa-badge pending d-inline-block" *ngIf="element.status.pending">
                                {{element.status.pending}}
                            </span>
                            <span class="daxa-badge completed d-inline-block" *ngIf="element.status.completed">
                                {{element.status.completed}}
                            </span>
                            <span class="daxa-badge not-started d-inline-block" *ngIf="element.status.notStarted">
                                {{element.status.notStarted}}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="action-info">
                                <button type="button" mat-button matTooltip="View Project" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.view}}
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.delete}}
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>