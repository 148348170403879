import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {UserInfoResponse} from "./responses/user-info.response";
import {environment} from "../../../../environments/environment";
import {UpdateUserInfoRequest} from "./requests/user-info.request";
import {UpdateUserAvatarRequest} from "./requests/upload-account-avatar.request";

@Injectable({
    providedIn: 'root'
})
export class UserInfoAdapter {
    private gateway = environment.adapter.http.gateway;
    private userService = environment.adapter.http.services.userManager;

    constructor(private http: HttpClient) {}

    getUserInfo(): Observable<UserInfoResponse> {
        const url = `${this.gateway}${this.userService.name}${this.userService.endpoint.getUserInfo}`;

        return this.http.get<UserInfoResponse>(url);
    }

    updateUserInfo( userInfo: UpdateUserInfoRequest): Observable<UserInfoResponse> {
        const url = `${this.gateway}${this.userService.name}${this.userService.endpoint.updateUserInfo}`;
        return this.http.patch<UserInfoResponse>(url, userInfo);
    }

    updateUserAvatar( request: UpdateUserAvatarRequest): Observable<any> {
        const url = `${this.gateway}${this.userService.name}${this.userService.endpoint.updateUserAvatar}`;
        let params = new FormData();
        params.append("avatar", request.avatar);
        return this.http.post<any>(url, params);
    }
}
