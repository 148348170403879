import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {ListNotificationResponse} from "./responses/list-notification.response";
import {ReadNotificationRequest} from "./requests/read-notification.request";
import {ReadNotificationResponse} from "./responses/read-notification.response";
import {GetNotificationListRequest} from "./requests/get-notification-list.request";
import {toHttpParams} from "./http-util";
import {BaseResponse} from "./responses/base.response";
import {CreateFcmTokenRequest} from "./requests/create-fcm-token.request";
import {CreateFcmTokenResponse} from "./responses/create-fcm-token.response";

@Injectable({
    providedIn: 'root'
})
export class NotificationAdapter {
    private gateway = environment.adapter.http.gateway;
    private communicationService = environment.adapter.http.services.communicationService;

    constructor(private http: HttpClient) {}

    getNotifications(getNotificationListRequest: GetNotificationListRequest): Observable<ListNotificationResponse> {
        const url = `${this.gateway}${this.communicationService.name}${this.communicationService.endpoint.getListNotification}`;
        let params = toHttpParams(getNotificationListRequest);
        return this.http.get<ListNotificationResponse>(url, { params });
    }

    readNotification( readNotificationRequest: ReadNotificationRequest): Observable<ReadNotificationResponse> {
        const url = `${this.gateway}${this.communicationService.name}${this.communicationService.endpoint.readNotification}`;
        return this.http.put<ReadNotificationResponse>(url, readNotificationRequest);
    }

    createNotificationToken( createFcmTokenRequest: CreateFcmTokenRequest): Observable<CreateFcmTokenResponse> {
        const url = `${this.gateway}${this.communicationService.name}${this.communicationService.endpoint.createNotificationToken}`;
        return this.http.post<CreateFcmTokenResponse>(url, createFcmTokenRequest);
    }
}


