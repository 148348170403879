import {Inject, Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {MyProfileService} from "../../core/services/my-profile.service";
import {MyProfileModel} from "../../core/domain/models/my-profile.model";

@Injectable({
    providedIn: 'root'
})
export class GetUserInfoUseCase {
    constructor(
      private myProfileService: MyProfileService
    ) {}
    execute(): Observable<MyProfileModel> {
        return this.myProfileService.getMyProfile().pipe(
            catchError(error => {
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    // Xử lý lỗi
    private handleError(error: any): void {
        console.error('Error fetching user info', error);
    }
}
