<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Calendar
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Apps
        </li>
        <li class="breadcrumb-item position-relative">
            Calendar
        </li>
    </ol>
</div>

<!-- Calendar -->
<div class="row">
    <div class="col-xxxl-8">
        <mat-card
            class="daxa-card calendar-card mb-25 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-content>
                <full-calendar [options]="calendarOptions"></full-calendar>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-5 col-xxxl-4">
        <app-working-schedule />
    </div>
</div>