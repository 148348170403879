import {Inject, Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {NotificationService} from "../../infrastructure/services/notification.service";
import {NotificationModel} from "../../core/domain/models/notification.model";
import {GetNotificationListRequest} from "../../infrastructure/adapters/http/requests/get-notification-list.request";
import {AddressService} from "../../infrastructure/services/address.service";
import {AddressModel} from "../../core/domain/models/address.model";
import {CountryModel} from "../../core/domain/models/country.model";

@Injectable({
    providedIn: 'root'
})
export class GetListCountryUseCase {
    constructor(
        private addressService: AddressService
    ) {}
    execute(): Observable<CountryModel[]> {
        return this.addressService.getListCountry()
            .pipe(
                catchError(error => {
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    // Xử lý lỗi
    private handleError(error: any): void {
        console.error('Error fetching user info', error);
    }
}
