import {NgClass, NgOptimizedImage} from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import {Component, HostListener, Input} from '@angular/core';
import { ToggleService } from '../sidebar/toggle.service';
import { MatButtonModule } from '@angular/material/button';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import {Store} from "@ngrx/store";
import {UserState} from "../../../../application/state/reducers/user.reducer";
import {Observable} from "rxjs";
import {UserInfoModel} from "../../../../core/domain/models/user-info.model";
import {NotificationModel} from "../../../../core/domain/models/notification.model";
import {GetListNotificationUseCase} from "../../../../application/use-cases/get-list-notification.use-case";
import {
    GetNotificationListRequest
} from "../../../../infrastructure/adapters/http/requests/get-notification-list.request";
import {UpdateNotificationAsReadUseCase} from "../../../../application/use-cases/update-notification-as-read.use-case";
import {TimeAgoPipe} from "../../../features/components/time-ago/time-ago.pipe";
import {getLanguage, SupportedLanguages} from "../../../../application/constants/langs-supported";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {NotificationState} from "../../../../application/state/reducers/notification.reducer";
import {
    newNotificationReceived,
    setNotificationCount
} from "../../../../application/state/actions/notification.actions";

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [CommonModule, NgClass, MatMenuModule, MatButtonModule, RouterLink, RouterLinkActive, TimeAgoPipe, NgOptimizedImage],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent {

    // isSidebarToggled
    isSidebarToggled = false;

    currentLanguage: string;

    // isToggled
    isToggled = false;

    notifications : NotificationModel[] = [];

    notificationsBadge : string = '';

    notificationOpened : NotificationModel;

    user$: Observable<UserInfoModel | null>;
    notifications$: Observable<NotificationModel[] | null>;
    notificationCount$: Observable<number | string>;
    newNotification$: Observable<boolean>;


    constructor(
        private store: Store<{ user: UserState }>,
        private storeNotification: Store<{  notification: NotificationState }>,
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        private getListNotificationUseCase: GetListNotificationUseCase,
        private updateNotificationAsReadUseCase: UpdateNotificationAsReadUseCase,
        private router: Router

    ) {
        this.toggleService.isSidebarToggled$.subscribe(isSidebarToggled => {
            this.isSidebarToggled = isSidebarToggled;
        });
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        this.user$ = this.store.select((state: { user: UserState }) => state.user.user);
        this.currentLanguage = this.getCurrentLanguage();
        console.log("user$ header", this.user$)
        console.log("currentLanguage header", this.currentLanguage)
        this.notifications$ = this.storeNotification.select((state: { notification: NotificationState }) => state.notification.notifications);
        this.notificationCount$ = this.storeNotification.select((state: { notification: NotificationState }) => state.notification.notificationCount);
        this.newNotification$ = this.storeNotification.select((state: { notification: NotificationState }) => state.notification.newNotification);
        this.notifications$.subscribe(data => {
            if (data) {
                this.handleGetNotifications(data);
            }
        });
    }

    // Hàm lấy ngôn ngữ từ URL
    getCurrentLanguage(): string {
        const langPath = window.location.pathname.split('/')[1];
        const lang = getLanguage(langPath)
        console.log("currentLanguage", lang);
        return lang;
    }

    // Hàm chuyển đổi ngôn ngữ
    switchLanguage(language: SupportedLanguages): void {
        const pathArray = window.location.pathname.split('/').slice(2).join('/'); // Bỏ ngôn ngữ hiện tại
        const newUrl = `/${language}/${pathArray}`; // Xây dựng URL mới với ngôn ngữ mới

        // Sử dụng window.location.replace để thay đổi URL mà không tạo lịch sử điều hướng
        window.location.replace(newUrl);
    }

    // Burger Menu Toggle
    toggle() {
        this.toggleService.toggle();
    }

    // Header Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    // Dark Mode
    toggleTheme() {
        this.themeService.toggleTheme();
    }

    getHeaderNotifications(): void {
        const requestModel: GetNotificationListRequest = {
            isRead: false
        }
        this.getListNotificationUseCase.execute(requestModel).subscribe({
            next: (data) => {
                console.log(data);
                this.handleGetNotifications(data);
            },
            error: (error) => {
                console.error('Error:', error);
            }
        });
    }

    handleGetNotifications(data: NotificationModel[]): void {
        this.notifications = data;
        this.store.dispatch(setNotificationCount({count: data.length}));

        if (this.notifications.length > 0) {
            this.notificationsBadge = this.notifications.length + '';
        } else if (this.notifications.length > 10) {
            this.notificationsBadge = '9+';
        }
    }

    setNotificationOpened(notification: NotificationModel, event: Event): void {
        this.notificationOpened = notification;
        event.stopPropagation(); // Ngăn chặn sự kiện click làm ẩn menu dropdown
        if (!this.notificationOpened.isRead) {
            notification.isRead = true;
            this.updateNotificationAsReadUseCase.execute([notification.id])
                .subscribe({
                    next: (data) => {
                        console.log('updateNotificationAsReadUseCase', data);
                    },
                    error: (error) => {
                        console.error('Error:', error);
                    }
            });
        }
    }

    protected readonly SupportedLanguages = SupportedLanguages;
}
