import {inject, Inject, Injectable} from '@angular/core';
import { io, Socket } from 'socket.io-client';
import {Observable} from "rxjs";
import {OAuthService} from "angular-oauth2-oidc";
import {SocketInterface} from "../../core/ports/socket.interface";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SocketService implements SocketInterface{
  private socket: Socket;
  private gateway = environment.socket.gateway
  constructor(private oauthService: OAuthService) {}

  connect(): void {
    let token = this.oauthService.getAccessToken();
    this.socket = io(this.gateway, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
        },
      },
    });
    // Sự kiện kết nối thành công
    this.socket.on('connect', () => {
      console.log('Socket connected:', this.socket.id);
    });

    // Sự kiện lỗi kết nối
    this.socket.on('connect_error', (error: any) => {
      console.error('Connection error:', error);
    });

    // Sự kiện ngắt kết nối
    this.socket.on('disconnect', (reason: string) => {
      console.warn('Socket disconnected:', reason);
    });
  }

  listen(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }

  emit(eventName: string, data: any): void {
    this.socket.emit(eventName, data);
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
