<mat-card
    class="daxa-card top-selling-products-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Top Selling Products
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="top-selling-products-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Number Column -->
                    <ng-container matColumnDef="number">
                        <td mat-cell *matCellDef="let element" class="fw-medium">
                            {{element.number}}
                        </td>
                    </ng-container>

                    <!-- Product Column -->
                    <ng-container matColumnDef="product">
                        <td mat-cell *matCellDef="let element">
                            <div class="product-info d-flex align-items-center">
                                <div class="image">
                                    <img [src]="element.product.productImage" alt="product-image">
                                </div>
                                <div class="info">
                                    <a routerLink="/ecommerce-page/product-details" class="d-inline-block">
                                        {{element.product.productName}}
                                    </a>
                                    <span class="d-block text-body">
                                        {{element.product.productSold}}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Invoice Number Column -->
                    <ng-container matColumnDef="invoiceNumber">
                        <td mat-cell *matCellDef="let element">
                            {{element.invoiceNumber}}
                        </td>
                    </ng-container>

                    <!-- Price Column -->
                    <ng-container matColumnDef="price">
                        <td mat-cell *matCellDef="let element">
                            {{element.price}}
                        </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[6, 12, 18]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>