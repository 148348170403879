<div class="row">
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets Open -->
        <app-tickets-open />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets In Progress -->
        <app-tickets-in-progress />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets Resolved -->
        <app-tickets-resolved />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets Closed -->
        <app-tickets-closed />

    </div>
    <div class="col-md-12 col-xxxl-6">

        <!-- New Tickets Created -->
        <app-new-tickets-created />

    </div>
    <div class="col-lg-6 col-xxxl-3">
        <div class="row">
            <div class="col-md-6 col-lg-12">

                <!-- First Response Time -->
                <app-first-response-time />

            </div>
            <div class="col-md-6 col-lg-12">

                <!-- Ave Resolution Time -->
                <app-ave-resolution-time />

            </div>
        </div>
    </div>
    <div class="col-lg-6 col-xxxl-3">

        <!-- Customer Satisfaction -->
        <app-customer-satisfaction />
        
    </div>
    <div class="col-lg-7">

        <!-- SLA Monitoring -->
        <app-sla-monitoring />

    </div>
    <div class="col-lg-5">

        <!-- Tickets Solved and Created -->
        <app-tickets-solved-and-cleated />

    </div>
    <div class="col-lg-12">

        <!-- Performance of Agents -->
        <app-performance-of-agents />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets by Channel -->
        <app-tickets-by-channel />

    </div>
    <div class="col-md-6 col-xxxl-3">

        <!-- Tickets by Type -->
        <app-tickets-by-type />

    </div>
    <div class="col-lg-12 col-xxxl-6">

        <!-- Recent Customer Rating -->
        <app-recent-customer-rating />

    </div>
</div>