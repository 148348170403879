<mat-card
    class="daxa-card tickets-resolved-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-content>
        <span class="title d-block text-body">
            Tickets Resolved
        </span>
        <h3 class="mb-0 lh-1">
            753
        </h3>
        <span class="info d-block position-relative text-danger">
            <i class="material-symbols-outlined">
                trending_down
            </i>
            7.25% <span class="text-body">This Week</span>
        </span>
        <div class="chart">
            <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [xaxis]="chartOptions.xaxis!"
                [stroke]="chartOptions.stroke!"
                [dataLabels]="chartOptions.dataLabels!"
                [colors]="chartOptions.colors!"
                [yaxis]="chartOptions.yaxis!"
                [fill]="chartOptions.fill!"
                [grid]="chartOptions.grid!"
                [labels]="chartOptions.labels!"
                [legend]="chartOptions.legend!"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>