<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Datepicker
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Datepicker
        </li>
    </ol>
</div>

<!-- Datepicker -->
<div class="row">
    <div class="col-xxl-4">
        
        <!-- Basic Datepicker -->
        <app-basic-datepicker />

    </div>
    <div class="col-xxl-8">
        
        <!-- Date Range Picker Comparison Ranges -->
        <app-drp-comparison-ranges />

    </div>
    <div class="col-xxl-4">
        
        <!-- Date Range Picker Forms Integration -->
        <app-drp-forms-integration />

    </div>
    <div class="col-xxl-8">
        
        <!-- Datepicker Action Buttons -->
        <app-datepicker-action-buttons />

    </div>
    <div class="col-xxl-5">
        
        <!-- Date Range Picker with Custom a Selection Strategy -->
        <app-drpwca-selection-strategy />

    </div>
    <div class="col-xxl-7">
        
        <!-- Datepicker Palette Colors -->
        <app-dp-paletter-colors />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker Open Method -->
        <app-datepicker-open-method />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker with Custom Icon -->
        <app-dpw-custom-icon />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker with Custom Date Classes -->
        <app-dpw-custom-date-classes />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker with Filter Validation -->
        <app-dpw-filter-validation />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker with Custom Formats -->
        <app-dpw-custom-formats />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker with Different Locale -->
        <app-dpw-different-locale />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker with Min & Max Validation -->
        <app-dpw-min-max-validation />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker that uses Moment.js Dates -->
        <app-dptu-moment-js-dates />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker Start Date -->
        <app-dp-start-date />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker Touch UI -->
        <app-dp-touch-ui />

    </div>
    <div class="col-xxl-8">
        
        <!-- Datepicker Selected Value -->
        <app-dp-selected-value />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker Emulating a Year and Month Picker -->
        <app-dpea-year-month-picker />

    </div>
    <div class="col-xxl-4">
        
        <!-- Disabled Datepicker -->
        <app-disabled-datepicker />

    </div>
    <div class="col-xxl-4">
        
        <!-- Datepicker Inline Calendar -->
        <app-dp-inline-calendar />

    </div>
</div>