<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Members
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Pages
        </li>
        <li class="breadcrumb-item position-relative">
            Members
        </li>
    </ol>
</div>

<!-- Members -->
<mat-card
    class="daxa-card members-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <form class="search-box position-relative">
                <i class="material-symbols-outlined">
                    search
                </i>
                <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search member..." (keyup)="applyFilter($event)" #input>
            </form>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="members-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>
                            ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.id}}
                        </td>
                    </ng-container>

                    <!-- Member Column -->
                    <ng-container matColumnDef="member">
                        <th mat-header-cell *matHeaderCellDef>
                            Member
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black">
                            <div class="info d-flex align-items-center">
                                <div class="image">
                                    <img [src]="element.member.img" alt="member-image">
                                </div>
                                <span class="d-block fw-medium">
                                    {{element.member.name}}
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>
                            Email
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.email}}
                        </td>
                    </ng-container>

                    <!-- Location Column -->
                    <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef>
                            Location
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.location}}
                        </td>
                    </ng-container>

                    <!-- Phone Column -->
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef>
                            Phone
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black">
                            {{element.phone}}
                        </td>
                    </ng-container>

                    <!-- Joining Date Column -->
                    <ng-container matColumnDef="joiningDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Joining Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.joiningDate}}
                        </td>
                    </ng-container>

                    <!-- Last Active Column -->
                    <ng-container matColumnDef="lastActive">
                        <th mat-header-cell *matHeaderCellDef>
                            Last Active
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.lastActive}}
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="action-info">
                                <button type="button" mat-button matTooltip="View" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.view}}
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.delete}}
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>