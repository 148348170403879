import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {GetNotificationListRequest} from "./requests/get-notification-list.request";
import {Observable} from "rxjs";
import {ListNotificationResponse} from "./responses/list-notification.response";
import {toHttpParams} from "./http-util";
import {
    AddressResponse,
    CreateAddressResponse, DeleteAddressResponse,
    GetAddressInfoResponse,
    ListAddressResponse
} from "./responses/address.response";
import {ListCountryResponse} from "./responses/country.response";
import {ListCityResponse} from "./responses/city.response";
import {GetListCityRequest} from "./requests/get-list-city.request";
import {GetListDistrictRequest} from "./requests/get-list-district.request";
import {CreateAddressRequest} from "./requests/create-address.request";
import {ReadNotificationResponse} from "./responses/read-notification.response";
import {ListDistrictResponse} from "./responses/district.response";

@Injectable({
    providedIn: 'root'
})

export class AddressAdapter {
    private gateway = environment.adapter.http.gateway;
    private userService = environment.adapter.http.services.userManager;

    constructor(private http: HttpClient) {
    }

    getListAddress(): Observable<AddressResponse> {
        const url = `${this.gateway}${this.userService.name}${this.userService.endpoint.getListAddress}`;
        return this.http.get<AddressResponse>(url, {});
    }

    getAddressInfo(id: number): Observable<GetAddressInfoResponse> {
        let url = `${this.gateway}${this.userService.name}${this.userService.endpoint.getAddressInfo}`;
        url = url.replace("{id}", id.toString());
        return this.http.get<GetAddressInfoResponse>(url, {});
    }

    getCountries(): Observable<ListCountryResponse> {
        const url = `${this.gateway}${this.userService.name}${this.userService.endpoint.getListCountry}`;
        return this.http.get<ListCountryResponse>(url, {});
    }

    getListCity(getListCityRequest : GetListCityRequest) : Observable<ListCityResponse> {
        let url = `${this.gateway}${this.userService.name}${this.userService.endpoint.getListCity}`;
        url = url.replace("{countryId}", getListCityRequest.countryId);
        url += `?page=${getListCityRequest.page}&size=${getListCityRequest.size}`;
        return this.http.get<ListCityResponse>(url, {});
    }

    getListDistrict(request : GetListDistrictRequest) : Observable<ListDistrictResponse> {
        let url = `${this.gateway}${this.userService.name}${this.userService.endpoint.getListProvince}`;
        url = url.replace("{countryId}", request.countryId);
        url = url.replace("{cityId}", request.cityId);
        url += `?page=${request.page}&size=${request.size}`;
        return this.http.get<ListCityResponse>(url, {});
    }

    createAddress(request: CreateAddressRequest) : Observable<CreateAddressResponse> {
        let url = `${this.gateway}${this.userService.name}${this.userService.endpoint.createAddress}`;
        return this.http.post<CreateAddressResponse>(url, request);
    }

    updateAddress(id: number, request: CreateAddressRequest) : Observable<CreateAddressResponse> {
        let url = `${this.gateway}${this.userService.name}${this.userService.endpoint.updateAddress}`;
        url = url.replace("{id}", id + '');
        return this.http.put<CreateAddressResponse>(url, request);
    }

    deleteAddress(id: number) : Observable<DeleteAddressResponse> {
        let url = `${this.gateway}${this.userService.name}${this.userService.endpoint.deleteAddress}`;
        url = url.replace("{id}", id + '');
        return this.http.delete<DeleteAddressResponse>(url);
    }
}