<mat-card
    class="daxa-card activity-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Recent Activity
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="item d-flex">
            <div class="image">
                <img src="images/users/user1.jpg" class="rounded-circle" alt="user-image">
            </div>
            <div class="content">
                <span class="title fw-medium">
                    Justin Butler
                </span>
                <span class="date d-block text-body">
                    20 Nov, 2024 (09:30 AM)
                </span>
                <p>
                    User Justin Butler updated their profile information.
                </p>
            </div>
        </div>
        <div class="item d-flex">
            <div class="image">
                <img src="images/users/user2.jpg" class="rounded-circle" alt="user-image">
            </div>
            <div class="content">
                <span class="title fw-medium">
                    Joshua Thayer
                </span>
                <span class="date d-block text-body">
                    18 Nov, 2024 (03:45 PM)
                </span>
                <p>
                    Discuss with the customer what kind of benefits will be provided in a project
                </p>
            </div>
        </div>
        <div class="item d-flex">
            <div class="image">
                <img src="images/users/user3.jpg" class="rounded-circle" alt="user-image">
            </div>
            <div class="content">
                <span class="title fw-medium">
                    Gladys Keen
                </span>
                <span class="date d-block text-body">
                    17 Nov, 2024 (11:15 AM)
                </span>
                <p>
                    Estimate the full budget of the project and take customer feedback
                </p>
            </div>
        </div>
        <div class="item d-flex">
            <div class="image">
                <img src="images/users/user4.jpg" class="rounded-circle" alt="user-image">
            </div>
            <div class="content">
                <span class="title fw-medium">
                    Stanley Mathews
                </span>
                <span class="date d-block text-body">
                    15 Nov, 2024 (02:00 PM)
                </span>
                <p>
                    Attempted access to a restricted area.
                </p>
            </div>
        </div>
        <div class="item d-flex">
            <div class="image">
                <img src="images/users/user5.jpg" class="rounded-circle" alt="user-image">
            </div>
            <div class="content">
                <span class="title fw-medium">
                    Jennifer Lockhart
                </span>
                <span class="date d-block text-body">
                    14 Nov, 2024 (04:20 PM)
                </span>
                <p>
                    User Jennifer Lockhart deactivated their account
                </p>
            </div>
        </div>
    </mat-card-content>
</mat-card>