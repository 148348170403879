import {Injectable} from "@angular/core";
import {AddressInterface} from "../../core/ports/address.interface";
import {AddressAdapter} from "../adapters/http/address.adapter";
import {Observable} from "rxjs";
import {AddressModel} from "../../core/domain/models/address.model";
import {map, tap} from "rxjs/operators";
import {
    AddressResponse,
    CreateAddressResponse, DeleteAddressResponse,
    GetAddressInfoResponse,
    ListAddressResponse
} from "../adapters/http/responses/address.response";
import {
    mapAddressResponseToModels,
    mapAddressToModel,
    mapCreateAddressResponseToModel, mapDeleteAddressResponseToModel, mapGetAddressInfoResponseToModel,
    mapListAddressResponseToModels
} from "../mappers/address.mapper";
import {CountryModel} from "../../core/domain/models/country.model";
import {ListCountryResponse} from "../adapters/http/responses/country.response";
import {mapListCountryResponseToModels} from "../mappers/country.mapper";
import {GetListAddressRequest} from "../adapters/http/requests/get-list-address.request";
import {GetListCityRequest} from "../adapters/http/requests/get-list-city.request";
import {ListCityResponse} from "../adapters/http/responses/city.response";
import {mapListCityResponseToModels} from "../mappers/city.mapper";
import {CityModel} from "../../core/domain/models/city.model";
import {GetListDistrictRequest} from "../adapters/http/requests/get-list-district.request";
import {ListDistrictResponse} from "../adapters/http/responses/district.response";
import {mapListProvinceResponseToModels} from "../mappers/district.mapper";
import {CreateAddressRequest} from "../adapters/http/requests/create-address.request";
import {DistrictModel} from "../../core/domain/models/district.model";

@Injectable({
    providedIn: 'root',
})
export class AddressService implements AddressInterface {

    constructor(private addressAdapter: AddressAdapter) {}

    getListAddress(): Observable<AddressModel[]> {
        return this.addressAdapter.getListAddress()
            .pipe(
                tap((response: AddressResponse) => console.log('Address response', response)),
                map((response: AddressResponse) => mapAddressResponseToModels(response)) // Map response sang model
        );
    }

    getAddressInfo(id:  number): Observable<AddressModel> {
        return this.addressAdapter.getAddressInfo(id)
            .pipe(
                tap((response: GetAddressInfoResponse) => console.log('Address response', response)),
                map((response: GetAddressInfoResponse) => mapGetAddressInfoResponseToModel(response)) // Map response sang model
        );
    }

    getListCountry(): Observable<CountryModel[]> {
        return this.addressAdapter.getCountries()
            .pipe(
                tap((response: ListCountryResponse) => console.log('Country response', response)),
                map((response: ListCountryResponse) => mapListCountryResponseToModels(response)) // Map response sang model
            );
    }

    getListCity(request: GetListCityRequest): Observable<CityModel[]> {
        return this.addressAdapter.getListCity(request)
            .pipe(
                tap((response: ListCityResponse) => console.log('City response', response)),
                map((response: ListCityResponse) => mapListCityResponseToModels(response)) // Map response sang model
            );
    }

    getListDistrict(request: GetListDistrictRequest): Observable<DistrictModel[]> {
        return this.addressAdapter.getListDistrict(request)
            .pipe(
                tap((response: ListDistrictResponse) => console.log('District response', response)),
                map((response: ListDistrictResponse) => mapListProvinceResponseToModels(response)) // Map response sang model
            );
    }

    createAddress(request: CreateAddressRequest): Observable<AddressModel> {
        return this.addressAdapter.createAddress(request)
            .pipe(
                tap((response: CreateAddressResponse) => console.log('CreateAddressResponse response', response)),
                map((response: CreateAddressResponse) => mapCreateAddressResponseToModel(response)) // Map response sang model
            );
    }

    updateAddress(id: number, request: CreateAddressRequest): Observable<AddressModel> {
        return this.addressAdapter.updateAddress(id,request)
            .pipe(
                tap((response: CreateAddressResponse) => console.log('CreateAddressResponse response', response)),
                map((response: CreateAddressResponse) => mapCreateAddressResponseToModel(response)) // Map response sang model
            );
    }

    deleteAddress(id: number): Observable<DeleteAddressResponse> {
        return this.addressAdapter.deleteAddress(id)
            .pipe(
                tap((response: DeleteAddressResponse) => console.log('CreateAddressResponse response', response))
            );
    }
}