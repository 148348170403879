<header
    class="header-area bg-white border-radius transition"
    [ngClass]="{'active': isSidebarToggled, 'sticky': isSticky}"
    [class.component-dark-theme]="themeService.isDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.dark-header]="themeService.isHeaderDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-md-flex align-items-center justify-content-between">
        <div class="header-left-side d-flex align-items-center">
            <div
                class="burger-menu cursor-pointer transition d-xl-none"
                [ngClass]="{'active': isSidebarToggled}"
                (click)="toggle()"
            >
                <span class="top-bar d-block bg-black transition"></span>
                <span class="middle-bar d-block bg-black transition"></span>
                <span class="bottom-bar d-block bg-black transition"></span>
            </div>
            <form class="search-box position-relative">
                <i class="material-symbols-outlined">
                    search
                </i>
                <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search here...">
            </form>
        </div>
        <ul class="header-right-side d-flex align-items-center mt-0 mb-0 pl-0 list-unstyled">
            <li>
                <button mat-icon-button [matMenuTriggerFor]="languageMenu" class="language-menu-btn">
                    <i class="material-symbols-outlined">
                        translate
                    </i>
                </button>
                <mat-menu class="language-menu-dropdown" #languageMenu="matMenu" xPosition="before">
                    <div
                        [class.dark-menu]="themeService.isDark() || themeService.isHeaderDark()"
                    >
                        <span class="title fw-medium d-block">Choose Language</span>
                        <ul class="menu-body pl-0 mb-0 mt-0 list-unstyled">
                            <li *ngIf="SupportedLanguages.Vietnamese === currentLanguage">
                                <button  mat-button (click)="switchLanguage(SupportedLanguages.English)">
                                    <img src="images/flags/usa.png" alt="flag">
                                    English
                                </button>
                            </li>
                            <li *ngIf="SupportedLanguages.English === currentLanguage">
                                <button mat-button (click)="switchLanguage(SupportedLanguages.Vietnamese)">
                                    <img src="images/flags/australia.png" alt="flag">
                                    Tiếng Việt
                                </button>
                            </li>
                        </ul>
                    </div>
                </mat-menu>
            </li>
            <li>
                <button
                    mat-button
                    class="dark-swtich-btn"
                    (click)="toggleTheme()"
                >
                    <i class="material-symbols-outlined">
                        dark_mode
                    </i>
                </button>
            </li>
            <li>
                <button mat-icon-button [matMenuTriggerFor]="notificationsMenu"  (menuOpened)="getHeaderNotifications()" [ngClass]="{'shake': newNotification$ | async}" class="notifications-menu-btn p-0">
                    <i class="material-symbols-outlined">
                        notifications
                    </i>
                    <span class="daxa-badge " *ngIf="notificationCount$ | async as notificationCount">
                        <label *ngIf="notificationCount !== 0">{{notificationCount}}</label>
                    </span>
                </button>
                <mat-menu class="notifications-menu-dropdown" #notificationsMenu="matMenu" xPosition="before">
                    <div
                        [class.dark-menu]="themeService.isDark() || themeService.isHeaderDark()"
                    >
                        <div class="title d-flex align-items-center justify-content-between">
                            <span class="fw-medium">
                                <label i18n>Notifications</label>
                                <span class="text-body fw-normal">({{notifications.length}})</span>
                            </span>
                        </div>
                        <ul class="menu-body pl-0 mt-0 list-unstyled">
                            <li *ngFor="let notification of notifications" class="position-relative" >
                                <div class="icon rounded-circle position-absolute text-center transition">
                                    <i class="material-symbols-outlined">sms</i>
                                </div>
                                <span class="sub-title d-block">
                                    <strong class="fw-medium">{{ notification.title }}</strong>
                                </span>
                                <span class="message d-block text-body" *ngIf="notification.id === notificationOpened?.id">
                                     {{ notification.body }}
                                </span>
                                <span class="time d-block text-body">
                                    {{notification.createdTime | timeAgo}}
                                </span>
                                <a class="d-block link-btn position-absolute cursor-pointer" (click)="setNotificationOpened(notification, $event)"></a>
                                <span class="unread d-inline-block rounded-circle bg-daxa position-absolute" *ngIf="!notification.isRead"></span>
                            </li>
                        </ul>
                        <div class="menu-footer text-center">
                            <a routerLink="/notifications" class="link-btn text-daxa d-inline-block fw-medium">
                                See All Notifications
                            </a>
                        </div>
                    </div>
                </mat-menu>
            </li>
            <li *ngIf="user$ | async as user">
                <button mat-button [matMenuTriggerFor]="profileMenu" class="profile-menu-btn">
                    <img src="{{user.avatarThumbnailUrl || 'images/admin.png' }}" alt="profile-image">
                    <span class="status d-inline-block rounded-circle position-absolute"></span>
                </button>
                <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">
                    <div
                        [class.dark-menu]="themeService.isDark() || themeService.isHeaderDark()"
                    >
                        <div class="menu-header d-flex align-items-center">
                            <img src="{{user.avatarThumbnailUrl || 'images/admin.png' }}" alt="profile-image">
                            <div class="title">
                                <a routerLink="/my-profile" class="d-inline-block fw-medium">
                                    {{user.fullName }}
                                </a>
                                <span class="designation d-block text-body">
                                    {{user.email }}
                                </span>
                            </div>
                        </div>
                        <ul class="menu-body pl-0 mb-0 mt-0 list-unstyled">
                            <li class="position-relative transition fw-medium">
                                <i class="material-symbols-outlined">
                                    person
                                </i>
                                <label i18n> My Profile</label>
                                <a routerLink="/my-profile" class="link-btn d-block position-absolute"></a>
                            </li>

                            <li class="position-relative transition fw-medium">
                                <i class="material-symbols-outlined">
                                    map
                                </i>
                                <label i18n> My addresses</label>
                                <a routerLink="/account/addresses" class="link-btn d-block position-absolute"></a>
                            </li>
                            <li class="position-relative transition fw-medium">
                                <i class="material-symbols-outlined">
                                    settings
                                </i>
                                <label i18n>Settings</label>
                                <a routerLink="/settings" class="link-btn d-block position-absolute"></a>
                            </li>
                            <li class="position-relative transition fw-medium">
                                <i class="material-symbols-outlined">
                                    logout
                                </i>
                                <label i18n>Logout</label>
                                <a routerLink="/authentication/logout" class="link-btn d-block position-absolute"></a>
                            </li>
                        </ul>
                    </div>
                </mat-menu>
            </li>
        </ul>
    </div>
</header>
