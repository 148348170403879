<mat-card
    class="daxa-card total-earnings-card border-radius border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
>
    <mat-card-content>
        <div class="d-flex justify-content-between">
            <div class="title">
                <h5>
                    Total Earnings
                </h5>
                <div class="number fw-medium lh-1">
                    $23.91k
                </div>
            </div>
            <div class="icon text-center bg-warning rounded-circle text-white position-relative">
                <img src="images/icons/active-projects.svg" alt="active-projects-icon">
            </div>
        </div>
        <div class="info d-flex align-items-center justify-content-between">
            <span class="sub-title d-block text-body">
                Earnings this month
            </span>
            <span class="daxa-up-down-badge down position-relative">
                <i class="material-symbols-outlined">
                    trending_down
                </i>
                1.25%
            </span>
        </div>
    </mat-card-content>
</mat-card>