import { Injectable } from '@angular/core';
import {UserInfoInterface} from "../../core/ports/user-info.interface";
import {UserInfoAdapter} from "../adapters/http/user-info.adapter";
import {Observable} from "rxjs";
import {UserInfoModel} from "../../core/domain/models/user-info.model";
import {UserInfoResponse} from "../adapters/http/responses/user-info.response";
import {map, tap} from "rxjs/operators";
import {mapUserInfoResponseToModel} from "../mappers/user-info.mapper";
import {UpdateUserInfoRequest} from "../adapters/http/requests/user-info.request";
import {UpdateUserAvatarRequest} from "../adapters/http/requests/upload-account-avatar.request";
import {CreateFcmTokenResponse} from "../adapters/http/responses/create-fcm-token.response";
import {UserAvatarResponse} from "../adapters/http/responses/user-avatar.response";
import {ResultModel} from "../../core/domain/models/result.model";

@Injectable({
    providedIn: 'root'
})
export class UserInfoService implements UserInfoInterface {
    constructor(private userInfoAdapter: UserInfoAdapter) {}

    getUserInfo(): Observable<UserInfoModel> {
        return this.userInfoAdapter.getUserInfo().pipe(
          map((response: UserInfoResponse) => mapUserInfoResponseToModel(response))
        );
    }

    updateUserInfo(userInfo: UpdateUserInfoRequest) {
        return this.userInfoAdapter.updateUserInfo(userInfo);
    }

    updateUserAvatar(request: UpdateUserAvatarRequest): Observable<ResultModel> {
        return this.userInfoAdapter.updateUserAvatar(request).pipe(
            tap((response: UserAvatarResponse) => console.log(response)),
            map((response: UserAvatarResponse) => {
            return {
                success: response.code == '0' ? true : false
            }
        })
        )
    }
}
