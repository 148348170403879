<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Polar Charts
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Apexcharts
        </li>
        <li class="breadcrumb-item position-relative">
            Polar Charts
        </li>
    </ol>
</div>

<!-- Polar Charts -->
<div class="row">
    <div class="col-lg-6">

        <!-- Basic Polar Chart -->
        <app-basic-polar-chart />

    </div>
    <div class="col-lg-6">

        <!-- Monochrome Polar Chart -->
        <app-monochrome-polar-chart />

    </div>
</div>