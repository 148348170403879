<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Plain Input Autocomplete
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form class="example-form">
            <input
                type="text"
                placeholder="Search for a street"
                [formControl]="control"
                [matAutocomplete]="auto"
                class="example-input"
            >
            <mat-autocomplete #auto="matAutocomplete">
                @for (street of filteredStreets | async; track street) {
                    <mat-option [value]="street">{{street}}</mat-option>
                }
            </mat-autocomplete>
        </form>
    </mat-card-content>
</mat-card>