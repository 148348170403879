import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {Router, RouterLink} from '@angular/router';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import {GetUserInfoUseCase} from "../../../../application/use-cases/get-user-info.use-case";
import {UserAuthUseCase} from "../../../../application/use-cases/user-auth.use-case";

@Component({
    selector: 'app-logout',
    standalone: true,
    imports: [RouterLink, MatButtonModule],
    templateUrl: './logout.component.html',
    styleUrl: './logout.component.scss'
})
export class LogoutComponent {

    constructor(
        private userAuthUseCase: UserAuthUseCase
    ) {
        this.userAuthUseCase.logout();
    }

}
