import {Component} from '@angular/core';
import {CommonModule, NgClass, ViewportScroller} from '@angular/common';
import {RouterOutlet, Router, Event, NavigationEnd} from '@angular/router';
import {SidebarComponent} from './presentation/components/common/sidebar/sidebar.component';
import {HeaderComponent} from './presentation/components/common/header/header.component';
import {FooterComponent} from './presentation/components/common/footer/footer.component';
import {CustomizerSettingsComponent} from './presentation/components/customizer-settings/customizer-settings.component';
import {CustomizerSettingsService} from './presentation/components/customizer-settings/customizer-settings.service';
import {ToggleService} from './presentation/components/common/sidebar/toggle.service';
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../environments/environment";
import {filter} from "rxjs";
import {Store} from "@ngrx/store";
import {loadUser} from "./application/state/actions/user.actions";
import {GetListProjectUseCase} from "./application/use-cases/get-list-project.use-case";
import {ProjectModel} from "./core/domain/models/project.model";
import {
    loadNotifications,
    newNotificationReceived,
    setNewNotification
} from "./application/state/actions/notification.actions";
import {getMessaging, getToken, Messaging, onMessage, isSupported} from "@angular/fire/messaging";
import {CreateFcmTokenUseCase} from "./application/use-cases/create-fcm-token.use-case";
import {NotificationModel} from "./core/domain/models/notification.model";
import {SocketConnectUseCase} from "./application/use-cases/socket-connect.use-case";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, CommonModule, SidebarComponent, HeaderComponent, FooterComponent, CustomizerSettingsComponent, NgClass],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    // Title
    title = '202 Corp IoT Console';

    // isSidebarToggled
    isSidebarToggled = false;

    // isToggled
    isToggled = false;

    constructor(
        private messaging: Messaging,
        private store: Store,
        public router: Router,
        private toggleService: ToggleService,
        private viewportScroller: ViewportScroller,
        public themeService: CustomizerSettingsService,
        private oauthService: OAuthService,
        private getListProjectUseCase: GetListProjectUseCase,
        private createFcmTokenUseCase: CreateFcmTokenUseCase,
        private socketConnectUseCase: SocketConnectUseCase
    ) {
        console.log('AppComponent');
        this.configureOAuth();
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                // Scroll to the top after each navigation end
                console.log("url:", event.url);
                this.viewportScroller.scrollToPosition([0, 0]);
                if (event.url === "/") {
                    this.getListProjectUseCase.execute().subscribe((projects: ProjectModel[]) => {
                        if (projects.length === 0) {
                            this.router.navigate(['/starter']).then(() => {
                            }); // Nếu không có project, chuyển tới onboard
                        } else if (projects.length === 1) {
                            this.router.navigate([`/dashboard/${projects[0].id}`]).then(() => {
                            }); // Nếu có 1 project, chuyển tới dashboard
                            // this.router.navigate([`/draw-flow`]); // Nếu có 1 project, chuyển tới dashboard
                        } else {
                            this.router.navigate(['/project-list']).then(() => {
                            }); // Nếu có nhiều project, chuyển tới trang list project
                        }
                    });
                }
            }
        });
        this.toggleService.isSidebarToggled$.subscribe(isSidebarToggled => {
            this.isSidebarToggled = isSidebarToggled;
        });
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        isSupported().then((isSupported: boolean) => {
            if (!isSupported) {
                console.log("FCM is not supported.");
                return;
            }
            this.requestPermission().then(() => {
            });
        });
        this.socketConnectUseCase.execute();
    }

    private configureOAuth() {
        this.oauthService.configure(environment.oidc);
        this.oauthService.loadDiscoveryDocumentAndLogin().then(() => {
            if (!this.oauthService.hasValidAccessToken()) {
                this.oauthService.initLoginFlow();
            } else {
                this.store.dispatch(loadUser());
                this.store.dispatch(loadNotifications());
            }
        });

        // Tự động tải profile user sau khi đăng nhập
        this.oauthService.events
            .pipe(filter(e => e.type === 'token_received'))
            .subscribe(() => {
                this.oauthService.loadUserProfile().then(user => {
                    console.log('oauthService.events User: ', user);
                });
            });
    }

    private async requestPermission() {
        const messagingInstance = getMessaging();
        const fcmPermission = await Notification.requestPermission();
        if (fcmPermission === "granted") {
            const currentToken = await getToken(messagingInstance, {vapidKey: environment.firebase.vapidKey})
            if (currentToken) {
                console.log('Token received: ', currentToken);
                this.createFcmTokenUseCase.execute(currentToken).subscribe(result => {
                    console.log('create fcm result: ', result);
                })
            } else {
                console.log('No registration token available.');
            }
        } else {
            // Handle denied permission
            console.log('Notification permission not granted.');
        }

        onMessage(this.messaging, payload => {
            console.log('Message received. ', payload);
            const notification: NotificationModel = {
                id: payload.messageId || '',  // Đảm bảo id không bị undefined
                title: payload.notification?.title || '',  // Sử dụng optional chaining
                body: payload.notification?.body || '',
                channel: 'FCM',  // Nếu có dữ liệu, hoặc gán giá trị mặc định
                type: 'FCM',  // Kiểu thông báo, giá trị mặc định là 'general'
                isRead: false,  // Giả định thông báo mới chưa được đọc
                createdTime: new Date().toDateString()  // Thời gian nhận thông báo hiện tại
            };

            // Dispatch action để cập nhật trạng thái khi nhận được thông báo mới
            this.store.dispatch(newNotificationReceived({notification}));
            this.store.dispatch(setNewNotification({isNew: true}));
            setTimeout(() => {
                this.store.dispatch(setNewNotification({isNew: false}));
            }, 2000);
        });
    }
}
