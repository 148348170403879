<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Wizard
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Forms
        </li>
        <li class="breadcrumb-item position-relative">
            Wizard
        </li>
    </ol>
</div>

<!-- Horizontal Wizard -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Horizontal Wizard
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-horizontal />
    </mat-card-content>
</mat-card>

<!-- Vertical Wizard -->
<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Vertical Wizard
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-vertical />
    </mat-card-content>
</mat-card>