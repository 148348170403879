<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Dialog Launched From a Menu
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <button mat-flat-button color="warn" [matMenuTriggerFor]="menu" #menuTrigger>
            Menu
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openDialog()">
                Open dialog
            </button>
        </mat-menu>
    </mat-card-content>
</mat-card>