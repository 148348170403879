<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Products List
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            E-Commerce
        </li>
        <li class="breadcrumb-item position-relative">
            Products List
        </li>
    </ol>
</div>

<!-- Products List -->
<mat-card
    class="daxa-card products-list-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <form class="search-box position-relative">
                <i class="material-symbols-outlined">
                    search
                </i>
                <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search product..." (keyup)="applyFilter($event)" #input>
            </form>
            <ul class="info pl-0 mb-0 mt-0 list-unstyled text-body">
                <li class="d-inline-block">
                    All Products <span class="text-daxa">(48,753)</span>
                </li>
                <li class="d-inline-block">
                    Published Products <span class="text-daxa">(35,921)</span>
                </li>
                <li class="d-inline-block">
                    Drafts Products <span class="text-daxa">(12,832)</span>
                </li>
            </ul>
        </mat-card-title>
        <mat-card-subtitle>
            <a mat-button class="add-new-btn" routerLink="/ecommerce-page/create-product">
                + Add New Product
            </a>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="products-list-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox
                                (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()"
                            >
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Product ID Column -->
                    <ng-container matColumnDef="productId">
                        <th mat-header-cell *matHeaderCellDef>
                            Product ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.productId}}
                        </td>
                    </ng-container>

                    <!-- Product Column -->
                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef>
                            Product
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="info d-flex align-items-center">
                                <div class="image">
                                    <img [src]="element.product.img" alt="product-image">
                                </div>
                                <a routerLink="/ecommerce-page/product-details" class="d-block fw-medium">
                                    {{element.product.name}}
                                </a>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Category Column -->
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>
                            Category
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.category}}
                        </td>
                    </ng-container>

                    <!-- Price Column -->
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef>
                            Price
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.price}}
                        </td>
                    </ng-container>

                    <!-- Stock Quantity Column -->
                    <ng-container matColumnDef="stockQuantity">
                        <th mat-header-cell *matHeaderCellDef>
                            Stock Quantity
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.stockQuantity}}
                        </td>
                    </ng-container>

                    <!-- Date Column -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef>
                            Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.date}}
                        </td>
                    </ng-container>

                    <!-- Sales Column -->
                    <ng-container matColumnDef="sales">
                        <th mat-header-cell *matHeaderCellDef>
                            Sales
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.sales}}
                        </td>
                    </ng-container>

                    <!-- Revenue Column -->
                    <ng-container matColumnDef="revenue">
                        <th mat-header-cell *matHeaderCellDef>
                            Revenue
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.revenue}}
                        </td>
                    </ng-container>

                    <!-- Rating Column -->
                    <ng-container matColumnDef="rating">
                        <th mat-header-cell *matHeaderCellDef>
                            Rating
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.rating}}
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="action-info">
                                <a mat-button routerLink="/ecommerce-page/product-details" matTooltip="View Product" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.view}}
                                    </i>
                                </a>
                                <a mat-button routerLink="/ecommerce-page/edit-product" matTooltip="Edit Product" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.edit}}
                                    </i>
                                </a>
                                <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.delete}}
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>