<div
    class="connections-card"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <h4>
        Connected Accounts
    </h4>
    <ul class="pl-0 mb-0 mt-0 list-unstyled">
        <li class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <img src="images/icons/google.png" alt="google">
                </div>
                <div class="title">
                    <span class="fw-medium d-block">
                        Google
                    </span>
                    <span class="d-block text-body">
                        Calendar and Contacts
                    </span>
                </div>
            </div>
            <button mat-button class="p-0 h-auto text-daxa">
                Click to Disconnect
            </button>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <img src="images/icons/slack.png" alt="slack">
                </div>
                <div class="title">
                    <span class="fw-medium d-block">
                        Slack
                    </span>
                    <span class="d-block text-body">
                        Communications
                    </span>
                </div>
            </div>
            <button mat-button class="p-0 h-auto text-daxa">
                Click to Disconnect
            </button>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <img src="images/icons/github.png" alt="github">
                </div>
                <div class="title">
                    <span class="fw-medium d-block">
                        Github
                    </span>
                    <span class="d-block text-body">
                        Manage your Git repositories
                    </span>
                </div>
            </div>
            <button mat-button class="p-0 h-auto text-daxa">
                Click to Disconnect
            </button>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <img src="images/icons/mailchimp.png" alt="mailchimp">
                </div>
                <div class="title">
                    <span class="fw-medium d-block">
                        Mailchimp
                    </span>
                    <span class="d-block text-body">
                        Email marketing service
                    </span>
                </div>
            </div>
            <button mat-button class="p-0 h-auto text-daxa">
                Click to Disconnect
            </button>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <img src="images/icons/asana.png" alt="asana">
                </div>
                <div class="title">
                    <span class="fw-medium d-block">
                        Asana
                    </span>
                    <span class="d-block text-body">
                        Communication
                    </span>
                </div>
            </div>
            <button mat-button class="p-0 h-auto text-daxa">
                Click to Disconnect
            </button>
        </li>
    </ul>
    <mat-divider></mat-divider>
    <h4>
        Social Accounts
    </h4>
    <ul class="pl-0 mb-0 mt-0 list-unstyled">
        <li class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <img src="images/icons/facebook.png" alt="facebook">
                </div>
                <div class="title">
                    <span class="fw-medium d-block">
                        Facebook
                    </span>
                </div>
            </div>
            <button mat-button class="p-0 h-auto text-daxa">
                Click to Disconnect
            </button>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <img src="images/icons/twitter.png" alt="twitter">
                </div>
                <div class="title">
                    <span class="fw-medium d-block">
                        Twitter
                    </span>
                </div>
            </div>
            <button mat-button class="p-0 h-auto text-daxa">
                Click to Disconnect
            </button>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <img src="images/icons/instagram.png" alt="instagram">
                </div>
                <div class="title">
                    <span class="fw-medium d-block">
                        Instagram
                    </span>
                </div>
            </div>
            <button mat-button class="p-0 h-auto text-daxa">
                Click to Disconnect
            </button>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <img src="images/icons/dribbble.png" alt="dribbble">
                </div>
                <div class="title">
                    <span class="fw-medium d-block">
                        Dribbble
                    </span>
                </div>
            </div>
            <button mat-button class="p-0 h-auto text-daxa">
                Click to Disconnect
            </button>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="icon">
                    <img src="images/icons/behance.png" alt="behance">
                </div>
                <div class="title">
                    <span class="fw-medium d-block">
                        Behance
                    </span>
                </div>
            </div>
            <button mat-button class="p-0 h-auto text-daxa">
                Click to Disconnect
            </button>
        </li>
    </ul>
</div>