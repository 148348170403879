<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Require an Autocomplete Option to be Selected
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>
            Control value: <span class="text-black fw-medium">{{myControl.value || 'empty'}}</span>
        </p>
        <form>
            <mat-form-field>
                <mat-label>Number</mat-label>
                <input
                    #input
                    type="text"
                    placeholder="Pick one"
                    matInput
                    [formControl]="myControl"
                    [matAutocomplete]="auto"
                    (input)="filter()"
                    (focus)="filter()"
                >
                <mat-autocomplete requireSelection #auto="matAutocomplete">
                    @for (option of filteredOptions; track option) {
                        <mat-option [value]="option">{{option}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </mat-card-content>
</mat-card>