<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Deals
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            CRM
        </li>
        <li class="breadcrumb-item position-relative">
            Deals
        </li>
    </ol>
</div>

<!-- Deals -->
<mat-card
    class="daxa-card deals-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <form class="search-box position-relative">
                <i class="material-symbols-outlined">
                    search
                </i>
                <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search deal..." (keyup)="applyFilter($event)" #input>
            </form>
        </mat-card-title>
        <mat-card-subtitle>
            <a mat-button class="add-new-btn" routerLink="/crm-page/create-deal">
                + Add New Deal
            </a>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="deals-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox
                                (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()"
                            >
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Deal ID Column -->
                    <ng-container matColumnDef="dealID">
                        <th mat-header-cell *matHeaderCellDef>
                            Deal ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.dealID}}
                        </td>
                    </ng-container>

                    <!-- Company Name Column -->
                    <ng-container matColumnDef="companyName">
                        <th mat-header-cell *matHeaderCellDef>
                            Company Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.companyName}}
                        </td>
                    </ng-container>

                    <!-- Contact Person Column -->
                    <ng-container matColumnDef="contactPerson">
                        <th mat-header-cell *matHeaderCellDef>
                            Contact Person
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black">
                            {{element.contactPerson}}
                        </td>
                    </ng-container>

                    <!-- Amount Column -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>
                            Amount
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.amount}}
                        </td>
                    </ng-container>

                    <!-- Stage Column -->
                    <ng-container matColumnDef="stage">
                        <th mat-header-cell *matHeaderCellDef>
                            Stage
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.stage}}
                        </td>
                    </ng-container>

                    <!-- Probability Column -->
                    <ng-container matColumnDef="probability">
                        <th mat-header-cell *matHeaderCellDef>
                            Probability
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.probability}}
                        </td>
                    </ng-container>

                    <!-- Close Date Column -->
                    <ng-container matColumnDef="closeDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Close Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.closeDate}}
                        </td>
                    </ng-container>

                    <!-- Created At Column -->
                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef>
                            Created At
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.createdAt}}
                        </td>
                    </ng-container>

                    <!-- Due Date Column -->
                    <ng-container matColumnDef="dueDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Due Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.dueDate}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>