<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Read
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Apps
        </li>
        <li class="breadcrumb-item position-relative">
            Email
        </li>
        <li class="breadcrumb-item position-relative">
            Read
        </li>
    </ol>
</div>

<!-- Read -->
<div class="row">
    <div class="col-lg-3 col-md-12">
        <app-sidebar />
    </div>
    <div class="col-lg-9 col-md-12">
        <mat-card
            class="daxa-card email-read-card mb-25 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Exclusive Product Offer on Facebook - Limited Time Only!
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <div class="info-buttons-list d-flex align-items-center">
                        <button mat-button>
                            <i class="material-symbols-outlined">
                                refresh
                            </i>
                        </button>
                        <button mat-button>
                            <i class="material-symbols-outlined">
                                print
                            </i>
                        </button>
                        <button mat-button>
                            <i class="material-symbols-outlined">
                                report_gmailerrorred
                            </i>
                        </button>
                        <button mat-button>
                            <i class="material-symbols-outlined">
                                delete
                            </i>
                        </button>
                        <button type="button" mat-button [matMenuTriggerFor]="emailHeaderMenu">
                            <i class="material-symbols-outlined">
                                more_horiz
                            </i>
                        </button>
                        <mat-menu #emailHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                            <button mat-menu-item>
                                Recent
                            </button>
                            <button mat-menu-item>
                                Unread
                            </button>
                            <button mat-menu-item>
                                Mark All Read
                            </button>
                            <button mat-menu-item>
                                Spam
                            </button>
                            <button mat-menu-item>
                                Delete All
                            </button>
                        </mat-menu>
                    </div>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="info d-md-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <div class="img">
                            <img src="images/users/user1.jpg" class="rounded-circle" alt="user-image">
                        </div>
                        <div class="title">
                            <span class="d-block fw-medium">
                                Micheal Collins
                            </span>
                            <span class="d-block text-body">
                                From: daxa&#64;gmail.com
                            </span>
                        </div>
                    </div>
                    <span class="d-block text-body">
                        20 Nov, 2024
                    </span>
                </div>
                <div class="content">
                    <span class="title d-block fw-medium">
                        Hello Micheal Collins
                    </span>
                    <p>
                        Pellentesque viverra lorem malesuada nunc tristique sapien. Imperdiet sit hendrerit tincidunt bibendum donec adipiscing. Tellus non morbi nascetur cursus etiam facilisis mi. Dolor aliquam sed amet aliquam venenatis. Ac viverra interdum tortor enim. Molestie tincidunt ut consequat a urna tortor. Vitae velit ac nisl velit mauris placerat nisi placerat.
                    </p>
                    <span class="title d-block fw-medium">
                        Limited-Time Offer: Daxa at a Special Price!
                    </span>
                    <p>
                        Pellentesque viverra lorem malesuada nunc tristique sapien. Imperdiet sit hendrerit tincidunt bibendum donec adipiscing. Tellus non morbi nascetur cursus etiam facilisis mi. Dolor aliquam sed amet aliquam venenatis. Ac viverra interdum tortor enim. Molestie tincidunt ut consequat a urna tortor. Vitae velit ac nisl velit mauris placerat nisi placerat.
                    </p>
                    <span class="title d-block fw-medium">
                        Here's what you get:
                    </span>
                    <ol>
                        <li class="text-body">
                            Mperdiet sit hendrerit tincidunt bibendum donec adipiscing.
                        </li>
                        <li class="text-body">
                            Tellus non morbi nascetur cursus etiam facilisis mi. Dolor aliquam sed amet aliquam venenatis.
                        </li>
                        <li class="text-body">
                            Molestie tincidunt ut consequat a urna tortor. Vitae velit ac nisl velit mauris placerat nisi placerat.
                        </li>
                    </ol>
                    <span class="title d-block fw-medium">
                        How to Redeem
                    </span>
                    <p>
                        Pellentesque viverra lorem malesuada nunc tristique sapien. Imperdiet sit hendrerit tincidunt bibendum donec adipiscing. Tellus non morbi nascetur cursus etiam facilisis mi. Dolor aliquam sed amet aliquam venenatis. Ac viverra interdum tortor enim. Molestie tincidunt ut consequat a urna tortor. Vitae velit ac nisl velit mauris placerat nisi placerat.
                    </p>
                    <p>
                        Thank you for being a part of our community!
                    </p>
                    <span class="title d-block fw-medium">
                        Best regards,
                    </span>
                    <p>
                        Robert Fairweather
                    </p>
                </div>
                <div class="btn-box">
                    <button mat-button>
                        Reply
                    </button>
                    <button mat-button>
                        Forward
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>