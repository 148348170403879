import {Component, Inject} from '@angular/core';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatOption, MatSelect} from "@angular/material/select";
import {CommonModule} from "@angular/common";
import {SimpleFuComponent} from "../../../../components/forms/file-uploader/simple-fu/simple-fu.component";
import {FileUploadControl, FileUploadModule, FileUploadValidators} from "@iplab/ngx-file-upload";
import {
    UpdateUserAvatarRequest
} from "../../../../../infrastructure/adapters/http/requests/upload-account-avatar.request";
import {UpdateUserAvatarUseCase} from "../../../../../application/use-cases/update-user-avatar.use-case";
import {AlertDialogComponent} from "../../../../features/components/alert-dialog/alert-dialog.component";

export interface DialogData {
    id: number;
}

@Component({

    selector: 'avatar-dialog-component',
    templateUrl: './avatar-dialog.component.html',
    styleUrl: './avatar-dialog.component.scss',
    standalone: true,
    imports: [
        FileUploadModule,
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatSelect,
        MatOption,
        ReactiveFormsModule,
        SimpleFuComponent,
    ]
})
export class AvatarDialogComponent {
    avatarForm: FormGroup;
    updateAccountAvatarRequest: UpdateUserAvatarRequest;
    isLoading: boolean = true;
    error: string | null = null;

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AvatarDialogComponent>,
        public updateUserAvatarUseCase : UpdateUserAvatarUseCase,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        console.log('DialogData', data);
        this.avatarForm = new FormGroup({
            avatar: new FormControl(null, FileUploadValidators.filesLimit(1))
        });
    }
    onSubmitAvatar() {
        if (this.avatarForm.valid) {
            this.updateAccountAvatarRequest = {
                avatar: this.avatarForm.get('avatar')?.value[0]
            } // Assuming the form control value is an array of files
            this.updateUserAvatarUseCase.execute(this.updateAccountAvatarRequest).subscribe({
                next: (data) => {
                    this.dialogRef.close();
                    this.showSuccessMessage();
                },
                error: (error) => {
                    this.error = 'Failed to load addresses';
                    this.isLoading = false;
                    console.error('Error:', error);
                }
            });
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    private showSuccessMessage() {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '750px',
            data: {
                title: 'Thông báo',
                message: 'Cập nhật avatar thành công. ',
                confirmButtonText: 'Đóng',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                window.location.reload();
            }
        });
    }
}