<mat-card
    class="daxa-card active-courses-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
>
    <mat-card-content>
        <div class="title">
            <h5>
                Active Courses
            </h5>
            <div class="number fw-medium lh-1">
                1.5K+
            </div>
        </div>
        <div class="icon text-center rounded-circle text-white position-relative">
            <img src="images/icons/active-learning.svg" alt="active-learning-icon">
        </div>
        <div class="info d-flex align-items-center justify-content-between">
            <span class="sub-title d-block text-body">
                This Month
            </span>
            <span class="daxa-up-down-badge position-relative">
                <i class="material-symbols-outlined">
                    trending_up
                </i>
                2.15%
            </span>
        </div>
    </mat-card-content>
</mat-card>