<mat-form-field>
    <mat-label>Choose one</mat-label>
    <mat-select [formControl]="selected" [errorStateMatcher]="matcher">
        <mat-option>Clear</mat-option>
        <mat-option value="valid">Valid option</mat-option>
        <mat-option value="invalid">Invalid option</mat-option>
    </mat-select>
    <mat-hint>Errors appear instantly!</mat-hint>
    @if (selected.hasError('required')) {
        <mat-error>You must make a selection</mat-error>
    }
    @if (selected.hasError('pattern') && !selected.hasError('required')) {
        <mat-error>Your selection is invalid</mat-error>
    }
</mat-form-field>