import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {Router, RouterLink} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProjectModel} from "../../../core/domain/models/project.model";
import {CreateProjectUseCase} from "../../../application/use-cases/create-project.use-case";

@Component({
    selector: 'app-reset-password',
    standalone: true,
    imports: [RouterLink, MatFormFieldModule, MatInputModule, MatButtonModule, MatCheckboxModule, ReactiveFormsModule, FormsModule],
    templateUrl: './onboard.component.html',
    styleUrl: './onboard.component.scss'
})
export class OnboardComponent {

    name: string = '';
    desc: string = '';

    onSubmit(): void {
        const requestModel: ProjectModel = {
            id: "",
            name: this.name,
            desc: this.desc
        };

        this.createProjectUseCase.execute(requestModel).subscribe((newProject) => {
            this.router.navigate([`/dashboard/${newProject.id}`]); // Điều hướng tới dashboard sau khi tạo thành công
        });
    }

    constructor(
        private createProjectUseCase: CreateProjectUseCase,
        private router: Router
    ) {

    }

}
