
<!-- My Profile -->
<div class="row">
    <div class="col-12 col-md-12 col-lg-12 ">

        <app-update-profile />

    </div>

</div>
<div class="row">

</div>
