<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Cart
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            E-Commerce
        </li>
        <li class="breadcrumb-item position-relative">
            Cart
        </li>
    </ol>
</div>

<!-- Cart -->
<div class="row">
    <div class="col-lg-8">
        <mat-card
            class="daxa-card cart-card mb-25 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Your Order
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <h5 class="mb-0">
                        Customer ID : 357951
                    </h5>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="recent-orders-table table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col" class="fw-medium text-black">
                                    Order ID
                                </th>
                                <th scope="col" class="fw-medium text-black">
                                    Product
                                </th>
                                <th scope="col" class="fw-medium text-black">
                                    Quantity
                                </th>
                                <th scope="col" class="fw-medium text-black">
                                    Price
                                </th>
                                <th scope="col" class="fw-medium text-black">
                                    Total
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-body">
                                    #ARP-1217
                                </td>
                                <td class="text-body">
                                    <div class="info d-flex align-items-center">
                                        <div class="image">
                                            <img src="images/products/product6.png" alt="product-image">
                                        </div>
                                        <a routerLink="/ecommerce-page/product-details" class="d-block fw-medium">
                                            Hand Watch
                                        </a>
                                    </div>
                                </td>
                                <td class="text-body">
                                    <app-quantity-counter [initialValue]="1" />
                                </td>
                                <td class="text-body">
                                    $80.00
                                </td>
                                <td class="text-body">
                                    $80.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-body">
                                    #ARP-1423
                                </td>
                                <td class="text-body">
                                    <div class="info d-flex align-items-center">
                                        <div class="image">
                                            <img src="images/products/product8.png" alt="product-image">
                                        </div>
                                        <a routerLink="/ecommerce-page/product-details" class="d-block fw-medium">
                                            Sports Shoes
                                        </a>
                                    </div>
                                </td>
                                <td class="text-body">
                                    <app-quantity-counter [initialValue]="3" />
                                </td>
                                <td class="text-body">
                                    $150.00
                                </td>
                                <td class="text-body">
                                    $450.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-body">
                                    #ARP-4312
                                </td>
                                <td class="text-body">
                                    <div class="info d-flex align-items-center">
                                        <div class="image">
                                            <img src="images/products/product3.png" alt="product-image">
                                        </div>
                                        <a routerLink="/ecommerce-page/product-details" class="d-block fw-medium">
                                            Gaming Laptop
                                        </a>
                                    </div>
                                </td>
                                <td class="text-body">
                                    <app-quantity-counter [initialValue]="1" />
                                </td>
                                <td class="text-body">
                                    $750.00
                                </td>
                                <td class="text-body">
                                    $750.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-body">
                                    #ARP-3124
                                </td>
                                <td class="text-body">
                                    <div class="info d-flex align-items-center">
                                        <div class="image">
                                            <img src="images/products/product9.png" alt="product-image">
                                        </div>
                                        <a routerLink="/ecommerce-page/product-details" class="d-block fw-medium">
                                            Woman Handbag
                                        </a>
                                    </div>
                                </td>
                                <td class="text-body">
                                    <app-quantity-counter [initialValue]="5" />
                                </td>
                                <td class="text-body">
                                    $15.00
                                </td>
                                <td class="text-body">
                                    $75.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-body">
                                    #ARP-1234
                                </td>
                                <td class="text-body">
                                    <div class="info d-flex align-items-center">
                                        <div class="image">
                                            <img src="images/products/product10.png" alt="product-image">
                                        </div>
                                        <a routerLink="/ecommerce-page/product-details" class="d-block fw-medium">
                                            Luxury Rendering
                                        </a>
                                    </div>
                                </td>
                                <td class="text-body">
                                    <app-quantity-counter [initialValue]="2" />
                                </td>
                                <td class="text-body">
                                    $25.00
                                </td>
                                <td class="text-body">
                                    $50.00
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="coupon-code position-relative">
                    <mat-form-field>
                        <mat-label>
                            Coupon code
                        </mat-label>
                        <input matInput placeholder="E.g. #ARP1217">
                    </mat-form-field>
                    <button mat-button>
                        Apply
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4">
        <mat-card
            class="daxa-card order-summary-card mb-25 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Order Summary
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="order-summary-table table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col" class="fw-medium text-black">
                                    Description
                                </th>
                                <th scope="col" class="fw-medium text-black">
                                    Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-body">
                                    Grand Total :
                                </td>
                                <td class="text-body">
                                    $1,105.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-body">
                                    Discount :
                                </td>
                                <td class="text-body">
                                    -$105.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-body">
                                    Tax :
                                </td>
                                <td class="text-body">
                                    $75.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-black">
                                    Subtotal :
                                </td>
                                <td class="text-body">
                                    $1,000.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-body">
                                    Shipping Charge :
                                </td>
                                <td class="text-body">
                                    $80.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-body">
                                    Coupon Charge :
                                </td>
                                <td class="text-body">
                                    $20.00
                                </td>
                            </tr>
                            <tr>
                                <td class="text-black">
                                    Total :
                                </td>
                                <td class="text-body">
                                    $900.00
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h5>
                    Add A Note
                </h5>
                <form>
                    <mat-form-field class="textarea">
                        <mat-label>
                            Write here....
                        </mat-label>
                        <textarea matInput placeholder="E.g. It makes me feel..."></textarea>
                    </mat-form-field>
                    <button mat-button>
                        Proceed To Checkout
                    </button>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>