<mat-card class="daxa-card total-revenue-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-content>
        <div class="d-flex justify-content-between">
            <div class="title">
                <h5>
                    Total Revenue
                </h5>
                <div class="number fw-medium lh-1">
                    $15,278
                </div>
            </div>
            <div class="icon text-center bg-warning rounded-circle text-white position-relative">
                <i class="material-symbols-outlined">
                    attach_money
                </i>
            </div>
        </div>
        <div class="info d-flex align-items-center justify-content-between">
            <span class="sub-title d-block text-body">
                Revenue increases this month
            </span>
            <span class="daxa-up-down-badge position-relative">
                <i class="material-symbols-outlined">
                    trending_up
                </i>
                3.15%
            </span>
        </div>
    </mat-card-content>
</mat-card>