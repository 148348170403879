// services/my-profile.service.ts
import {Inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserInfoInterface } from '../ports/user-info.interface';
import {MyProfileModel} from "../domain/models/my-profile.model";
import {mapUserInfoToMyProfile} from "../domain/mappers/my-profile.mapper";
import {USER_INFO_TOKEN} from "../ports/user-info.token";


@Injectable({
  providedIn: 'root',
})
export class MyProfileService {
  constructor(@Inject(USER_INFO_TOKEN)  private userInfoPort: UserInfoInterface) {}

  getMyProfile(): Observable<MyProfileModel> {
    return this.userInfoPort.getUserInfo().pipe(
      // Sử dụng hàm mapper để chuyển đổi từ UserInfoModel sang MyProfileModel
      map(userInfo => mapUserInfoToMyProfile(userInfo))
    );
  }
}
