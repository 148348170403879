<form [formGroup]="addressForm">
    <h1 mat-dialog-title>
        Address
    </h1>
    <div mat-dialog-content>
        <div class="row mb-25">
            <div class="col-sm-12 col-12">
                <mat-form-field>
                    <mat-label>
                        Enter address
                    </mat-label>
                    <input matInput value="" formControlName="addressLevel1" >
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-25">
            <div class="col-sm-4 col-12">
                <mat-form-field>
                    <mat-label>Select country</mat-label>
                    <mat-select (selectionChange)="onSelectionChangeCountry()" formControlName="countryId">
                        <mat-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-4 col-12">
                <mat-form-field>
                    <mat-label>Select city</mat-label>
                    <mat-select (selectionChange)="onSelectionChangeCity()" formControlName="provinceId">
                        <mat-option *ngFor="let city of cities" [value]="city.id">{{city.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-4 col-12">
                <mat-form-field>
                    <mat-label>Select city</mat-label>
                    <mat-select formControlName="districtId">
                        <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

    </div>
</form>

<div mat-dialog-actions class="mt-0">
    <button mat-flat-button class="daxa" (click)="onSubmitAddress()">
        Ok
    </button>
</div>