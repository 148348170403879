<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Datepicker Palette Colors
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field color="accent">
                    <mat-label>Inherited calendar color</mat-label>
                    <input matInput [matDatepicker]="picker1">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field color="accent">
                    <mat-label>Custom calendar color</mat-label>
                    <input matInput [matDatepicker]="picker2">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 color="primary"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </mat-card-content>
</mat-card>