<mat-card
    class="daxa-card total-members-card border-radius border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
>
    <mat-card-content>
        <div class="d-md-flex align-items-center justify-content-between">
            <div class="title">
                <h5>
                    Total Members
                </h5>
                <div class="number fw-medium lh-1">
                    75
                </div>
            </div>
            <div class="users d-flex align-items-center">
                <img src="images/users/user12.jpg" alt="user-image">
                <img src="images/users/user13.jpg" alt="user-image">
                <img src="images/users/user14.jpg" alt="user-image">
                <img src="images/users/user15.jpg" alt="user-image">
                <img src="images/users/user16.jpg" alt="user-image">
                <div class="fw-medium bg-primary fw-medium text-white rounded-circle">
                    +50
                </div>
            </div>
        </div>
        <div class="info d-flex align-items-center justify-content-between">
            <span class="sub-title d-block text-body">
                Projects engagement 50%
            </span>
            <!-- <span class="daxa-up-down-badge position-relative">
                <i class="material-symbols-outlined">
                    trending_up
                </i>
                0.99%
            </span> -->
        </div>
    </mat-card-content>
</mat-card>