<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Stacked Chips
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-chip-listbox class="mat-mdc-chip-set-stacked" aria-label="Color selection">
            @for (chip of availableColors; track chip) {
                <mat-chip-option [color]="chip.color">{{chip.name}}</mat-chip-option>
            }
        </mat-chip-listbox>
    </mat-card-content>
</mat-card>