<mat-card class="daxa-card time-spendings-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Time Spending's
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <apx-chart
            [series]="chartOptions.series!"
            [chart]="chartOptions.chart!"
            [dataLabels]="chartOptions.dataLabels!"
            [labels]="chartOptions.labels!"
            [stroke]="chartOptions.stroke!"
            [colors]="chartOptions.colors!"
            [tooltip]="chartOptions.tooltip!"
            [legend]="chartOptions.legend!"
        ></apx-chart>
        <div class="d-flex align-items-center justify-content-between">
            <span class="d-block text-body">
                Weekly report
            </span>
            <span class="daxa-up-down-badge position-relative">
                <i class="material-symbols-outlined">
                    trending_up
                </i>
                35.5%
            </span>
        </div>
    </mat-card-content>
</mat-card>