<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Data Table
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Tables
        </li>
        <li class="breadcrumb-item position-relative">
            Data Table
        </li>
    </ol>
</div>

<!-- Data Table -->
<div class="row">
    <div class="col-xxxl-6">

        <!-- Top Selling Products -->
        <app-top-selling-products />

        <!-- Transactions History -->
        <app-transactions-history />

        <!-- Recent Leads -->
        <app-recent-leads />

        <!-- All Projects -->
        <app-all-projects />

        <!-- Top Instructors -->
        <app-top-instructors />

        <!-- Courses -->
        <app-courses />

        <!-- Recent Customer Rating -->
        <app-recent-customer-rating />

    </div>
    <div class="col-xxxl-6">

        <!-- Recent Orders -->
        <app-recent-orders />

        <!-- Top Customers -->
        <app-top-customers />

        <!-- To Do List -->
        <app-to-do-list />

        <!-- Courses Categories -->
        <app-courses-categories />

        <!-- Student's Progress -->
        <app-students-progress />

        <!-- SLA Monitoring -->
        <app-sla-monitoring />

        <!-- Performance of Agents -->
        <app-performance-of-agents />
        
    </div>
</div>