<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Carousels
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Carousels
        </li>
    </ol>
</div>

<!-- Carousels -->
<div class="row">
    <div class="col-lg-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Slides Only
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <owl-carousel-o [options]="imageSlides">
                    <ng-template carouselSlide>
                        <img src="images/cards/card5.jpg" alt="card">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="images/cards/card6.jpg" alt="card">
                    </ng-template>
                </owl-carousel-o>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        With Dots Controls
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <owl-carousel-o [options]="imageSlides2">
                    <ng-template carouselSlide>
                        <img src="images/cards/card6.jpg" alt="card">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="images/cards/card5.jpg" alt="card">
                    </ng-template>
                </owl-carousel-o>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        With Navs Controls
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <owl-carousel-o [options]="imageSlides3">
                    <ng-template carouselSlide>
                        <img src="images/cards/card6.jpg" alt="card">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="images/cards/card5.jpg" alt="card">
                    </ng-template>
                </owl-carousel-o>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-12">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Responsive Breakpoints
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <owl-carousel-o [options]="imageSlides4">
                    <ng-template carouselSlide>
                        <img src="images/cards/card6.jpg" alt="card">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="images/cards/card5.jpg" alt="card">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="images/cards/card6.jpg" alt="card">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="images/cards/card5.jpg" alt="card">
                    </ng-template>
                    <ng-template carouselSlide>
                        <img src="images/cards/card6.jpg" alt="card">
                    </ng-template>
                </owl-carousel-o>
            </mat-card-content>
        </mat-card>
    </div>
</div>