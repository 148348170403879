import {createReducer, on} from '@ngrx/store';
import {NotificationModel} from "../../../core/domain/models/notification.model";
import {
  loadNotifications,
  loadNotificationsFailure,
  loadNotificationsSuccess, setNewNotification,
  setNotificationCount
} from "../actions/notification.actions";

export interface NotificationState {
  notifications: NotificationModel[] | [];
  notificationCount: number | string;
  newNotification: boolean;
  loading: boolean;
  error: any;
}

export const initialState: NotificationState = {
  notifications: [],
  notificationCount: 0,
  newNotification: false,
  loading: false,
  error: null,
};

export const notificationReducer = createReducer(
  initialState,
  on(loadNotifications, (state) => ({...state, loading: true})),
  on(loadNotificationsSuccess, (state, {notifications}) => ({
    ...state,
    loading: false,
    notifications,
    notificationCount: notifications.length
  })),
  on(loadNotificationsFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error
  })),
  on(setNotificationCount, (state, {count}) => ({
    ...state,
    notificationCount: count
  })),
  on(setNewNotification, (state, {isNew}) => ({
    ...state,
    newNotification: isNew
  }))
);
