export const series = {
    tickets: [
        47, 45, 74, 32, 56, 31, 44, 33, 45, 19
    ],
    dates: [
        "10 Mar 2024",
        "11 Mar 2024",
        "12 Mar 2024",
        "13 Mar 2024",
        "14 Mar 2024",
        "15 Mar 2024",
        "16 Mar 2024",
        "17 Mar 2024",
        "18 Mar 2024",
        "19 Mar 2024"
    ]
};