<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        RadialBar Charts
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Apexcharts
        </li>
        <li class="breadcrumb-item position-relative">
            RadialBar Charts
        </li>
    </ol>
</div>

<!-- RadialBar Charts -->
<div class="row">
    <div class="col-lg-6 col-xxxl-4">

        <!-- Basic RadialBar Chart -->
        <app-basic-radialbar-chart />

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Multiple RadialBar Chart -->
        <app-multiple-radialbar-chart />

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Custom Angle Circle RadialBar Chart -->
        <app-custom-angle-circle-radialbar-chart />

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Gradient RadialBar Chart -->
        <app-gradient-radialbar-chart />

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Strocked Circular Gauge RadialBar Chart -->
        <app-strocked-circular-gauge-radialbar-chart />

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Semi Circular Gauge RadialBar Chart -->
        <app-semi-circular-gauge-radialbar-chart />

    </div>
</div>