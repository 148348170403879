<mat-card class="daxa-card profile-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Profile Information
            </h5>
        </mat-card-title>
    </mat-card-header>
    <form [formGroup]="userProfileForm" (ngSubmit)="onUpdateProfile()">
        <mat-card-content>
            <div class="row">
                <div class="col-sm-6">
                    <div class="mb-25">
                        <mat-form-field>
                            <mat-label>
                                User name
                            </mat-label>
                            <input matInput type="email" id="username" formControlName="username">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-25">
                        <mat-form-field>
                            <mat-label>
                                Email
                            </mat-label>
                            <input matInput type="email" id="email" formControlName="email">
                        </mat-form-field>
                        <div class="error text-danger" *ngIf="userProfileForm.get('email')!.hasError('required') && userProfileForm.get('email')!.touched">
                            Email is required.
                        </div>
                        <div class="error text-danger" *ngIf="userProfileForm.get('email')!.hasError('email') && userProfileForm.get('email')!.touched">
                            Please enter a valid email address.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-25">
                        <mat-form-field>
                            <mat-label>
                                First Name
                            </mat-label>
                            <input matInput type="text" id="firstName" formControlName="firstName">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-25">
                        <mat-form-field>
                            <mat-label>
                                Last Name
                            </mat-label>
                            <input matInput type="text" id="lastName" formControlName="lastName">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-25">
                        <mat-form-field appearance="fill">
                            <mat-label>Birthday: YYYY-MM-DD</mat-label>
                            <input matInput formControlName="birthDay" [matDatepicker]="pickerBirthDay">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerBirthDay"></mat-datepicker-toggle>
                            <mat-datepicker #pickerBirthDay></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-25">
                        <mat-form-field>
                            <mat-label>
                                Phone number
                            </mat-label>
                            <input matInput type="text" id="mobile" formControlName="mobile">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-25">
                        <mat-form-field>
                            <mat-label>Gender</mat-label>
                            <mat-select formControlName="gender" >
                                <mat-option value="MALE">MALE</mat-option>
                                <mat-option value="FEMALE">FEMALE</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="btn-box">
                <button mat-button (click)="onUpdateProfile()">
                    Save
                </button>
            </div>
        </mat-card-content>
    </form>

</mat-card>
