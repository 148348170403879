<div class="table-responsive">
    <mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="name">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>
    
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
        
    </mat-table>
</div>