<mat-card
    class="daxa-card running-projects-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Running Projects
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Month
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="project-item">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <span class="title d-block fw-medium">
                        Hotel Management System
                    </span>
                    <span class="deadline d-block text-body">
                        Deadline: Dec 15, 2024
                    </span>
                </div>
                <div class="col-md-6">
                    <mat-progress-bar mode="determinate" value="70"></mat-progress-bar>
                    <span class="d-block text-body text-right">
                        <span class="text-daxa">70%</span> of the total project is complete
                    </span>
                </div>
            </div>
        </div>
        <div class="project-item">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <span class="title d-block fw-medium">
                        Engineering Lite Touch
                    </span>
                    <span class="deadline d-block text-body">
                        Deadline: Dec 20, 2024
                    </span>
                </div>
                <div class="col-md-6">
                    <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
                    <span class="d-block text-body text-right">
                        <span class="text-daxa">50%</span> of the total project is complete
                    </span>
                </div>
            </div>
        </div>
        <div class="project-item">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <span class="title d-block fw-medium">
                        Project Monitoring
                    </span>
                    <span class="deadline d-block text-body">
                        Deadline: Dec 30, 2024
                    </span>
                </div>
                <div class="col-md-6">
                    <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
                    <span class="d-block text-body text-right">
                        <span class="text-daxa">40%</span> of the total project is complete
                    </span>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>