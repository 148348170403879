import {Component, Inject} from '@angular/core';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import {MatOption, MatSelect} from "@angular/material/select";
import {CountryModel} from "../../../../core/domain/models/country.model";
import {GetListCountryUseCase} from "../../../../application/use-cases/get-list-country.user-case";
import {CommonModule} from "@angular/common";
import {GetListCityUseCase} from "../../../../application/use-cases/get-list-city.user-case";
import {GetListCityRequest} from "../../../../infrastructure/adapters/http/requests/get-list-city.request";
import {CityModel} from "../../../../core/domain/models/city.model";
import {GetListDistrictUseCase} from "../../../../application/use-cases/get-list-district.user-case";
import {GetListDistrictRequest} from "../../../../infrastructure/adapters/http/requests/get-list-district.request";
import {CreateAddressRequest} from "../../../../infrastructure/adapters/http/requests/create-address.request";
import {CreateAddressUseCase} from "../../../../application/use-cases/create-address.use-case";
import {DistrictModel} from "../../../../core/domain/models/district.model";
import {GetAddressInfoUseCase} from "../../../../application/use-cases/get-address-info.use-case";
import {mapAddressModelToCreateAddressInfo} from "../../../../infrastructure/mappers/address.mapper";
import {UpdateAddressUseCase} from "../../../../application/use-cases/update-address.use-case";

export interface DialogData {
    id: number;
}

// Dialog Overview Example Dialog
@Component({
    selector: 'address-dialog-update-component',
    templateUrl: 'address-dialog-update.component.html',
    styleUrl: './address-dialog-update.component.scss',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatSelect,
        MatOption,
        ReactiveFormsModule,
    ]
})
export class AddressDialogUpdateComponent {
    updateAddressRequest: CreateAddressRequest;
    addressForm: FormGroup;
    isLoading: boolean = true;
    error: string | null = null;
    countries: CountryModel[] = [];
    cities: CityModel[] = [];
    districts: DistrictModel[] = [];
    requestCity : GetListCityRequest = {
        countryId: '',
        page: 0,
        size: 100
    }
    requestDistrict : GetListDistrictRequest = {
        countryId: '',
        cityId: '',
        page: 0,
        size: 100
    }

    constructor(
        public dialogRef: MatDialogRef<AddressDialogUpdateComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public getListCountryUseCase: GetListCountryUseCase,
        public getListCityUseCase: GetListCityUseCase,
        public getListDistrictUseCase: GetListDistrictUseCase,
        public updateAddressUseCase : UpdateAddressUseCase,
        public getAddressInfoUseCase: GetAddressInfoUseCase
    ) {

        this.addressForm = new FormGroup({
            countryId: new FormControl('', Validators.required),
            provinceId: new FormControl('', Validators.required),
            districtId: new FormControl('', Validators.required),
            addressLevel1: new FormControl('', Validators.required),
            addressLevel2: new FormControl(''),
            addressLevel3: new FormControl(''),
            postalCode: new FormControl(''),
        });
        if (data.id > 0) {
            this.getAddressInfo(data.id);
        } else {
            this.getListCountry();
        }
        console.log('DialogData', data);
    }


    getListCountry(): void {
        this.getListCountryUseCase.execute()
            .subscribe({
                next: (data) => {
                    console.log(data);
                    this.countries = data;
                    this.cities = [];
                    this.districts = [];
                    this.addressForm.patchValue({ countryId: this.updateAddressRequest.countryId });
                    this.onSelectionChangeCountry();
                },
                error: (error) => {
                    this.error = 'Failed to load addresses';
                    this.isLoading = false;
                    console.error('Error:', error);
                }
            });
    }

    getListCity() {
        this.getListCityUseCase.execute(this.requestCity)
            .subscribe({
                next: (data) => {
                    console.log(data);
                    this.cities = data;
                    this.districts = [];
                    this.addressForm.patchValue({ provinceId: this.updateAddressRequest.provinceId + '' });
                    this.onSelectionChangeCity();
                },
                error: (error) => {
                    this.error = 'Failed to load addresses';
                    this.isLoading = false;
                    console.error('Error:', error);
                }
            });
    }

    getListDistrict() {
        this.getListDistrictUseCase.execute(this.requestDistrict).subscribe({
            next: (data) => {
                console.log(data);
                this.districts = data;
                this.addressForm.get('districtId')?.setValue(this.updateAddressRequest.districtId + '');
            },
            error: (error) => {
                this.error = 'Failed to load addresses';
                this.isLoading = false;
                console.error('Error:', error);
            }
        });
    }
    onSelectionChangeCountry(): void {
        this.requestCity.countryId = this.addressForm.get('countryId')?.value
        this.getListCity();
    }
    onSelectionChangeCity(): void {
        this.requestDistrict.countryId = this.addressForm.get('countryId')?.value;
        this.requestDistrict.cityId = this.addressForm.get('provinceId')?.value;
        this.getListDistrict();
    }

    getAddressInfo(id: number): void {
        if (id > 0) {
            this.getAddressInfoUseCase.execute(id)
                .subscribe({
                    next: (data) => {
                        console.log('getAddressInfoUseCase', data);
                        this.addressForm.get('countryId')?.setValue(data.country.id);
                        this.updateAddressRequest = mapAddressModelToCreateAddressInfo(data);
                        this.addressForm.patchValue(this.updateAddressRequest);
                        this.getListCountry();
                        this.isLoading = false;
                    },
                    error: (error) => {
                        this.error = 'Failed to load address info';
                        this.isLoading = false;
                        console.error('Error:', error);
                    }
                });
        }
    }
    onSubmitAddress() {
        if (this.addressForm.valid) {
            this.updateAddressRequest = {
                ...this.updateAddressRequest,
                ...this.addressForm.value
            };
            this.updateAddressUseCase.execute(this.data.id, this.updateAddressRequest).subscribe({
                next: (data) => {
                    console.log(data);
                    this.dialogRef.close();
                },
                error: (error) => {
                    this.error = 'Failed to load addresses';
                    this.isLoading = false;
                    console.error('Error:', error);
                }
            });
        }
    }
    /*onNoClick(): void {
        this.dialogRef.close();
    }*/

}