import {Inject, Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {ADDRESS_TOKEN, AddressInterface} from "../../core/ports/address.interface";
import {AddressModel} from "../../core/domain/models/address.model";
import {CreateAddressRequest} from "../../infrastructure/adapters/http/requests/create-address.request";
import {AddressService} from "../../infrastructure/services/address.service";

@Injectable({
    providedIn: 'root'
})
export class UpdateAddressUseCase {
    constructor(
        private addressService: AddressService
    ) {}
    execute(id: number, request: CreateAddressRequest ): Observable<AddressModel> {
        return this.addressService.updateAddress(id, request).pipe(
            catchError(error => {
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    // Xử lý lỗi
    private handleError(error: any): void {
        console.error('Error create project', error);
    }
}
