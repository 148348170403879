import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";
import {ProjectInterface} from "../../core/ports/project.interface";
import {ProjectModel} from "../../core/domain/models/project.model";
import {ProjectAdapter} from "../adapters/http/project.adapter";
import {ListProjectResponse} from "../adapters/http/responses/list-project.response";
import {mapListProjectResponseToModels, mapProjectResponseToModels} from "../mappers/project.mapper";
import {CreateProjectResponse} from "../adapters/http/responses/create-project.response";

@Injectable({
    providedIn: 'root'
})
export class ProjectService implements ProjectInterface {
    constructor(private projectAdapter: ProjectAdapter) {}

    getProjects(): Observable<ProjectModel[]> {
        return this.projectAdapter.getProjects().pipe(
          tap((response: ListProjectResponse) => console.log("project res", response)),
          map((response: ListProjectResponse) => mapListProjectResponseToModels(response))
        );
    }

    createProject(requestModel: ProjectModel) {

        return this.projectAdapter.createProject(requestModel).pipe(
          map((response: CreateProjectResponse) => mapProjectResponseToModels(response))
        );
        // return this.projectAdapter.createProject(requestModel);
    }
}
