<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Chat
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Apps
        </li>
        <li class="breadcrumb-item position-relative">
            Chat
        </li>
    </ol>
</div>

<!-- Chat -->
<div class="row">
    <div class="col-lg-4 col-md-12">
        <mat-card
            class="daxa-card chat-sidebar-card mb-25 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Messages
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form class="search-box position-relative">
                    <i class="material-symbols-outlined">
                        search
                    </i>
                    <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search...">
                </form>
                <mat-tab-group animationDuration="0ms">
                    <mat-tab label="All Message">
                        <div class="chat-users-list">
                            <div class="single-user-item active d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user1.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Marcia Baker
                                        </span>
                                        <span class="d-block text-success">
                                            Typing...
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Just Now
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user2.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Carolyn Barnes
                                        </span>
                                        <span class="d-block text-body">
                                            Hello Mateo...
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Sun 9:10 PM
                                    </span>
                                    <span class="daxa-badge d-inline-block rounded-circle">
                                        2
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user4.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Donna Miller
                                        </span>
                                        <span class="d-block text-body">
                                            Great ! 🔥
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Mon 2:12 PM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user5.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Barbara Cross
                                        </span>
                                        <span class="d-block text-body">
                                            That cool, go for it...😀
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Tue 8:50 AM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user6.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Rebecca Block
                                        </span>
                                        <span class="d-block text-body">
                                            Hello!
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Wed 9:20 PAM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user7.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Ramiro McCarty
                                        </span>
                                        <span class="d-block text-body">
                                            How are you Luca? Would...😐
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Wed 11:50 PM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user8.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Robert Fairweather
                                        </span>
                                        <span class="voice d-block text-body">
                                            <i class="ri-mic-line"></i>
                                            Voice message
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Thu 6:10 PM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user9.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Marcelino Haddock
                                        </span>
                                        <span class="d-block text-body">
                                            Cool ! 🔥
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Fri 3:09 PM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user10.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Thomas Wilson
                                        </span>
                                        <span class="d-block text-body">
                                            What are you doing...🙁
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Sun 9:51 PM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user11.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Nathaniel Hulsey
                                        </span>
                                        <span class="d-block text-body">
                                            Could you please...
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Mon 7:23 AM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Group Chat">
                        <button mat-button>
                            + Create New Group 
                        </button>
                        <div class="chat-users-list">
                            <div class="single-user-item active d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/courses/course1.jpg" class="rounded-circle" alt="user-image">
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            UI/UX Designer Group
                                        </span>
                                        <span class="d-block text-body">
                                            Divan: Sure I’ll submit the file...
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Just Now
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/courses/course2.jpg" class="rounded-circle" alt="user-image">
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Web Development Group
                                        </span>
                                        <span class="d-block text-body">
                                            Divan: <span class="text-success">Typing...</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Sun 9:10 PM
                                    </span>
                                    <span class="daxa-badge d-inline-block rounded-circle">
                                        2
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/courses/course3.jpg" class="rounded-circle" alt="user-image">
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Discussion Group
                                        </span>
                                        <span class="d-block text-body">
                                            Divan: Great ! 🔥
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Mon 2:12 PM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Contacts">
                        <div class="chat-users-list">
                            <div class="single-user-item active d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user1.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Marcia Baker
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user2.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Carolyn Barnes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user4.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Donna Miller
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user5.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Barbara Cross
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user6.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Rebecca Block
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user7.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Ramiro McCarty
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user8.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Robert Fairweather
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user9.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Marcelino Haddock
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user10.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Thomas Wilson
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="images/users/user11.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Nathaniel Hulsey
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-8 col-md-12">
        <mat-card
            class="daxa-card chat-card mb-25 border-radius bg-white border-none d-block"
            [class.component-dark-theme]="themeService.isDark()"
            [class.rtl-enabled]="themeService.isRTLEnabled()"
        >
            <mat-card-content>
                <div class="chat-header d-md-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <div class="img position-relative">
                            <img src="images/users/user1.jpg" class="rounded-circle" alt="user-image">
                            <span class="active-status"></span>
                        </div>
                        <div class="title">
                            <span class="d-block fw-medium">
                                Marcia Baker
                            </span>
                            <span class="d-block text-success">
                                Active Now
                            </span>
                        </div>
                    </div>
                    <div class="buttons-list d-flex align-items-center">
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                call
                            </i>
                        </button>
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                videocam
                            </i>
                        </button>
                        <button type="button" class="text-body" mat-button [matMenuTriggerFor]="chatHeaderMenu">
                            <i class="material-symbols-outlined">
                                more_horiz
                            </i>
                        </button>
                        <mat-menu #chatHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                            <button mat-menu-item>
                                Mute Chat
                            </button>
                            <button mat-menu-item>
                                Delete
                            </button>
                            <button mat-menu-item>
                                Block
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div class="border"></div>
                <div class="chat-body">
                    <ul class="list-unstyled mb-0 mt-0">
                        <li class="position-relative">
                            <img src="images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p>
                                        Hey Micheals, have you had a chance to check out the new admin dashboard?
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        Oh, they've got this Kanban board for task management. You can drag and drop tasks between columns – it's so intuitive. Makes managing tasks a breeze. 🔥
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-muted">
                                09:10 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="images/admin.png" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p>
                                        Oh, you mean the one for project management?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-muted">
                                09:20 AM
                            </span>
                        </li>
                        <li class="position-relative">
                            <img src="images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p>
                                        Yeah, that's the one! It's got a sleek Material Design, and the features are pretty robust.
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-muted">
                                09:20 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="images/admin.png" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p>
                                        Nice! What features are you finding interesting?
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        Hey Micheals, have you had a chance to check out the new admin dashboard?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-muted">
                                09:21 AM
                            </span>
                        </li>
                        <li class="position-relative">
                            <img src="images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p>
                                        Well, it has a project overview with all the key metrics on the landing page – project progress, pending tasks, and even a Gantt chart.
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-muted">
                                09:22 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="images/admin.png" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p>
                                        That sounds handy. How about the task management features?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-muted">
                                09:25 AM
                            </span>
                        </li>
                        <li class="position-relative">
                            <img src="images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p>
                                        Oh, they've got this Kanban board for task management. You can drag and drop tasks between columns – it's so intuitive. Makes managing tasks a breeze. 🔥
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-muted">
                                09:30 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="images/admin.png" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p>
                                        Sweet! What about team collaboration?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-muted">
                                09:31 AM
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="chat-footer border-radius d-md-flex align-items-center">
                    <div class="buttons-list d-flex align-items-center">
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                sentiment_satisfied
                            </i>
                        </button>
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                attach_file
                            </i>
                        </button>
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                mic_none
                            </i>
                        </button>
                        <button mat-button class="text-body">
                            <i class="material-symbols-outlined">
                                image
                            </i>
                        </button>
                    </div>
                    <form class="position-relative">
                        <mat-form-field>
                            <mat-label>Type something....</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <button mat-button>
                            <i class="material-symbols-outlined">
                                send
                            </i>
                        </button>
                    </form>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>