<!-- Sidebar -->
<div
    [ngClass]="{'active': isToggled}"
    class="settings-sidebar bg-white box-shadow"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="settings-header bg-gray d-flex align-items-center justify-content-between">
        <div>
            <h5 class="m-0">
                Configuration Panel
            </h5>
        </div>
        <div>
            <img src="images/logo-icon.svg" alt="logo-icon">
        </div>
    </div>
    <ng-scrollbar visibility="hover">
        <div class="settings-body">
            <div class="p-20">
                <span class="d-block mb-12 fw-medium">
                    RTL Mode
                </span>
                <button
                    (click)="toggleRTLEnabledTheme()"
                    class="switch-btn"
                    [class.active]="themeService.isRTLEnabled()"
                >
                </button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="d-block mb-12 fw-medium">
                    Dark Mode
                </span>
                <button
                    (click)="toggleTheme()"
                    class="switch-btn"
                    [class.active]="themeService.isDark()"
                >
                </button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="d-block mb-12 fw-medium">
                    Only Sidebar Dark
                </span>
                <button
                    (click)="toggleSidebarTheme()"
                    class="switch-btn"
                    [class.active]="themeService.isSidebarDark()"
                >
                </button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="d-block mb-12 fw-medium">
                    Only Header Dark
                </span>
                <button
                    (click)="toggleHeaderTheme()"
                    class="switch-btn"
                    [class.active]="themeService.isHeaderDark()"
                >
                </button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="d-block mb-12 fw-medium">
                    Right Sidebar
                </span>
                <button
                    (click)="toggleRightSidebarTheme()"
                    class="switch-btn"
                    [class.active]="themeService.isRightSidebar()"
                >
                </button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20 hide-sidebar">
                <span class="d-block mb-12 fw-medium">
                    Hide Sidebar
                </span>
                <button
                    (click)="toggleHideSidebarTheme()"
                    class="switch-btn"
                    [class.active]="themeService.isHideSidebar()"
                >
                </button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="d-block mb-12 fw-medium">
                    Borderd Card
                </span>
                <button
                    (click)="toggleCardBorderTheme()"
                    class="switch-btn"
                    [class.active]="themeService.isCardBorder()"
                >
                </button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="d-block mb-12 fw-medium">
                    Card Border Radius
                </span>
                <button
                    (click)="toggleCardBorderRadiusTheme()"
                    class="switch-btn"
                    [class.active]="themeService.isCardBorderRadius()"
                >
                </button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <a mat-flat-button href="https://1.envato.market/oqD6RY" class="daxa" target="_blank">
                    Buy Daxa
                </a>
            </div>
        </div>
    </ng-scrollbar>
</div>

<!-- Settings Button -->
<button
    mat-fab
    (click)="toggle()"
    class="daxa settings-btn"
    [ngClass]="{'active': isToggled}"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-icon>settings</mat-icon>
</button>