<div class="sign-in-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div
                class="daxa-form ml-auto mr-auto border-radius bg-white"
                [class.card-borderd-theme]="themeService.isCardBorder()"
                [class.component-dark-theme]="themeService.isDark()"
                [class.rtl-enabled]="themeService.isRTLEnabled()"
            >
                <div class="title text-center">
                    <h3>
                        Sign In
                    </h3>
                    <p class="text-black">
                        Don’t have an account yet? <a routerLink="/authentication/sign-up" class="text-daxa">Sign Up</a>
                    </p>
                </div>
                <form [formGroup]="authForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label class="main-label d-block lh-1 text-black">
                            Email Address
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter email address
                            </mat-label>
                            <input matInput type="email" id="email" formControlName="email">
                        </mat-form-field>
                        <div class="error text-danger" *ngIf="authForm.get('email')!.hasError('required') && authForm.get('email')!.touched">
                            Email is required.
                        </div>
                        <div class="error text-danger" *ngIf="authForm.get('email')!.hasError('email') && authForm.get('email')!.touched">
                            Please enter a valid email address.
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="main-label d-block lh-1 text-black">
                            Your Password
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Enter your password
                            </mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" id="password" formControlName="password">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <span class="material-symbols-outlined">
                                    {{hide ? 'visibility_off' : 'visibility'}}
                                </span>
                            </button>
                        </mat-form-field>
                        <div class="error text-danger" *ngIf="authForm.get('password')!.hasError('required') && authForm.get('password')!.touched">
                            Password is required.
                        </div>
                        <div class="error text-danger" *ngIf="authForm.get('password')!.hasError('minlength') && authForm.get('password')!.touched">
                            Password must be at least 8 characters long.
                        </div>
                    </div>
                    <div class="info d-flex justify-content-between align-items-center">
                        <mat-checkbox>
                            Remember me
                        </mat-checkbox>
                        <a routerLink="/authentication/forgot-password" class="d-inline-block text-daxa">
                            Forgot Password?
                        </a>
                    </div>
                    <button mat-button class="btn" type="submit" [disabled]="authForm.invalid">
                        Sign In
                    </button>
                    <div class="signin-with-socials text-center">
                        <div class="or position-relative">
                            <span class="d-inline-block">
                                or sign in with
                            </span>
                        </div>
                        <div class="socials">
                            <button mat-button type="button" class="facebook">
                                <i class="ri-facebook-fill"></i>
                            </button>
                            <button mat-button type="button" class="twitter">
                                <i class="ri-twitter-x-fill"></i>
                            </button>
                            <button mat-button type="button" class="google">
                                <i class="ri-google-fill"></i>
                            </button>
                            <button mat-button type="button" class="linkedin">
                                <i class="ri-linkedin-fill"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>