<h1 mat-dialog-title>
    Delete file
</h1>
<div mat-dialog-content>
    <p>
        Would you like to delete cat.jpeg?
    </p>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="warn" mat-dialog-close>
        No
    </button>
    <button mat-flat-button class="daxa" mat-dialog-close cdkFocusInitial>
        Ok
    </button>
</div>