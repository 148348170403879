<!-- Create Project -->
<mat-card
    class="daxa-card create-project-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Create New Project
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-lg-6">
                    <!-- Dòng cho Project Name -->
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Project Name
                        </label>
                        <mat-form-field class="full-width">
                            <mat-label>
                                Write project name
                            </mat-label>
                            <input type="text" id="name" [(ngModel)]="name" name="name" required matInput placeholder="E.g. Hotel management system">
                        </mat-form-field>
                    </div>

                    <!-- Dòng cho Project Description -->
                    <div class="mb-25">
                        <label class="main-label d-block lh-1 text-black">
                            Project Description
                        </label>
                        <mat-form-field class="full-width">
                            <mat-label>
                                Write project description
                            </mat-label>
                            <input type="text" id="desc" [(ngModel)]="desc" name="desc" required matInput placeholder="E.g. A brief description of the project">
                        </mat-form-field>
                    </div>

                    <!-- Các nút Create và Cancel -->
                    <div class="btn-box d-flex justify-content-start">
                        <button type="submit" mat-button color="primary">
                            + Create Project
                        </button>
                        <button type="button" mat-button (click)="onCancel()">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>
