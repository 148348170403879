
// Mapper từ Notification sang NotificationModel
import {City, ListCityResponse} from "../adapters/http/responses/city.response";
import {CityModel} from "../../core/domain/models/city.model";

export function mapCityToModel(city: City): CityModel {
  return {
    id: city.id,
    nameVi: city.nameVi,
    nameEn: city.nameEn,
    name: city.nameVi ?  city.nameVi : city.name,
  };
}

export function mapListCityResponseToModels(response: ListCityResponse): CityModel[] {
  return response.data.items.map(city => mapCityToModel(city));
}