<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Form Field Appearance Variants
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        
        <mat-form-field appearance="fill">
            <mat-label>Fill form field</mat-label>
            <input matInput placeholder="Placeholder">
            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
            <mat-hint>Hint</mat-hint>
        </mat-form-field>

        <br>
        <br>

        <mat-form-field appearance="outline">
            <mat-label>Outline form field</mat-label>
            <input matInput placeholder="Placeholder">
            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
            <mat-hint>Hint</mat-hint>
        </mat-form-field>

    </mat-card-content>
</mat-card>