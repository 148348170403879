import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { AllProjectsComponent } from './all-projects/all-projects.component';
import { RecentActivityComponent } from './recent-activity/recent-activity.component';
import { CustomizerSettingsService } from '../../../components/customizer-settings/customizer-settings.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserState } from '../../../../application/state/reducers/user.reducer';
import { UserInfoModel } from '../../../../core/domain/models/user-info.model';
import { AsyncPipe, NgIf } from '@angular/common';
import {MatTooltip} from "@angular/material/tooltip";
import {MatDialog} from "@angular/material/dialog";
import {AddressDialogComponent} from "../../../features/account/address-dialog/address-dialog.component";
import {AvatarDialogComponent} from "./avatar/avatar-dialog.component";
import {MatIcon} from "@angular/material/icon";

@Component({
    selector: 'app-user-profile',
    standalone: true,
    imports: [RouterLink, MatCardModule, MatButtonModule, MatMenuModule, AllProjectsComponent, RecentActivityComponent, AsyncPipe, NgIf, MatTooltip, MatIcon
    ],
    templateUrl: './user-profile.component.html',
    styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent {

    // isToggled
    isToggled = false;
    user$: Observable<UserInfoModel | null>;
    
    constructor(
        public dialog: MatDialog,
    private store: Store<{ user: UserState }>,
        public themeService: CustomizerSettingsService
    ) {
        this.user$ = this.store.select((state: { user: UserState }) => state.user.user);
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    openAvatarDialog(): void {
        const dialogRef = this.dialog.open(AvatarDialogComponent, {
            panelClass: 'md-dialog-container'
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

}
