<div>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="material-symbols-outlined">
                    emoji_emotions
                </i>
                <span class="title">Icons</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidebar-sub-menu">
            <li class="sidemenu-item">
                <a routerLink="/icons" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Material Symbols
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/icons/remixicon" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    RemixIcon
                </a>
            </li>
        </ul>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="material-symbols-outlined">
                    qr_code_scanner
                </i>
                <span class="title">UI Elements</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidebar-sub-menu">
            <li class="sidemenu-item">
                <a routerLink="/ui-kit" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Alerts
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/autocomplete" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Autocomplete
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/avatars" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Avatars
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/accordion" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Accordion
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/badges" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Badges
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/breadcrumb" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Breadcrumb
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/button-toggle" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Button Toggle
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/bottom-sheet" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Bottom Sheet
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/buttons" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Buttons
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/cards" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Cards
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/carousels" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Carousels
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/checkbox" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Checkbox
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/chips" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Chips
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/clipboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Clipboard
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/color-picker" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Color Picker
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/datepicker" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Datepicker
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/dialog" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Dialog
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/divider" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Divider
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/drag-drop" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Drag & Drop
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/expansion" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Expansion
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/form-field" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Form Field
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/grid-list" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Grid List
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/icon" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Icon
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/input" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Input
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/list" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    List
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/listbox" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Listbox
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/menus" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Menus
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/pagination" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Pagination
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/progress-bar" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Progress Bar
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/radio" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Radio
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/ratio" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Ratio
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/select" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Select
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/sidenav" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Sidenav
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/slide-toggle" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Slide Toggle
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/slider" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Slider
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/snackbar" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Snackbar
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/stepper" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Stepper
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/table" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Table
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/tabs" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Tabs
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/toolbar" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Toolbar
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/tooltip" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Tooltip
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/tree" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Tree
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/typography" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Typography
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/videos" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Videos
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/ui-kit/utilities" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    _Utilities
                </a>
            </li>
        </ul>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="material-symbols-outlined">
                    table_chart
                </i>
                <span class="title">Tables</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidebar-sub-menu">
            <li class="sidemenu-item">
                <a routerLink="/tables" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Basic Table
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/tables/data-table" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Data Table
                </a>
            </li>
        </ul>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="material-symbols-outlined">
                    forum
                </i>
                <span class="title">Forms</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidebar-sub-menu">
            <li class="sidemenu-item">
                <a routerLink="/forms" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Basic Elements
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/forms/advanced-elements" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Advanced Elements
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/forms/wizard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Wizard
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/forms/editors" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Editors
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/forms/file-uploader" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    File Uploader
                </a>
            </li>
        </ul>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="material-symbols-outlined">
                    pie_chart
                </i>
                <span class="title">ApexCharts</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidebar-sub-menu">
            <li class="sidemenu-item">
                <a routerLink="/charts" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Line
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/charts/area" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Area
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/charts/column" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Column
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/charts/mixed" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Mixed
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/charts/radialbar" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    RadialBar
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/charts/radar" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Radar
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/charts/pie" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Pie
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/charts/polar" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Polar
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/charts/more" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    More
                </a>
            </li>
        </ul>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="material-symbols-outlined">
                    lock_open
                </i>
                <span class="title">Authentication</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidebar-sub-menu">
            <li class="sidemenu-item">
                <a routerLink="/authentication" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Sign In
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/authentication/sign-up" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Sign Up
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/authentication/forgot-password" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Forgot Password
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/authentication/reset-password" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Reset Password
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/authentication/confirm-email" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Confirm Email
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/authentication/lock-screen" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Lock Screen
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/authentication/logout" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Logout
                </a>
            </li>
        </ul>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="material-symbols-outlined">
                    content_copy
                </i>
                <span class="title">Extra Pages</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidebar-sub-menu">
            <li class="sidemenu-item">
                <a routerLink="/pricing" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Pricing
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/timeline" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Timeline
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/faq" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    FAQ
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/gallery" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Gallery
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/testimonials" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Testimonials
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/search" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Search
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/coming-soon" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Coming Soon
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/blank-page" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Blank Page
                </a>
            </li>
        </ul>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <i class="material-symbols-outlined">
                    error
                </i>
                <span class="title">Errors</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="sidebar-sub-menu">
            <li class="sidemenu-item">
                <a routerLink="/not-found" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    404 Error Page
                </a>
            </li>
            <li class="sidemenu-item">
                <a routerLink="/internal-error" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                    Internal Error
                </a>
            </li>
        </ul>
    </mat-expansion-panel>
    <a routerLink="/widgets" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            widgets
        </i>
        <span class="title">Widgets</span>
    </a>
    <a routerLink="/maps" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            map
        </i>
        <span class="title">Maps</span>
    </a>
    <a routerLink="/notifications" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            notifications
        </i>
        <span class="title">Notifications</span>
    </a>
    <a routerLink="/members" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <i class="material-symbols-outlined">
            people
        </i>
        <span class="title">Members</span>
    </a>
</div>