<form [formGroup]="avatarForm">
    <h1 mat-dialog-title>
        Avatar
    </h1>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-sm-12 col-12">
                <file-upload formControlName="avatar" class="file-uploader" [multiple]="false"></file-upload>
            </div>
        </div>
    </div>
</form>

<div mat-dialog-actions class="mt-0">
    <button mat-flat-button color="warn" (click)="onNoClick()">
        Close
    </button>
    <button mat-flat-button class="daxa" (click)="onSubmitAvatar()">
        Ok
    </button>
</div>