import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import {Router, RouterLink} from '@angular/router';
import {CustomizerSettingsService} from "../customizer-settings/customizer-settings.service";
import {GetListProjectUseCase} from "../../../application/use-cases/get-list-project.use-case";


@Component({
    selector: 'app-project-block',
    standalone: true,
    imports: [MatCardModule, MatMenuModule, MatButtonModule, RouterLink, NgFor],
    templateUrl: './project-block.component.html',
    styleUrl: './project-block.component.scss'
})
export class ProjectBlockComponent {

    teamMemberCard: any;

    // isToggled
    isToggled = false;

    constructor(
        public themeService: CustomizerSettingsService,
        private getListProjectUseCase: GetListProjectUseCase,
        private router: Router
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        this.getProjects();
    }

    getProjects(): void {
        this.getListProjectUseCase.execute().subscribe({
            next: (projects) => {
                this.teamMemberCard = projects.map((project) => {
                    return {
                        id: project.id,
                        name: project.name,
                        desc: project.desc,
                    };
                });
            },
            error: (error) => {
                console.error('Error:', error);
            }
        });
    }

    // RTL Mode
    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    viewProject(id: string): void {
        this.router.navigate([`/dashboard/${id}`]);
    }

}
