import { UserInfoResponse } from '../adapters/http/responses/user-info.response';
import { UserInfoModel } from '../../core/domain/models/user-info.model';
import {UpdateUserInfoRequest} from "../adapters/http/requests/user-info.request";
import {MyProfileModel} from "../../core/domain/models/my-profile.model";

// Hàm ánh xạ từ UserInfoResponse sang UserInfoModel
export function mapUserInfoResponseToModel(response: UserInfoResponse): UserInfoModel {
  return {
    id: response.data.id,
    username: response.data.username, // Giả sử username là firstName
    email: response.data.email, // Giả sử email là số điện thoại
    enabled: response.data.enabled, // Giá trị giả định cho enabled
    emailVerified: response.data.emailVerified, // Giá trị giả định cho emailVerified
    firstName: response.data.firstName,
    lastName: response.data.lastName,
    fullName: `${response.data.firstName} ${response.data.lastName}`,
    createdAt: response.data.createdAt,
    birthDay: response.data.attributes.birthDay,
    gender: response.data.attributes.gender,
    mobile: response.data.attributes.mobile,
    avatarUrl: response.data.attributes.avatarUrl,
    avatarThumbnailUrl: response.data.attributes.avatarThumbnailUrl
  };
}

export function mapMyProfileModelToUpdateUserInfoRequest(model: MyProfileModel): UpdateUserInfoRequest {
  return {
    firstName: model.firstName,
    lastName: model.lastName,
    attributes: {
      birthDay: model.birthDay,
      gender: model.gender,
      mobile: model.mobile
    }
  };
}

