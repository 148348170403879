import {Inject, Injectable} from '@angular/core';
import { UserInfoInterface } from '../../core/ports/user-info.interface';
import {USER_INFO_TOKEN} from "../../core/ports/user-info.token";
import {catchError} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {UpdateUserInfoRequest} from "../../infrastructure/adapters/http/requests/user-info.request";
import {UpdateUserAvatarRequest} from "../../infrastructure/adapters/http/requests/upload-account-avatar.request";
import {ResultModel} from "../../core/domain/models/result.model";

@Injectable({
    providedIn: 'root'
})
export class UpdateUserAvatarUseCase {
    constructor(
        @Inject(USER_INFO_TOKEN) private userInfoPort: UserInfoInterface
    ) {}

    execute( request: UpdateUserAvatarRequest): Observable<ResultModel> {
        return this.userInfoPort.updateUserAvatar(request).pipe(
            catchError(error => {
                this.handleError(error);
                return throwError(error);
            })
        );
    }

    // Xử lý lỗi
    private handleError(error: any): void {
        console.error('Error fetching user info', error);
    }
}
