<mat-card
    class="daxa-card media-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Media
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="media-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">
                    
                    <!-- Name Column -->
                    <ng-container matColumnDef="folderName">
                        <th mat-header-cell *matHeaderCellDef>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a routerLink="/file-manager" class="folder-name position-relative fw-medium">
                                <i class="material-symbols-outlined">
                                    image
                                </i>
                                {{element.folderName}}
                            </a>
                        </td>
                    </ng-container>

                    <!-- Owner Column -->
                    <ng-container matColumnDef="owner">
                        <th mat-header-cell *matHeaderCellDef>
                            Owner
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.owner}}
                        </td>
                    </ng-container>

                    <!-- Listed Date Column -->
                    <ng-container matColumnDef="listedDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Listed Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.listedDate}}
                        </td>
                    </ng-container>

                    <!-- File Size Column -->
                    <ng-container matColumnDef="fileSize">
                        <th mat-header-cell *matHeaderCellDef>
                            File Size
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.fileSize}}
                        </td>
                    </ng-container>

                    <!-- File Items Column -->
                    <ng-container matColumnDef="fileItems">
                        <th mat-header-cell *matHeaderCellDef>
                            File Items
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.fileItems}}
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="action-info">
                                <button type="button" mat-button matTooltip="Download" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.download}}
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Rename" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.edit}}
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Remove" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.delete}}
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
        </div>
    </mat-card-content>
</mat-card>