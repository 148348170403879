import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {ListProjectResponse} from "./responses/list-project.response";
import {environment} from "../../../../environments/environment";
import {CreateProjectRequest} from "./requests/create-project.request";
import {CreateProjectResponse} from "./responses/create-project.response";

@Injectable({
    providedIn: 'root'
})
export class ProjectAdapter {
    private gateway = environment.adapter.http.gateway;
    private projectService = environment.adapter.http.services.projectManager;

    constructor(private http: HttpClient) {}

    getProjects(): Observable<ListProjectResponse> {
        const url = `${this.gateway}${this.projectService.name}${this.projectService.endpoint.getListProject}`;
        return this.http.get<ListProjectResponse>(url);
    }

    createProject( createProjectRequest: CreateProjectRequest): Observable<CreateProjectResponse> {
        const url = `${this.gateway}${this.projectService.name}${this.projectService.endpoint.createProject}`;
        return this.http.post<CreateProjectResponse>(url, createProjectRequest);
    }
}
