<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Pricing
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Pricing
        </li>
    </ol>
</div>

<!-- Pricing -->
<div
    class="row"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="col-lg-4 col-xxxl-3 col-sm-6">
        <mat-card class="daxa-card pricing-card mb-25 border-radius p-0 bg-white border-none d-block">
            <mat-card-header class="border-top-radius">
                <mat-card-title>
                    <h5 class="mb-0">
                        Free
                    </h5>
                    <span class="d-block">
                        A simple start for everyone
                    </span>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="price lh-1 text-primary fw-medium">
                    $00.00<span class="fw-normal text-body">/ Per month</span>
                </div>
                <ul class="features-list mb-0 mt-0 list-unstyled">
                    <li class="position-relative text-body">
                        Free Live Support
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Access to essential functionalities
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Real world solution
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Limited storage space for user data
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Response within a specified time
                        <i class="material-symbols-outlined">
                            close
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Free Setup
                        <i class="material-symbols-outlined">
                            close
                        </i>
                    </li>
                </ul>
                <button mat-button>
                    Get Started Now
                </button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-xxxl-3 col-sm-6">
        <mat-card class="daxa-card pricing-card mb-25 border-radius p-0 bg-white border-none d-block">
            <mat-card-header class="border-top-radius">
                <mat-card-title>
                    <h5 class="mb-0">
                        Basic Plan
                    </h5>
                    <span class="d-block">
                        A simple start for everyone
                    </span>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="price lh-1 text-primary fw-medium">
                    $39.00<span class="fw-normal text-body">/ Per month</span>
                </div>
                <ul class="features-list mb-0 mt-0 list-unstyled">
                    <li class="position-relative text-body">
                        Free Live Support
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Access to essential functionalities
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Real world solution
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Limited storage space for user data
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Response within a specified time
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Free Setup
                        <i class="material-symbols-outlined">
                            close
                        </i>
                    </li>
                </ul>
                <button mat-button>
                    Get Started Now
                </button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-xxxl-3 col-sm-6">
        <mat-card class="daxa-card pricing-card mb-25 border-radius p-0 bg-white border-none d-block">
            <mat-card-header class="border-top-radius">
                <mat-card-title>
                    <h5 class="mb-0">
                        Enterprise
                    </h5>
                    <span class="d-block">
                        A simple start for everyone
                    </span>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="price lh-1 text-primary fw-medium">
                    $69.00<span class="fw-normal text-body">/ Per month</span>
                </div>
                <ul class="features-list mb-0 mt-0 list-unstyled">
                    <li class="position-relative text-body">
                        Free Live Support
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Access to essential functionalities
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Real world solution
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Limited storage space for user data
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Response within a specified time
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Free Setup
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                </ul>
                <button mat-button>
                    Get Started Now
                </button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-xxxl-3 col-sm-6">
        <mat-card class="daxa-card pricing-card mb-25 border-radius p-0 bg-white border-none d-block">
            <mat-card-header class="border-top-radius">
                <mat-card-title>
                    <h5 class="mb-0">
                        Business
                    </h5>
                    <span class="d-block">
                        A simple start for everyone
                    </span>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="price lh-1 text-primary fw-medium">
                    $99.00<span class="fw-normal text-body">/ Per month</span>
                </div>
                <ul class="features-list mb-0 mt-0 list-unstyled">
                    <li class="position-relative text-body">
                        Free Live Support
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Access to essential functionalities
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Real world solution
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Limited storage space for user data
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Response within a specified time
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Free Setup
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                </ul>
                <button mat-button>
                    Get Started Now
                </button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-xxxl-3 col-sm-6">
        <mat-card class="daxa-card pricing-card mb-25 border-radius p-0 bg-white border-none d-block">
            <mat-card-header class="border-top-radius">
                <mat-card-title>
                    <h5 class="mb-0">
                        Unlimited Plan
                    </h5>
                    <span class="d-block">
                        A simple start for everyone
                    </span>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="price lh-1 text-primary fw-medium">
                    $139.00<span class="fw-normal text-body">/ Per month</span>
                </div>
                <ul class="features-list mb-0 mt-0 list-unstyled">
                    <li class="position-relative text-body">
                        Free Live Support
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Access to essential functionalities
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Real world solution
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Limited storage space for user data
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Response within a specified time
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Free Setup
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                </ul>
                <button mat-button>
                    Get Started Now
                </button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-xxxl-3 col-sm-6">
        <mat-card class="daxa-card pricing-card mb-25 border-radius p-0 bg-white border-none d-block">
            <mat-card-header class="border-top-radius">
                <mat-card-title>
                    <h5 class="mb-0">
                        Pro Plan
                    </h5>
                    <span class="d-block">
                        A simple start for everyone
                    </span>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="price lh-1 text-primary fw-medium">
                    $199.00<span class="fw-normal text-body">/ Per month</span>
                </div>
                <ul class="features-list mb-0 mt-0 list-unstyled">
                    <li class="position-relative text-body">
                        Free Live Support
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Access to essential functionalities
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Real world solution
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Limited storage space for user data
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Response within a specified time
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                    <li class="position-relative text-body">
                        Free Setup
                        <i class="material-symbols-outlined">
                            done
                        </i>
                    </li>
                </ul>
                <button mat-button>
                    Get Started Now
                </button>
            </mat-card-content>
        </mat-card>
    </div>
</div>