import { Component } from '@angular/core';
import {MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle} from "@angular/material/expansion";
import {RouterLink, RouterLinkActive} from "@angular/router";

@Component({
  selector: 'sidebar-modules',
  standalone: true,
    imports: [
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        RouterLink,
        RouterLinkActive
    ],
  templateUrl: './modules.component.html',
  styleUrl: './modules.component.scss'
})
export class ModulesComponent {
    // Mat Expansion
    panelOpenState = false;
}
