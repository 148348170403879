<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Create Order
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            E-Commerce
        </li>
        <li class="breadcrumb-item position-relative">
            Orders
        </li>
        <li class="breadcrumb-item position-relative">
            Create Order
        </li>
    </ol>
</div>

<!-- Create Order -->
<form>
    <div class="row">
        <div class="col-lg-8">
            <mat-card
                class="daxa-card create-order-card mb-25 border-radius bg-white border-none d-block"
                [class.rtl-enabled]="themeService.isRTLEnabled()"
            >
                <mat-card-header>
                    <mat-card-title>
                        <h5 class="mb-0">
                            Billing Information
                        </h5>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Customer Name
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Your name
                                    </mat-label>
                                    <input matInput placeholder="E.g. Mateo Luca">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Phone Number
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Your phone number
                                    </mat-label>
                                    <input matInput placeholder="E.g. +(415) 555‑0132">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Email Address
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Your email address
                                    </mat-label>
                                    <input matInput placeholder="E.g. hello@daxa.com">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    State
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Your state
                                    </mat-label>
                                    <mat-select>
                                        <mat-option value="option1">
                                            Florida
                                        </mat-option>
                                        <mat-option value="option2">
                                            Wisconsin
                                        </mat-option>
                                        <mat-option value="option3">
                                            Washington
                                        </mat-option>
                                        <mat-option value="option4">
                                            Nebraska
                                        </mat-option>
                                        <mat-option value="option5">
                                            Minnesota
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    City
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Your city
                                    </mat-label>
                                    <mat-select>
                                        <mat-option value="option1">
                                            New York
                                        </mat-option>
                                        <mat-option value="option2">
                                            Tokyo
                                        </mat-option>
                                        <mat-option value="option3">
                                            London
                                        </mat-option>
                                        <mat-option value="option4">
                                            Amsterdam
                                        </mat-option>
                                        <mat-option value="option5">
                                            Paris
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    ZIP / Postcode
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Your zip / postcode
                                    </mat-label>
                                    <input matInput placeholder="E.g. 3100">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Street Address
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Your street address
                                    </mat-label>
                                    <input matInput placeholder="E.g. 123 Main Street, Anytown, USA">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Country
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Your country
                                    </mat-label>
                                    <mat-select>
                                        <mat-option value="option1">
                                            Switzerland
                                        </mat-option>
                                        <mat-option value="option2">
                                            New Zealand
                                        </mat-option>
                                        <mat-option value="option3">
                                            Germany
                                        </mat-option>
                                        <mat-option value="option4">
                                            United States
                                        </mat-option>
                                        <mat-option value="option5">
                                            Japan
                                        </mat-option>
                                        <mat-option value="option6">
                                            Netherlands
                                        </mat-option>
                                        <mat-option value="option7">
                                            Sweden
                                        </mat-option>
                                        <mat-option value="option8">
                                            Canada
                                        </mat-option>
                                        <mat-option value="option9">
                                            United Kingdom
                                        </mat-option>
                                        <mat-option value="option10">
                                            Australia
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Order Note (optional)
                                </label>
                                <mat-form-field class="textarea">
                                    <mat-label>
                                        Write your note here....
                                    </mat-label>
                                    <textarea matInput placeholder="E.g. It makes me feel..."></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <button mat-button>
                            Create Order
                        </button>
                        <button mat-button>
                            Cancel
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-lg-4">
            <mat-card
                class="daxa-card create-order-card mb-25 border-radius bg-white border-none d-block"
                [class.component-dark-theme]="themeService.isDark()"
                [class.rtl-enabled]="themeService.isRTLEnabled()"
            >
                <mat-card-header>
                    <mat-card-title>
                        <h5 class="mb-0">
                            Your Order
                        </h5>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="your-order-info d-flex align-items-center justify-content-between">
                        <span class="d-block">
                            Ordered items
                        </span>
                        <a mat-button routerLink="/ecommerce-page/cart">
                            Edit Cart
                        </a>
                    </div>
                    <div class="your-order-table">
                        <div class="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col" class="fw-medium text-black">
                                            Description
                                        </th>
                                        <th scope="col" class="fw-medium text-black">
                                            Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-body">
                                            Grand Total :
                                        </td>
                                        <td class="text-body">
                                            $0.00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-body">
                                            Discount :
                                        </td>
                                        <td class="text-body">
                                            $0.00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-body">
                                            Tax :
                                        </td>
                                        <td class="text-body">
                                            $0.00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-black">
                                            Subtotal :
                                        </td>
                                        <td class="text-body">
                                            $0.00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-body">
                                            Shipping Charge :
                                        </td>
                                        <td class="text-body">
                                            $0.00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-body">
                                            Coupon Charge :
                                        </td>
                                        <td class="text-body">
                                            $0.00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-black">
                                            Total :
                                        </td>
                                        <td class="text-body">
                                            $0.00
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="daxa-card create-order-card mb-25 border-radius bg-white border-none d-block">
                <mat-card-header>
                    <mat-card-title>
                        <h5 class="mb-0">
                            Payment Method
                        </h5>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Payment Card
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Your card
                                    </mat-label>
                                    <mat-select>
                                        <mat-option value="option1">
                                            PayPal
                                        </mat-option>
                                        <mat-option value="option2">
                                            Stax
                                        </mat-option>
                                        <mat-option value="option3">
                                            Helcim
                                        </mat-option>
                                        <mat-option value="option4">
                                            Square
                                        </mat-option>
                                        <mat-option value="option5">
                                            Stripe
                                        </mat-option>
                                      </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Card Number
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Your card number
                                    </mat-label>
                                    <input matInput placeholder="E.g. 3588XXXXXXXXX">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Expiration Date
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Expiration date
                                    </mat-label>
                                    <input matInput placeholder="E.g. MM / YY">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Security Code
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Security code
                                    </mat-label>
                                    <input matInput placeholder="E.g. CVV">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</form>