<div
    class="sidebar-area bg-white active"
    [ngClass]="{'active': isSidebarToggled}"
    [class.component-dark-theme]="themeService.isDark()"
    [class.dark-sidebar]="themeService.isSidebarDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="logo bg-white">
        <a routerLink="/" class="d-flex align-items-center">
            <img class="logo-img" src="brand/tzt-logo.svg" alt="logo-icon">
            <span class="fw-semibold fs-20">202 Console</span>
        </a>
    </div>
    <div
        (click)="toggle()"
        class="burger-menu"
        [ngClass]="{'active': isSidebarToggled}"
    >
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>
    <ng-scrollbar visibility="hover">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <mat-accordion>
                    <sidebar-others/>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>
