<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Dialog with Scrollable Content
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <button mat-flat-button color="accent" (click)="openDialog()">
            Open dialog
        </button>
    </mat-card-content>
</mat-card>