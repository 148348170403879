<mat-card
    class="daxa-card about-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-content>
        <h5>
            Profile Information
        </h5>
        <ul class="pl-0 mb-0 list-unstyled mt-0">
            <li class="text-body">
                User ID: <span class="text-black">7001</span>
            </li>
            <li class="text-body">
                Full Name: <span class="text-black">Micheal Collins</span>
            </li>
            <li class="text-body">
                Email: <span class="text-black">micheal&#64;gmail.com</span>
            </li>
            <li class="text-body">
                Role: <span class="text-black">Administrator</span>
            </li>
            <li class="text-body">
                Location: <span class="text-black">New York, USA</span>
            </li>
            <li class="text-body">
                Join Date: <span class="text-black">May 10, 2024</span>
            </li>
        </ul>
        <h5>
            Additional Information
        </h5>
        <ul class="pl-0 mb-0 list-unstyled mt-0">
            <li class="text-body">
                Phone: <span class="text-black">+1 444 266 5599</span>
            </li>
            <li class="text-body">
                Address: <span class="text-black">84 S. Arrowhead Court Branford</span>
            </li>
            <li class="text-body">
                Orders: <span class="text-black">696</span>
            </li>
            <li class="text-body">
                Product: <span class="text-black">9240</span>
            </li>
            <li class="text-body">
                Event: <span class="text-black">5 events</span>
            </li>
        </ul>
    </mat-card-content>
</mat-card>