<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Course Details
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            LMS
        </li>
        <li class="breadcrumb-item position-relative">
            Courses
        </li>
        <li class="breadcrumb-item position-relative">
            Course Details
        </li>
    </ol>
</div>

<!-- Course Details -->
<mat-card
    class="daxa-card course-details-card mb-25 border-radius bg-gradient border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 text-white mb-0">
                Course Details
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="course-details-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>
                            ID
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-white">
                            {{element.id}}
                        </td>
                    </ng-container>

                    <!-- Course Name Column -->
                    <ng-container matColumnDef="courseName">
                        <th mat-header-cell *matHeaderCellDef>
                            Course Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="fw-medium text-white">
                            {{element.courseName}}
                        </td>
                    </ng-container>

                    <!-- Category Column -->
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>
                            Category
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-white">
                            {{element.category}}
                        </td>
                    </ng-container>

                    <!-- Instructor Column -->
                    <ng-container matColumnDef="instructor">
                        <th mat-header-cell *matHeaderCellDef>
                            Instructor
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-white">
                            {{element.instructor}}
                        </td>
                    </ng-container>

                    <!-- Enrolled Students Column -->
                    <ng-container matColumnDef="enrolledStudents">
                        <th mat-header-cell *matHeaderCellDef>
                            Enrolled Students
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-white">
                            {{element.enrolledStudents}}
                        </td>
                    </ng-container>

                    <!-- Start Date Column -->
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Start Date
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-white">
                            {{element.startDate}}
                        </td>
                    </ng-container>

                    <!-- End Date Column -->
                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef>
                            End Date
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-white">
                            {{element.endDate}}
                        </td>
                    </ng-container>

                    <!-- Price Column -->
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef>
                            Price
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-white">
                            {{element.price}}
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-white">
                            <div class="action-info">
                                <a mat-button routerLink="/lms-page/course-details" matTooltip="View Course" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.view}}
                                    </i>
                                </a>
                                <a mat-button routerLink="/lms-page/edit-course" matTooltip="Edit Course" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.edit}}
                                    </i>
                                </a>
                                <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.delete}}
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<div class="row">
    <div class="col-lg-7 col-md-12">

        <!-- Course Instructor -->
        <app-course-instructor />

    </div>
    <div class="col-lg-5 col-md-12">

        <!-- Enrolled Students -->
        <app-enrolled-students />
        
    </div>
    <div class="col-lg-12 col-xxxl-6">

        <!-- Course Content -->
        <app-course-content />

    </div>
    <div class="col-lg-12 col-xxxl-6">
        <div class="row">
            <div class="col-md-8 col-lg-8">

                <!-- Course Sales -->
                <app-course-sales />

            </div>
            <div class="col-md-4 col-lg-4">

                <!-- Course Videos -->
                <app-course-videos />
                
            </div>
            <div class="col-lg-12">

                <!-- Course Ratings -->
                <app-course-ratings />
                
            </div>
        </div>
    </div>
</div>