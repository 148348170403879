<mat-card
    class="daxa-card recent-leads-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Recent Leads
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="recent-leads-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox
                                (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()"
                            >
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Customer Column -->
                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef>
                            Customer
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="info d-flex align-items-center">
                                <div class="image">
                                    <img [src]="element.customer.img" alt="customer-image">
                                </div>
                                <span class="d-block text-black fw-medium">
                                    {{element.customer.name}}
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>
                            Email
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.email}}
                        </td>
                    </ng-container>

                    <!-- Source Column -->
                    <ng-container matColumnDef="source">
                        <th mat-header-cell *matHeaderCellDef>
                            Source
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.source}}
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="daxa-badge new d-inline-block" *ngIf="element.status.new">
                                {{element.status.new}}
                            </span>
                            <span class="daxa-badge in-progress d-inline-block" *ngIf="element.status.inProgress">
                                {{element.status.inProgress}}
                            </span>
                            <span class="daxa-badge won d-inline-block" *ngIf="element.status.won">
                                {{element.status.won}}
                            </span>
                            <span class="daxa-badge lost d-inline-block" *ngIf="element.status.lost">
                                {{element.status.lost}}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-body">
                            <div class="action-info">
                                <button type="button" mat-button matTooltip="View Lead" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.view}}
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Edit Lead" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.edit}}
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.delete}}
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>