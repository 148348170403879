import {Inject, Injectable} from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import { of } from 'rxjs';
import {USER_INFO_TOKEN} from "../../../core/ports/user-info.token";
import {
  loadNotifications,
  loadNotificationsFailure,
  loadNotificationsSuccess,
  newNotificationReceived, setNotificationCount
} from "../actions/notification.actions";
import {NOTIFICATION_TOKEN, NotificationInterface} from "../../../core/ports/notification.interface";

@Injectable()
export class NotificationEffects {
  loadNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(loadNotifications),
    mergeMap(() => this.notificationPort.getNotifications({isRead: false})
      .pipe(
        tap(notifications => console.log('NotificationEffects Info:', notifications)),
        map(notifications => loadNotificationsSuccess({ notifications })),
        catchError(error => of(loadNotificationsFailure({ error })))
      ))
  ));

  // Hiệu ứng để cập nhật trạng thái khi nhận được thông báo mới
  newNotificationReceived$ = createEffect(() => this.actions$.pipe(
    ofType(newNotificationReceived),
    map(({ notification }) => {
      // Khi có thông báo mới, đặt lại trạng thái icon thành "N"
      return setNotificationCount({ count: 'N' });
    })
  ));

  constructor(
    private actions$: Actions,
    @Inject(NOTIFICATION_TOKEN) private notificationPort: NotificationInterface
  ) {}
}
