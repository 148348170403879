<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Projects List
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            Project Management
        </li>
        <li class="breadcrumb-item position-relative">
            Projects List
        </li>
    </ol>
</div>

<!-- All Projects -->
<mat-card
    class="daxa-card all-projects-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                All Projects
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <a mat-button class="add-new-btn" routerLink="/project-management-page/create-project">
                + Add New Project
            </a>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="all-projects-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>
                            ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.id}}
                        </td>
                    </ng-container>

                    <!-- Project Name Column -->
                    <ng-container matColumnDef="projectName">
                        <th mat-header-cell *matHeaderCellDef>
                            Project Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.projectName}}
                        </td>
                    </ng-container>

                    <!-- Start Date Column -->
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Start Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.startDate}}
                        </td>
                    </ng-container>

                    <!-- End Date Column -->
                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef>
                            End Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.endDate}}
                        </td>
                    </ng-container>

                    <!-- Project Manager Column -->
                    <ng-container matColumnDef="projectManager">
                        <th mat-header-cell *matHeaderCellDef>
                            Project Manager
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-black">
                            {{element.projectManager}}
                        </td>
                    </ng-container>

                    <!-- Budget Column -->
                    <ng-container matColumnDef="budget">
                        <th mat-header-cell *matHeaderCellDef>
                            Budget
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.budget}}
                        </td>
                    </ng-container>

                    <!-- Team Members Column -->
                    <ng-container matColumnDef="teamMembers">
                        <th mat-header-cell *matHeaderCellDef>
                            Team Members
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="team-members-list">
                                <img [src]="element.teamMembers.img1" alt="team-member-image" *ngIf="element.teamMembers.img1">
                                <img [src]="element.teamMembers.img2" alt="team-member-image" *ngIf="element.teamMembers.img2">
                                <img [src]="element.teamMembers.img3" alt="team-member-image" *ngIf="element.teamMembers.img3">
                                <img [src]="element.teamMembers.img4" alt="team-member-image" *ngIf="element.teamMembers.img4">
                                <img [src]="element.teamMembers.img5" alt="team-member-image" *ngIf="element.teamMembers.img5">
                            </div>
                        </td>
                    </ng-container>

                    <!-- Progress Column -->
                    <ng-container matColumnDef="progress">
                        <th mat-header-cell *matHeaderCellDef>
                            Progress
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="d-flex align-items-center">
                                <span class="percentage d-block text-body">
                                    {{element.progress}}%
                                </span>
                                <mat-progress-bar mode="determinate" class="inProgress" [value]="element.progress" *ngIf="element.status.inProgress"></mat-progress-bar>
                                <mat-progress-bar mode="determinate" class="pending" [value]="element.progress" *ngIf="element.status.pending"></mat-progress-bar>
                                <mat-progress-bar mode="determinate" [value]="element.progress" *ngIf="element.status.completed"></mat-progress-bar>
                                <mat-progress-bar mode="determinate" class="notStarted" [value]="element.progress" *ngIf="element.status.notStarted"></mat-progress-bar>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="daxa-badge in-progress d-inline-block" *ngIf="element.status.inProgress">
                                {{element.status.inProgress}}
                            </span>
                            <span class="daxa-badge pending d-inline-block" *ngIf="element.status.pending">
                                {{element.status.pending}}
                            </span>
                            <span class="daxa-badge completed d-inline-block" *ngIf="element.status.completed">
                                {{element.status.completed}}
                            </span>
                            <span class="daxa-badge not-started d-inline-block" *ngIf="element.status.notStarted">
                                {{element.status.notStarted}}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="action-info">
                                <button type="button" mat-button matTooltip="View Project" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.view}}
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.delete}}
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>