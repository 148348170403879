<mat-card class="daxa-card sales-overview-card mb-25 border-radius bg-primary border-none d-block">
    <mat-card-content>
        <h5 class="text-white">
            Sales Overview
        </h5>
        <div class="sales-overview-info d-flex align-items-center justify-content-between">
            <div>
                <span class="sub-title d-block text-white">
                    Total Sales
                </span>
                <span class="number d-block text-white fw-medium lh-1">
                    9,586
                </span>
            </div>
            <div>
                <span class="sub-title d-block text-white">
                    Monthly Sales
                </span>
                <span class="number d-block text-white fw-medium lh-1">
                    3,507
                </span>
            </div>
            <div>
                <span class="sub-title d-block text-white">
                    Today’s Sales
                </span>
                <span class="number d-block text-white fw-medium lh-1">
                    357
                </span>
            </div>
        </div>
        <div class="progress-bar-container">
            <div class="progress-bar" [style.width.%]="progress"></div>
        </div>
        <span class="info d-block text-white">
            20% increase in last month
        </span>
    </mat-card-content>
</mat-card>