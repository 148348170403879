<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Create Course
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            LMS
        </li>
        <li class="breadcrumb-item position-relative">
            Courses
        </li>
        <li class="breadcrumb-item position-relative">
            Create Course
        </li>
    </ol>
</div>

<!-- Create Course -->
<form>
    <div class="row">
        <div class="col-xxl-7 col-xxxl-8">
            <mat-card class="daxa-card create-course-card mb-25 border-radius bg-white border-none d-block">
                <mat-card-header>
                    <mat-card-title>
                        <h5 class="mb-0">
                            Add a Course
                        </h5>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Course Title
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Course title
                                    </mat-label>
                                    <input matInput placeholder="E.g. Hand Watch">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Course Description
                                </label>
                                <div class="NgxEditor__Wrapper">
                                    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                                    <ngx-editor [editor]="editor" [placeholder]="'Type here...'"></ngx-editor>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Course Price
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Enter rate
                                    </mat-label>
                                    <input matInput placeholder="E.g. $90">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Start Date
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Choose a date
                                    </mat-label>
                                    <input matInput [matDatepicker]="startDate">
                                    <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                    <mat-datepicker #startDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    End Date
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Choose a date
                                    </mat-label>
                                    <input matInput [matDatepicker]="endDate">
                                    <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                    <mat-datepicker #endDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Instructor
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Select
                                    </mat-label>
                                    <mat-select [formControl]="instructor" multiple>
                                        @for (instructor of instructorList; track instructor) {
                                            <mat-option [value]="instructor">{{instructor}}</mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Course Tags
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Select
                                    </mat-label>
                                    <mat-select [formControl]="tags" multiple>
                                        @for (tag of tagsList; track tag) {
                                            <mat-option [value]="tag">{{tag}}</mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Course Avatar
                                </label>
                                <file-upload class="file-uploader" [multiple]="multiple"></file-upload>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-xxl-5 col-xxxl-4">
            <mat-card
                class="daxa-card create-course-card mb-25 border-radius bg-white border-none d-block"
                [class.component-dark-theme]="themeService.isDark()"
            >
                <mat-card-header>
                    <mat-card-title>
                        <h5 class="mb-0">
                            Course Overview
                        </h5>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Lesson Title
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Lesson title
                                    </mat-label>
                                    <input matInput placeholder="E.g. Hand Watch">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Lesson Time
                                </label>
                                <mat-form-field>
                                    <mat-label>
                                        Enter hours
                                    </mat-label>
                                    <input matInput placeholder="E.g. 2 hours 23 minutes">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mb-25">
                                <label class="main-label d-block lh-1 text-black">
                                    Lesson Description
                                </label>
                                <mat-form-field class="textarea">
                                    <mat-label>
                                        Write your description here....
                                    </mat-label>
                                    <textarea matInput placeholder="E.g. It makes me feel..."></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="btn-box d-md-flex align-items-center justify-content-between">
                        <button mat-button>
                            Save Course
                        </button>
                        <button mat-button>
                            + Add More Field
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</form>