<mat-card
        class="daxa-card products-list-card mb-25 border-radius bg-white border-none d-block"
        [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Address
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <a mat-button class="add-new-btn" (click)="openAddressDialog()">
                + Add New Address
            </a>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="products-list-table">
            <div class="table-responsive" *ngIf="addresses.length === 0">
                <div class="no-data-found">
                    No data
                </div>
            </div>
            <div class="table-responsive" *ngIf="addresses.length > 0">
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>
                            ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.id}}
                        </td>
                    </ng-container>

                    <!-- Project Name Column -->
                    <ng-container matColumnDef="addressLevel1">
                        <th mat-header-cell *matHeaderCellDef>
                            Address
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.addressLevel1}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="district">
                        <th mat-header-cell *matHeaderCellDef>
                            District
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.district.name}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="province">
                        <th mat-header-cell *matHeaderCellDef>
                            Province
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.province.name}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="country">
                        <th mat-header-cell *matHeaderCellDef>
                            Country
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.country.name}}
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="action-info">
                                <button type="button" mat-button matTooltip="Edit" matTooltipPosition="above"  (click)="openAddressDialogUpdate(element.id)">
                                    <i class="material-symbols-outlined">
                                        edit
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Delete" matTooltipPosition="above" (click)="deleteAddress(element.id)">
                                    <i class="material-symbols-outlined">
                                        delete
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <!--<mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>-->
        </div>
    </mat-card-content>
</mat-card>
