<mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Form Field with Hints
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field hintLabel="Max 10 characters">
            <mat-label>Enter some input</mat-label>
            <input matInput #input maxlength="10" placeholder="Ex. Nougat">
            <mat-hint align="end">{{input.value.length}}/10</mat-hint>
        </mat-form-field>
        <br>
        <br>
        <mat-form-field>
            <mat-label>Select me</mat-label>
            <mat-select>
                <mat-option value="option">Option</mat-option>
            </mat-select>
            <mat-hint align="end">Here's the dropdown arrow ^</mat-hint>
        </mat-form-field>
    </mat-card-content>
</mat-card>