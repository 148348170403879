import { Observable } from 'rxjs';
import {InjectionToken} from "@angular/core";
import {NotificationModel} from "../domain/models/notification.model";
import {GetNotificationListRequest} from "../../infrastructure/adapters/http/requests/get-notification-list.request";
import {ReadNotificationResponse} from "../../infrastructure/adapters/http/responses/read-notification.response";
import {ResultModel} from "../domain/models/result.model";

export interface NotificationInterface {
  getNotifications(getNotificationListRequest: GetNotificationListRequest): Observable<NotificationModel[]>;
  markNotificationsAsRead(ids: string[]): Observable<ReadNotificationResponse>;
  createFcmToken(token: string): Observable<ResultModel>;
}

export const NOTIFICATION_TOKEN = new InjectionToken<NotificationInterface>('NotificationToken');
