import { NgIf } from '@angular/common';
import {Component, ViewChild} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {Router, RouterLink} from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import {CustomizerSettingsService} from "../customizer-settings/customizer-settings.service";
import {MatPaginator} from "@angular/material/paginator";
import {GetListNotificationUseCase} from "../../../application/use-cases/get-list-notification.use-case";
import {NotificationModel} from "../../../core/domain/models/notification.model";
import {GetNotificationListRequest} from "../../../infrastructure/adapters/http/requests/get-notification-list.request";

@Component({
    selector: 'app-notifications',
    standalone: true,
    imports: [MatCardModule, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, NgIf, MatCheckboxModule, MatTooltipModule],
    templateUrl: './notifications.component.html',
    styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {

    displayedColumns: string[] = ['id', 'channel', 'type', 'title', 'body', 'status'];
    dataSource = new MatTableDataSource<NotificationModel>([]);
    selection = new SelectionModel<NotificationModel>(true, []);

    notifications: NotificationModel[] = [];
    isLoading: boolean = true;
    error: string | null = null;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    constructor(
        public themeService: CustomizerSettingsService,
        private getListNotificationUseCase: GetListNotificationUseCase,
        private router: Router
    ) {
        this.getNotifications();
    }

    getNotifications(): void {
        const requestModel: GetNotificationListRequest = {
            isRead : null
        }
        this.getListNotificationUseCase.execute(requestModel).subscribe({
            next: (data) => {
                console.log(data);
                this.notifications = data;
                this.dataSource = new MatTableDataSource<NotificationModel>(this.notifications);
                this.isLoading = false;
            },
            error: (error) => {
                this.error = 'Failed to load projects';
                this.isLoading = false;
                console.error('Error:', error);
            }
        });
    }
}