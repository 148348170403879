<div [class.component-dark-theme]="themeService.isDark()">
    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side">
            <p>Auto-resizing sidenav</p>
            @if (showFiller) {
                <p>Lorem, ipsum dolor sit amet consectetur.</p>
            }
            <button (click)="showFiller = !showFiller" mat-flat-button class="daxa">
                Toggle extra text
            </button>
        </mat-drawer>
        <div class="example-sidenav-content">
            <button type="button" mat-flat-button class="daxa" (click)="drawer.toggle()">
                Toggle sidenav
            </button>
        </div>
    </mat-drawer-container>
</div>