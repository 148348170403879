<!-- My Drive -->
<div
    class="row"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="icon">
                        <i class="material-symbols-outlined">
                            folder
                        </i>
                    </div>
                    <h5 class="mb-0">
                        <a routerLink="/file-manager">
                            Assets
                        </a>
                    </h5>
                </div>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        159 Files
                    </span>
                    <span class="d-block text-body">
                        4.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="icon">
                        <i class="material-symbols-outlined">
                            folder
                        </i>
                    </div>
                    <h5 class="mb-0">
                        <a routerLink="/file-manager">
                            Projects
                        </a>
                    </h5>
                </div>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        522 Files
                    </span>
                    <span class="d-block text-body">
                        5.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="icon">
                        <i class="material-symbols-outlined">
                            folder
                        </i>
                    </div>
                    <h5 class="mb-0">
                        <a routerLink="/file-manager">
                            Personal
                        </a>
                    </h5>
                </div>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        995 Files
                    </span>
                    <span class="d-block text-body">
                        7.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="icon">
                        <i class="material-symbols-outlined">
                            folder
                        </i>
                    </div>
                    <h5 class="mb-0">
                        <a routerLink="/file-manager">
                            Applications
                        </a>
                    </h5>
                </div>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        39 Files
                    </span>
                    <span class="d-block text-body">
                        3.8 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="icon">
                        <i class="material-symbols-outlined">
                            folder
                        </i>
                    </div>
                    <h5 class="mb-0">
                        <a routerLink="/file-manager">
                            Documents
                        </a>
                    </h5>
                </div>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        75 Files
                    </span>
                    <span class="d-block text-body">
                        2.2 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-6">
        <mat-card class="daxa-card file-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn p-0" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="material-symbols-outlined">
                            more_horiz
                        </i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Rename
                        </button>
                        <button mat-menu-item>
                            Download
                        </button>
                        <button mat-menu-item>
                            Remove
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="d-flex align-items-center">
                    <div class="icon">
                        <i class="material-symbols-outlined">
                            folder
                        </i>
                    </div>
                    <h5 class="mb-0">
                        <a routerLink="/file-manager">
                            Media
                        </a>
                    </h5>
                </div>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        357 Files
                    </span>
                    <span class="d-block text-body">
                        8.5 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<mat-card
    class="daxa-card recent-files-card mb-25 border-radius bg-white border-none d-block"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Recent Files
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button
                mat-button
                class="text-daxa p-0 h-auto"
                (click)="toggleClass()"
            >
                + Create New Folder
            </button>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="recent-files-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox
                                (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()"
                            >
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    
                    <!-- Name Column -->
                    <ng-container matColumnDef="folderName">
                        <th mat-header-cell *matHeaderCellDef>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a routerLink="/file-manager" class="folder-name position-relative fw-medium">
                                <i class="material-symbols-outlined">
                                    folder
                                </i>
                                {{element.folderName}}
                            </a>
                        </td>
                    </ng-container>

                    <!-- Owner Column -->
                    <ng-container matColumnDef="owner">
                        <th mat-header-cell *matHeaderCellDef>
                            Owner
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.owner}}
                        </td>
                    </ng-container>

                    <!-- Listed Date Column -->
                    <ng-container matColumnDef="listedDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Listed Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.listedDate}}
                        </td>
                    </ng-container>

                    <!-- File Size Column -->
                    <ng-container matColumnDef="fileSize">
                        <th mat-header-cell *matHeaderCellDef>
                            File Size
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.fileSize}}
                        </td>
                    </ng-container>

                    <!-- File Items Column -->
                    <ng-container matColumnDef="fileItems">
                        <th mat-header-cell *matHeaderCellDef>
                            File Items
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.fileItems}}
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="action-info">
                                <button type="button" mat-button matTooltip="Download" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.download}}
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Rename" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.edit}}
                                    </i>
                                </button>
                                <button type="button" mat-button matTooltip="Remove" matTooltipPosition="above">
                                    <i class="material-symbols-outlined">
                                        {{element.action.delete}}
                                    </i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- Popup -->
<div
    class="create-new-folder-popup"
    [class.active]="classApplied"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="popup-dialog">
        <mat-card class="daxa-card mb-25 border-radius bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mb-0">
                        Create New Folder
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <button
                        mat-button
                        (click)="toggleClass()"
                    >
                        <i class="ri-close-fill"></i>
                    </button>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="row">
                    <div class="col-md-12">
                        <label class="main-label d-block lh-1 text-black">
                            Folder Name
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Folder name
                            </mat-label>
                            <input matInput placeholder="E.g. Hand Watch">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <label class="main-label d-block lh-1 text-black">
                            File Items
                        </label>
                        <mat-form-field class="date">
                            <mat-label>
                                File items
                            </mat-label>
                            <input matInput placeholder="E.g. 100">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <label class="main-label d-block lh-1 text-black">
                            Owner
                        </label>
                        <mat-form-field>
                            <mat-label>
                                Select
                            </mat-label>
                            <mat-select>
                                <mat-option value="option1">
                                    Linda Maddox
                                </mat-option>
                                <mat-option value="option2">
                                    Juanita Lavigne
                                </mat-option>
                                <mat-option value="option3">
                                    Roy Pope
                                </mat-option>
                                <mat-option value="option4">
                                    Cecil Jones
                                </mat-option>
                                <mat-option value="option5">
                                    Trudy Venegas
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="btn-box">
                    <button
                        mat-button
                        (click)="toggleClass()"
                    >
                        Cancel
                    </button>
                    <button mat-button>
                        Create
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>