import {Component, OnInit} from '@angular/core';
import { RouterLink } from '@angular/router';
import { UpdateProfileComponent } from './update-profile/update-profile.component';

import {GetUserInfoUseCase} from "../../../application/use-cases/get-user-info.use-case";

@Component({
    selector: 'feature-my-profile',
    standalone: true,
    imports: [RouterLink, UpdateProfileComponent],
    templateUrl: './my-profile.component.html',
    styleUrl: './my-profile.component.scss'
})
export class FeatureMyProfileComponent  {
    constructor() {}

}
