<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Courses List
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            LMS
        </li>
        <li class="breadcrumb-item position-relative">
            Courses List
        </li>
    </ol>
</div>

<!-- Courses List -->
<mat-card
    class="daxa-card courses-card mb-25 border-radius bg-white border-none d-block"
    [class.component-dark-theme]="themeService.isDark()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mb-0">
                Courses
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group animationDuration="0ms">
            <mat-tab label="All Courses">
                <app-all-courses />
            </mat-tab>
            <mat-tab label="Paid">
                <app-paid-courses />
            </mat-tab>
            <mat-tab label="Free">
                <app-free-courses />
            </mat-tab>
            <mat-tab label="Top Rated">
                <app-top-rated-courses />
            </mat-tab>
            <mat-tab label="Best Seller">
                <app-best-seller-courses />
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>